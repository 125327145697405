var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-sheet",
        [
          _c(
            "v-row",
            { staticClass: "ml-4 mb-4" },
            [
              _c(
                "v-avatar",
                {
                  staticStyle: { "margin-top": "-20px" },
                  attrs: { color: "primary", tile: "", size: "80" },
                },
                [
                  _c("v-icon", { attrs: { size: "50", color: "white" } }, [
                    _vm._v("filter_alt"),
                  ]),
                ],
                1
              ),
              _c("p", { staticClass: "text-h5 ml-4 mt-3" }, [_vm._v("Filter")]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mx-4 mt-5 pb-4" },
            [
              _c(
                "v-col",
                { staticClass: "px-5 py-0 mb-5", attrs: { cols: "12" } },
                [
                  _c("v-switch", {
                    attrs: {
                      label: "Versteckte Datensätze anzeigen",
                      hint: "Zeigt Wohneinheiten und Kontakte an",
                      "persistent-hint": "",
                    },
                    model: {
                      value: _vm.value.showHidden,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "showHidden", $$v)
                      },
                      expression: "value.showHidden",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "px-5 py-0", attrs: { cols: "3" } },
                [
                  _c("p", { staticClass: "my-0 overline font-weight-bold" }, [
                    _vm._v("Types"),
                  ]),
                  _c("v-autocomplete", {
                    attrs: {
                      filled: "",
                      dense: "",
                      placeholder: "Types wählen...",
                      multiple: "",
                      "deletable-chips": "",
                      "small-chips": "",
                      chips: "",
                      "item-value": "value",
                      "item-text": "label",
                      items: _vm.processedTypes,
                    },
                    model: {
                      value: _vm.value.types,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "types", $$v)
                      },
                      expression: "value.types",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "px-5 py-0", attrs: { cols: "3" } },
                [
                  _c("p", { staticClass: "my-0 overline font-weight-bold" }, [
                    _vm._v("Channels"),
                  ]),
                  _c("v-autocomplete", {
                    attrs: {
                      filled: "",
                      dense: "",
                      placeholder: "Channels wählen...",
                      multiple: "",
                      "small-chips": "",
                      "deletable-chips": "",
                      chips: "",
                      "item-value": "id",
                      "item-text": "label",
                      items: _vm.processedChannels,
                    },
                    model: {
                      value: _vm.value.channels,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "channels", $$v)
                      },
                      expression: "value.channels",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "px-5 py-0", attrs: { cols: "3" } },
                [
                  _c("p", { staticClass: "my-0 overline font-weight-bold" }, [
                    _vm._v("Ausstattungsmerkmale"),
                  ]),
                  _c("v-autocomplete", {
                    attrs: {
                      filled: "",
                      dense: "",
                      placeholder: "Merkmal wählen...",
                      multiple: "",
                      "small-chips": "",
                      "deletable-chips": "",
                      chips: "",
                      "item-value": "value",
                      "item-text": "label",
                    },
                    model: {
                      value: _vm.value.topics,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "topics", $$v)
                      },
                      expression: "value.topics",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "px-5 py-0", attrs: { cols: "3" } },
                [
                  _c("p", { staticClass: "my-0 overline font-weight-bold" }, [
                    _vm._v("Qualitätssiegel"),
                  ]),
                  _c("v-autocomplete", {
                    attrs: {
                      filled: "",
                      dense: "",
                      placeholder: "Siegel wählen...",
                      multiple: "",
                      "small-chips": "",
                      "deletable-chips": "",
                      chips: "",
                      "item-value": "value",
                      "item-text": "label",
                    },
                    model: {
                      value: _vm.value.topics,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "topics", $$v)
                      },
                      expression: "value.topics",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "px-5 py-0", attrs: { cols: "3" } },
                [
                  _c("p", { staticClass: "my-0 overline font-weight-bold" }, [
                    _vm._v("Themen"),
                  ]),
                  _c("v-autocomplete", {
                    attrs: {
                      filled: "",
                      dense: "",
                      placeholder: "Themen wählen...",
                      multiple: "",
                      "small-chips": "",
                      "deletable-chips": "",
                      chips: "",
                      "item-value": "value",
                      "item-text": "label",
                      items: _vm.processedTopics,
                    },
                    model: {
                      value: _vm.value.topics,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "topics", $$v)
                      },
                      expression: "value.topics",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "px-5 py-0", attrs: { cols: "3" } },
                [
                  _c("p", { staticClass: "my-0 overline font-weight-bold" }, [
                    _vm._v("Orte"),
                  ]),
                  _c("v-autocomplete", {
                    attrs: {
                      filled: "",
                      dense: "",
                      placeholder: "Orte wählen...",
                      multiple: "",
                      "small-chips": "",
                      "deletable-chips": "",
                      chips: "",
                      "item-value": "value",
                      "item-text": "label",
                    },
                    model: {
                      value: _vm.value.cities,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "cities", $$v)
                      },
                      expression: "value.cities",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "ml-5 mb-5",
              attrs: { color: "error", depressed: "", rounded: "" },
              on: {
                click: function ($event) {
                  return _vm.change({})
                },
              },
            },
            [
              _c("v-icon", { staticClass: "mr-2" }, [_vm._v(" clear_all ")]),
              _vm._v(" Filter zurücksetzen "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }