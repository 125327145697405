var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        { attrs: { fixed: "", color: "red", width: "400" } },
        [
          _c(
            "p",
            {
              staticClass: "white--text caption mt-5 text-uppercase ml-4 mb-0",
            },
            [_vm._v("Katalog")]
          ),
          _c("p", { staticClass: "text-h5 white--text ml-4" }, [
            _vm._v(_vm._s(_vm.catalog.name)),
          ]),
          _c(
            "v-sheet",
            {
              staticClass: "mx-5 pa-5",
              staticStyle: { "overflow-y": "scroll" },
              attrs: { "max-height": "calc(100% - 400px)" },
            },
            [
              _c(
                "draggable",
                _vm._b(
                  {
                    attrs: {
                      group: { name: "entries", pull: "clone", put: false },
                      clone: _vm.cloneEntry,
                    },
                    on: {
                      start: function ($event) {
                        _vm.drag = true
                      },
                      end: function ($event) {
                        _vm.drag = false
                      },
                      change: _vm.log,
                    },
                    model: {
                      value: _vm.entries,
                      callback: function ($$v) {
                        _vm.entries = $$v
                      },
                      expression: "entries",
                    },
                  },
                  "draggable",
                  _vm.dragOptions,
                  false
                ),
                [
                  _c(
                    "transition-group",
                    {
                      attrs: {
                        type: "transition",
                        name: !_vm.drag ? "flip-list" : null,
                      },
                    },
                    _vm._l(_vm.entries, function (entry) {
                      return _c(
                        "div",
                        { key: entry._id, staticClass: "py-2 px-2" },
                        [
                          _c("p", { staticClass: "ma-0" }, [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  (
                                    entry.content.name.find(function (l) {
                                      return l.lang === "de-DE"
                                    }) || {}
                                  ).value
                                )
                              ),
                            ]),
                          ]),
                          _vm.pages.filter(function (p) {
                            return (
                              p.content.findIndex(function (row) {
                                return row.id === entry._id.toString()
                              }) !== -1
                            )
                          }).length !== 0
                            ? _c(
                                "p",
                                { staticClass: "ma-0" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { small: "", color: "green" },
                                    },
                                    [_vm._v("check")]
                                  ),
                                  _c("small", [_vm._v("bereits Im Katalog")]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            height: "100%",
            width: "calc(100% - 400px)",
            "margin-left": "400px",
          },
        },
        [
          _c(
            "v-row",
            {
              staticClass: "pt-5 px-5",
              staticStyle: { "padding-bottom": "300px" },
            },
            [
              _vm._l(_vm.pages, function (page, index) {
                return [
                  index === 0
                    ? _c("v-sheet", {
                        key: index + "__page_zero",
                        staticClass: "my-5 ml-5",
                        attrs: {
                          elevation: "0",
                          height: _vm.pageHeight,
                          width: _vm.pageWidth,
                        },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { key: index },
                    [
                      _c(
                        "v-sheet",
                        {
                          class: "mt-5 pa-1 " + (index % 2 !== 0 ? "ml-5" : ""),
                          attrs: {
                            elevation: "5",
                            height: _vm.pageHeight,
                            width: _vm.pageWidth,
                          },
                        },
                        [
                          _c(
                            "draggable",
                            _vm._b(
                              {
                                staticClass: "dragArea mx-auto",
                                style:
                                  _vm.pages[index].content.length === 0
                                    ? "border: 1px black dotted; height: 0px; width: 0px; margin-top: " +
                                      _vm.dragThreshold +
                                      "px;"
                                    : "",
                                attrs: {
                                  emptyInsertThreshold: _vm.dragThreshold,
                                  group: "entries",
                                },
                                on: {
                                  start: function ($event) {
                                    _vm.drag = true
                                  },
                                  end: function ($event) {
                                    _vm.drag = false
                                  },
                                  change: _vm.log,
                                },
                                model: {
                                  value: _vm.pages[index].content,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pages[index], "content", $$v)
                                  },
                                  expression: "pages[index].content",
                                },
                              },
                              "draggable",
                              _vm.dragOptions,
                              false
                            ),
                            [
                              _c(
                                "transition-group",
                                {
                                  attrs: {
                                    type: "transition",
                                    name: !_vm.drag ? "flip-list" : null,
                                  },
                                },
                                _vm._l(
                                  _vm.pages[index].content,
                                  function (entry) {
                                    return _c(
                                      "div",
                                      { key: entry.id },
                                      [
                                        _c("v-img", {
                                          attrs: { src: _vm.getPreviewURL },
                                          on: {
                                            contextmenu: function ($event) {
                                              return _vm.showContext(
                                                $event,
                                                index
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              "position-x": _vm.x,
                                              "position-y": _vm.y,
                                              absolute: "",
                                              "offset-y": "",
                                            },
                                            model: {
                                              value: _vm.show[index],
                                              callback: function ($$v) {
                                                _vm.$set(_vm.show, index, $$v)
                                              },
                                              expression: "show[index]",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list",
                                              [
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v("Delete"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("p", { staticClass: "my-2 text-center" }, [
                        _vm._v(" - " + _vm._s(index + 1) + " - "),
                      ]),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              staticStyle: {
                position: "fixed",
                right: "10px",
                bottom: "20px",
                width: "200px",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c("v-icon", { staticClass: "mr-2 my-auto" }, [
                    _vm._v(" zoom_in "),
                  ]),
                  _c("v-slider", {
                    staticClass: "my-auto",
                    attrs: {
                      "hide-details": "",
                      min: "0.5",
                      max: "3",
                      step: "0.05",
                      "thumb-label": "",
                    },
                    model: {
                      value: _vm.zoomModifier,
                      callback: function ($$v) {
                        _vm.zoomModifier = $$v
                      },
                      expression: "zoomModifier",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }