var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "onlimchatbot" },
    [
      _c("h1", [_vm._v("Onlim Chatbot Interface")]),
      _c("p", [
        _vm._v("für Channel "),
        _vm.currentChannel.labels !== undefined
          ? _c("strong", [_vm._v(_vm._s(_vm.currentChannel.labels["de-DE"]))])
          : _vm._e(),
        _vm._v(" "),
        _c("span", { staticClass: "info--text" }, [
          _vm._v("[" + _vm._s(_vm.currentChannel.id) + "]"),
        ]),
      ]),
      _c("p", [
        _vm._v(
          "Hier können Einstellungen und Informationen zu Übertragung der Daten an den Chatbot eingesehen werden."
        ),
      ]),
      _c(
        "v-row",
        _vm._l(_vm.menu, function (menuitem, index) {
          return _c(
            "v-col",
            {
              key: index,
              attrs: {
                index: index,
                item: menuitem,
                sm12: "",
                md6: "",
                lg4: "",
                xl3: "",
              },
            },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c(
                    "v-list-item",
                    { attrs: { "three-line": "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("div", { staticClass: "overline mb-4" }),
                          _c(
                            "v-list-item-title",
                            { staticClass: "headline mb-1" },
                            [_vm._v(" " + _vm._s(menuitem.title) + " ")]
                          ),
                          _c("v-list-item-subtitle", [
                            _vm._v(_vm._s(menuitem.description)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-avatar",
                        { attrs: { tile: "", size: "80" } },
                        [
                          _c("v-icon", { attrs: { size: "64" } }, [
                            _vm._v(_vm._s(menuitem.icon)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            rounded: "",
                            router: "",
                            to: "" + menuitem.path,
                            append: "",
                          },
                        },
                        [_vm._v(" bearbeiten ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }