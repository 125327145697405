var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-5" },
    [
      _c(
        "v-alert",
        {
          attrs: {
            outlined: "",
            type: "info",
            text: "",
            color: (_vm.definition.config || {}).color || "info",
          },
        },
        [
          _c("span", { staticClass: "font-weight-bold mb-0" }, [
            _vm._v(_vm._s(_vm.definition.label)),
          ]),
          _c("br"),
          _c("span", [_vm._v(_vm._s(_vm.definition.description))]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }