var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "users" },
    [
      _c("p", { staticClass: "text-h5" }, [
        _vm._v(_vm._s((_vm.user || {}).name)),
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "col-12 col-sm-6 col-md-4" },
            [
              _c("v-autocomplete", {
                attrs: {
                  label: "Projekt",
                  items: _vm.projects,
                  loading: _vm.loadingProjects,
                  "item-value": "id",
                  "item-text": "name",
                  "auto-select-first": "",
                },
                model: {
                  value: _vm.projectID,
                  callback: function ($$v) {
                    _vm.projectID = $$v
                  },
                  expression: "projectID",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "col-12 col-sm-6 col-md-4" },
            [
              _c("v-autocomplete", {
                attrs: {
                  label: "Channel",
                  items: _vm.processedChannels,
                  loading: _vm.loadingProjects,
                  "item-value": "id",
                  "item-text": "label",
                  "auto-select-first": "",
                },
                model: {
                  value: _vm.channelID,
                  callback: function ($$v) {
                    _vm.channelID = $$v
                  },
                  expression: "channelID",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "col-12 col-sm-6 col-md-4" },
            [
              _c("v-autocomplete", {
                attrs: {
                  label: "Rolle",
                  items: _vm.roles,
                  "item-value": "value",
                  "item-text": "label",
                  "auto-select-first": "",
                },
                model: {
                  value: _vm.role,
                  callback: function ($$v) {
                    _vm.role = $$v
                  },
                  expression: "role",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            color: "success",
            loading: _vm.loadingAddPermission,
            depressed: "",
            fab: "",
            disabled: !_vm.projectID || !_vm.channelID || !_vm.role,
          },
          on: { click: _vm.addPermission },
        },
        [_c("v-icon", [_vm._v("add")])],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mx-auto", attrs: { "max-width": "800", tile: "" } },
        _vm._l(_vm.permissions, function (project, index) {
          return _c(
            "v-list-item",
            { key: index, staticClass: "pt-2" },
            [
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    { staticClass: "font-weight-bold text-h5" },
                    [
                      _vm._v(
                        _vm._s((project || {}).name) +
                          " [" +
                          _vm._s((project || {}).id) +
                          "]"
                      ),
                    ]
                  ),
                  _c(
                    "v-list-item-content",
                    _vm._l(project.channels, function (channel, channelIndex) {
                      return _c(
                        "v-list-item",
                        { key: channelIndex },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              (channel || {}).id !== "*"
                                ? _c(
                                    "v-list-item-title",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        _vm._s((channel || {}).name) +
                                          " [" +
                                          _vm._s((channel || {}).id) +
                                          "]"
                                      ),
                                    ]
                                  )
                                : _c(
                                    "v-list-item-title",
                                    { staticClass: "success--text" },
                                    [_vm._v("Alle Channels")]
                                  ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-chip-group",
                                    _vm._l(channel.roles, function (role) {
                                      return _c(
                                        "v-chip",
                                        {
                                          key: role,
                                          attrs: { close: "", color: "light" },
                                          on: {
                                            "click:close": function ($event) {
                                              return _vm.removePermission(
                                                project.id,
                                                channel.id,
                                                role
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.roles.find(function (r) {
                                                return r.value === role
                                              }).label
                                            )
                                          ),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }