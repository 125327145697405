var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        fab: "",
                        fixed: "",
                        right: "",
                        bottom: "",
                        color: "green",
                        dark: "",
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_c("v-icon", [_vm._v("mdi-plus")])],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline mt-5" }, [
                  _vm._v("Neues Template"),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Name",
                                  rules: _vm.rules,
                                  required: "",
                                },
                                model: {
                                  value: _vm.template.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.template, "name", $$v)
                                  },
                                  expression: "template.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "ID",
                                  rules: _vm.rules,
                                  required: "",
                                },
                                model: {
                                  value: _vm.template.id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.template, "id", $$v)
                                  },
                                  expression: "template.id",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "number",
                                  label: "Anzahl Bilder",
                                },
                                model: {
                                  value: _vm.template.imageCount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.template, "imageCount", $$v)
                                  },
                                  expression: "template.imageCount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                staticStyle: { "max-width": "150px" },
                                attrs: {
                                  "append-outer-icon": "euro",
                                  type: "number",
                                  label: "Anzeigenpreis",
                                },
                                model: {
                                  value: _vm.template.price,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.template, "price", $$v)
                                  },
                                  expression: "template.price",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("Abbrechen")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "success",
                        depressed: "",
                        disabled: !_vm.valid,
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("Erstellen")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }