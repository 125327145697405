var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-text-field", {
        staticClass: "mb-4",
        staticStyle: { "max-width": "800px" },
        attrs: {
          label: "Berechtigungen durchsuchen",
          "prepend-icon": "search",
          "hide-details": "",
          clearable: "",
        },
        model: {
          value: _vm.search,
          callback: function ($$v) {
            _vm.search = $$v
          },
          expression: "search",
        },
      }),
      _c("v-treeview", {
        attrs: {
          items: _vm.resolvedSchema,
          value: _vm.value,
          search: _vm.search,
          "open-all": _vm.open,
          "expand-icon": "expand_more",
          selectable: "",
          dense: "",
          "selected-color": "success",
          "off-icon": "clear",
          "on-icon": "check",
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
        },
      }),
      _c("v-spacer", { staticClass: "my-5" }),
      _c("code", { domProps: { textContent: _vm._s(_vm.role) } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }