var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "projects" },
    [
      _c("p", { staticClass: "text-h5" }, [_vm._v("Projekte")]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticStyle: { "max-width": "450px" } },
            [_c("ProjectList")],
            1
          ),
          _vm.$router.currentRoute.params.projectID
            ? _c(
                "v-col",
                { staticStyle: { "max-height": "80vh" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "overflow-y-auto pa-5",
                      staticStyle: { "max-height": "80vh" },
                    },
                    [
                      _c("router-view", {
                        key: _vm.$router.currentRoute.params.projectID,
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            fab: "",
                            fixed: "",
                            bottom: "",
                            right: "",
                            color: "success",
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v(" add ")])],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { elevation: "0" } },
                [
                  _c("v-toolbar-title", [_vm._v("Neues Projekt")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        depressed: "",
                        text: "",
                        color: "error",
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "createForm",
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.nameRules,
                          label: "Name",
                          hint: _vm.pI,
                        },
                        model: {
                          value: _vm.projectName,
                          callback: function ($$v) {
                            _vm.projectName = $$v
                          },
                          expression: "projectName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "success",
                        text: "",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.create },
                    },
                    [_vm._v(" Erstellen ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }