var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-5" }, [
    _vm._m(0),
    _c("p", {
      staticClass: "text-h4 font-weight-bold mb-0",
      domProps: { textContent: _vm._s(_vm.definition.label) },
    }),
    _c("p", { domProps: { textContent: _vm._s(_vm.definition.description) } }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "primary mb-5",
        staticStyle: {
          width: "70px",
          height: "5px",
          position: "relative",
          "margin-left": "-20px",
        },
      },
      [
        _c("div", {
          staticClass: "primary",
          staticStyle: { width: "5px", height: "30px", position: "absolute" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }