var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("v-autocomplete", {
        staticClass: "pt-0",
        attrs: {
          clearable: "",
          items: _vm.users,
          "item-value": "id",
          loading: _vm.loading,
          "item-text": "name",
          label: _vm.loading ? "Wird geladen..." : "Person wählen...",
          "single-line": "",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "selection",
              fn: function (ref) {
                var item = ref.item
                var index = ref.index
                return [_c("UserChip", { attrs: { user: _vm.currentUser } })]
              },
            },
          ],
          null,
          false,
          2670026211
        ),
        model: {
          value: _vm.userId,
          callback: function ($$v) {
            _vm.userId = $$v
          },
          expression: "userId",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }