var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "text-h6 mb-4 grey--text" }, [
        _vm._v("Einstellungen"),
      ]),
      _vm.loading
        ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
        : _vm._e(),
      !_vm.loading
        ? _c(
            "v-tabs",
            [
              _c(
                "v-tab",
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v(" settings ")]),
                  _vm._v(" Allgemein "),
                ],
                1
              ),
              _c(
                "v-tab",
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(" translate "),
                  ]),
                  _vm._v(" Sprachen "),
                ],
                1
              ),
              _c(
                "v-tab",
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v(" code ")]),
                  _vm._v(" Comet "),
                ],
                1
              ),
              _c(
                "v-tab",
                [
                  _c("v-icon", { staticClass: "mr-3", attrs: { left: "" } }, [
                    _vm._v(" mail "),
                  ]),
                  _vm._v(" Mail "),
                ],
                1
              ),
              _c(
                "v-tab",
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(" format_list_numbered "),
                  ]),
                  _vm._v(" Status "),
                ],
                1
              ),
              _c(
                "v-tab",
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(" account_balance "),
                  ]),
                  _vm._v(" Buchhaltung "),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass: "pa-5",
                              attrs: { elevation: "0", tile: "" },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "headline font-weight-bold mb-5",
                                },
                                [_vm._v(" Allgemein ")]
                              ),
                              _c(
                                "p",
                                { staticClass: "text-overline mb-0 mt-5" },
                                [_vm._v(" Channel-Thumbnail ")]
                              ),
                              _c(
                                "v-row",
                                { staticClass: "px-3 mt-3" },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          src: _vm.thumbnailURL,
                                          height: "200",
                                          width: "300",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "placeholder",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "fill-height ma-0 grey lighten-4",
                                                      attrs: {
                                                        align: "center",
                                                        justify: "center",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Kein Bild gefunden "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          2338759570
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-file-input", {
                                        staticStyle: { "max-width": "500px" },
                                        attrs: {
                                          label: "Datei wählen",
                                          "truncate-length": "15",
                                          accept: "image/jpeg",
                                        },
                                        model: {
                                          value: _vm.channelThumbnail,
                                          callback: function ($$v) {
                                            _vm.channelThumbnail = $$v
                                          },
                                          expression: "channelThumbnail",
                                        },
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ml-5",
                                          attrs: {
                                            depressed: "",
                                            color: "primary",
                                          },
                                          on: {
                                            click: _vm.uploadChannelThumbnail,
                                          },
                                        },
                                        [_vm._v(" Hochladen ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "p",
                                { staticClass: "text-overline mb-0 mt-10" },
                                [_vm._v(" Neue Einträge anlegen ")]
                              ),
                              _c(
                                "v-row",
                                { staticClass: "px-3" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        staticStyle: { "max-width": "400px" },
                                        attrs: {
                                          "item-value": "value",
                                          "item-text": "description",
                                          items: [
                                            {
                                              description: "Deaktiviert",
                                              value: 0,
                                            },
                                            {
                                              description: "Aktiviert",
                                              value: 2,
                                            },
                                            {
                                              description:
                                                "Aktiviert (Verpflichtende Graph-Verlinkung)",
                                              value: 3,
                                            },
                                          ],
                                        },
                                        model: {
                                          value: _vm.config.general.create.mode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config.general.create,
                                              "mode",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "config.general.create.mode",
                                        },
                                      }),
                                      _vm.config.general.create.mode > 1
                                        ? _c("v-autocomplete", {
                                            staticStyle: {
                                              "max-width": "800px",
                                            },
                                            attrs: {
                                              multiple: "",
                                              label:
                                                "Zusätzliche Channelzuweisung",
                                              hint: "Diese Channels werden dem KG-Datensatz automatisch zugewiesen. z.B. für automatische Ausgabe auf Website.",
                                              "persistent-hint": "",
                                              "deletable-chips": "",
                                              chips: "",
                                              "item-value": "id",
                                              "item-text": "label",
                                              items: _vm.processedChannels,
                                            },
                                            model: {
                                              value:
                                                _vm.config.general.create
                                                  .autoChannel,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.config.general.create,
                                                  "autoChannel",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                                        config.general.create.autoChannel\n                                    ",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "p",
                                { staticClass: "text-overline mb-0 mt-5" },
                                [
                                  _vm._v(
                                    " Beschreibung aus IXEDIT in Graph speichern "
                                  ),
                                ]
                              ),
                              _c(
                                "v-row",
                                { staticClass: "px-3" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          label: _vm.config.graph
                                            .ixedit_desc_sync.enabled
                                            ? "aktiviert"
                                            : "deaktiviert",
                                          hint: "Speichert automatisch die Texte des zuletzt mit IXEDIT editierten Eintrags in 'description' des entsprechenden Graph-Datensatzes.",
                                          "persistent-hint": "",
                                        },
                                        model: {
                                          value:
                                            _vm.config.graph.ixedit_desc_sync
                                              .enabled,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config.graph.ixedit_desc_sync,
                                              "enabled",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                                        config.graph.ixedit_desc_sync\n                                            .enabled\n                                    ",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "px-3" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-dialog",
                                        {
                                          attrs: {
                                            persistent: "",
                                            "max-width": "600",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              color: "error",
                                                              depressed: "",
                                                            },
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " Channel zurücksetzen "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            229137940
                                          ),
                                          model: {
                                            value: _vm.resetDialog,
                                            callback: function ($$v) {
                                              _vm.resetDialog = $$v
                                            },
                                            expression: "resetDialog",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            { staticClass: "py-2" },
                                            [
                                              _c(
                                                "v-card-title",
                                                { staticClass: "text-h5" },
                                                [
                                                  _vm._v(
                                                    " Channel zurücksetzen "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      " Folgende Aktionen werden ausgeführt: "
                                                    ),
                                                  ]),
                                                  _c("ul", [
                                                    _c("li", [
                                                      _vm._v(
                                                        " ALLE Templates werden GELÖSCHT "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        " ALLE Eintragstypen werden GELÖSCHT "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        " ALLE Einstellungen werden GELÖSCHT "
                                                      ),
                                                    ]),
                                                  ]),
                                                  !_vm.deleteEntries
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "red--text mt-4",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Achtung! Die Einträge werden nicht automatisch gelöscht. Bitte entweder löschen oder die entsprechende Datenstruktur wieder konfigurieren. "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c("p", [
                                                    _vm._v(
                                                      " Bitte gib den Namen des Channels ein um zu bestätigen, dass du die Auswirkungen kennst: "
                                                    ),
                                                  ]),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "persistent-hint": "",
                                                      hint: _vm.channelName,
                                                      outlined: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.channelNameConfirmation,
                                                      callback: function ($$v) {
                                                        _vm.channelNameConfirmation =
                                                          $$v
                                                      },
                                                      expression:
                                                        "\n                                                    channelNameConfirmation\n                                                ",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-card-actions",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "error",
                                                        text: "",
                                                        large: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.resetDialog = false
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" Abbrechen ")]
                                                  ),
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "error",
                                                        depressed: "",
                                                        large: "",
                                                        disabled:
                                                          _vm.channelName !==
                                                          _vm.channelNameConfirmation,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.resetPrintSettings,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " UNWIDERRUFLICH LÖSCHEN "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass: "pa-5",
                              attrs: { elevation: "0", tile: "" },
                            },
                            [
                              _c(
                                "p",
                                { staticClass: "headline font-weight-bold" },
                                [_vm._v("Sprachen")]
                              ),
                              _c(
                                "p",
                                { staticClass: "text-overline mb-0 mt-3" },
                                [_vm._v(" Verfügbare Sprachen ")]
                              ),
                              _c("v-autocomplete", {
                                staticStyle: { "max-width": "500px" },
                                attrs: {
                                  filled: "",
                                  items: _vm.allLanguages,
                                  multiple: "",
                                  "deletable-chips": "",
                                  chips: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "input-value": data.selected,
                                                  close: "",
                                                },
                                                on: {
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    _vm.config.language.available.splice(
                                                      _vm.config.language.available.findIndex(
                                                        function (l) {
                                                          return (
                                                            l ===
                                                            data.item.value
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  },
                                                },
                                              },
                                              "v-chip",
                                              data.attrs,
                                              false
                                            ),
                                            [
                                              _c(
                                                "v-avatar",
                                                { attrs: { left: "" } },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src:
                                                        "https://webimages.we2p.de/static/flags/svg/" +
                                                        data.item.value.slice(
                                                          -2
                                                        ) +
                                                        ".svg",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.item.text) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item",
                                      fn: function (data) {
                                        return [
                                          typeof data.item !== "object"
                                            ? [
                                                _c("v-list-item-content", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      data.item
                                                    ),
                                                  },
                                                }),
                                              ]
                                            : [
                                                _c(
                                                  "v-list-item-avatar",
                                                  { attrs: { tile: "" } },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        "object-fit": "contain",
                                                      },
                                                      attrs: {
                                                        src:
                                                          "https://webimages.we2p.de/static/flags/svg/" +
                                                          data.item.value.slice(
                                                            -2
                                                          ) +
                                                          ".svg",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          data.item.text
                                                        ),
                                                      },
                                                    }),
                                                    _c("v-list-item-subtitle", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          data.item.value
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1432450199
                                ),
                                model: {
                                  value: _vm.config.language.available,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.config.language,
                                      "available",
                                      $$v
                                    )
                                  },
                                  expression: "config.language.available",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass: "pa-5",
                              attrs: { elevation: "0", tile: "" },
                            },
                            [
                              _c(
                                "p",
                                { staticClass: "headline font-weight-bold" },
                                [_vm._v("Comet")]
                              ),
                              _c(
                                "p",
                                { staticClass: "text-overline mb-0 mt-3" },
                                [_vm._v("Verbindung")]
                              ),
                              _c("v-text-field", {
                                staticStyle: { "max-width": "500px" },
                                attrs: { label: "Client", filled: "" },
                                model: {
                                  value: _vm.config.cometConnection.client,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.config.cometConnection,
                                      "client",
                                      $$v
                                    )
                                  },
                                  expression: "config.cometConnection.client",
                                },
                              }),
                              _c(
                                "p",
                                { staticClass: "text-overline mb-0 mt-3" },
                                [_vm._v("Sortierung")]
                              ),
                              _c("v-switch", {
                                staticClass: "mt-0",
                                staticStyle: { "max-width": "500px" },
                                attrs: { label: "", filled: "" },
                                model: {
                                  value:
                                    _vm.config.cometConnection.sorting.enabled,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.config.cometConnection.sorting,
                                      "enabled",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "config.cometConnection.sorting.enabled",
                                },
                              }),
                              _c("p", {
                                staticClass: "text-overline mb-0 mt-3",
                              }),
                              _c("v-text-field", {
                                staticStyle: { "max-width": "500px" },
                                attrs: {
                                  type: "number",
                                  label: "Seitenzahl der ersten Seite",
                                  filled: "",
                                },
                                model: {
                                  value:
                                    _vm.config.cometConnection
                                      .firstPageWithEntries,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.config.cometConnection,
                                      "firstPageWithEntries",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "\n                                config.cometConnection.firstPageWithEntries\n                            ",
                                },
                              }),
                              _c("p", {
                                staticClass: "text-overline mb-0 mt-3",
                              }),
                              _c("v-select", {
                                staticStyle: { "max-width": "500px" },
                                attrs: {
                                  items: [
                                    { value: "l", text: "links" },
                                    { value: "r", text: "rechts" },
                                  ],
                                  label: "Ausrichtung der ersten Seite",
                                  filled: "",
                                },
                                model: {
                                  value:
                                    _vm.config.cometConnection.sideOfPageOne,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.config.cometConnection,
                                      "sideOfPageOne",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "config.cometConnection.sideOfPageOne",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass: "pa-5",
                              attrs: { elevation: "0", tile: "" },
                            },
                            [
                              _c(
                                "p",
                                { staticClass: "headline font-weight-bold" },
                                [_vm._v(" Mail Konfiguration ")]
                              ),
                              _c("v-switch", {
                                attrs: {
                                  inset: "",
                                  label: _vm.config.mail.active
                                    ? "Mailversand aktiv"
                                    : "Mailversand inaktiv -> nur PDF Download",
                                },
                                model: {
                                  value: _vm.config.mail.active,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.config.mail, "active", $$v)
                                  },
                                  expression: "config.mail.active",
                                },
                              }),
                              _vm.config.mail.active
                                ? [
                                    _c(
                                      "p",
                                      { staticClass: "text-overline my-0" },
                                      [_vm._v("Absender")]
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "col-12 col-md-4" },
                                          [
                                            _c("v-text-field", {
                                              staticStyle: {
                                                "max-width": "500px",
                                              },
                                              attrs: {
                                                label: "Name",
                                                filled: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.config.mail.general
                                                    .senderName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.config.mail.general,
                                                    "senderName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                                            config.mail.general.senderName\n                                        ",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { staticClass: "col-12 col-md-4" },
                                          [
                                            _c("v-text-field", {
                                              staticStyle: {
                                                "max-width": "500px",
                                              },
                                              attrs: {
                                                label:
                                                  "Absender E-Mail (System)",
                                                filled: "",
                                                "persistent-hint": "",
                                                hint: "Sicherstellen, dass der Mail-Server entsprechend konfiguriert ist! (IP 193.58.245.3 whitelisten lassen.)",
                                              },
                                              model: {
                                                value:
                                                  _vm.config.mail.general
                                                    .senderMail,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.config.mail.general,
                                                    "senderMail",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                                            config.mail.general.senderMail\n                                        ",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { staticClass: "col-12 col-md-4" },
                                          [
                                            _c("v-text-field", {
                                              staticStyle: {
                                                "max-width": "500px",
                                              },
                                              attrs: {
                                                label: "Antwort E-Mail",
                                                filled: "",
                                                "persistent-hint": "",
                                                hint: "An diese E-Mail Adresse antwortet der Empfänger.",
                                              },
                                              model: {
                                                value:
                                                  _vm.config.mail.general
                                                    .replyToMail,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.config.mail.general,
                                                    "replyToMail",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                                            config.mail.general.replyToMail\n                                        ",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "text-overline my-0" },
                                      [_vm._v("Farben")]
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "col-12 col-md-4" },
                                          [
                                            _c("v-text-field", {
                                              staticStyle: {
                                                "max-width": "500px",
                                              },
                                              attrs: {
                                                label: "Primär",
                                                filled: "",
                                                color:
                                                  _vm.config.mail.color.primary,
                                              },
                                              model: {
                                                value:
                                                  _vm.config.mail.color.primary,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.config.mail.color,
                                                    "primary",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "config.mail.color.primary",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { staticClass: "col-12 col-md-4" },
                                          [
                                            _c("v-text-field", {
                                              staticStyle: {
                                                "max-width": "500px",
                                              },
                                              attrs: {
                                                label: "Hintergrund",
                                                filled: "",
                                                color:
                                                  _vm.config.mail.color
                                                    .background,
                                              },
                                              model: {
                                                value:
                                                  _vm.config.mail.color
                                                    .background,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.config.mail.color,
                                                    "background",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                                            config.mail.color.background\n                                        ",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "text-overline my-0" },
                                      [_vm._v("Logo")]
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "col-12 col-md-2" },
                                          [
                                            _c("v-text-field", {
                                              staticStyle: {
                                                "max-width": "500px",
                                              },
                                              attrs: {
                                                label: "Höhe (z.B. 100px)",
                                                filled: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.config.mail.general.logo
                                                    .height,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.config.mail.general
                                                      .logo,
                                                    "height",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                                            config.mail.general.logo.height\n                                        ",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { staticClass: "col-12 col-md-2" },
                                          [
                                            _c("v-text-field", {
                                              staticStyle: {
                                                "max-width": "500px",
                                              },
                                              attrs: {
                                                label: "Breite",
                                                filled: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.config.mail.general.logo
                                                    .width,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.config.mail.general
                                                      .logo,
                                                    "width",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                                            config.mail.general.logo.width\n                                        ",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { staticClass: "col-12 col-md-8" },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Quelle (URL)",
                                                filled: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.config.mail.general.logo
                                                    .src,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.config.mail.general
                                                      .logo,
                                                    "src",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                                            config.mail.general.logo.src\n                                        ",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "text-overline my-0" },
                                      [_vm._v("Header Bild")]
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "col-12 col-md-8" },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Quelle (URL)",
                                                filled: "",
                                                hint: "Channel-Thumbnail wird verwendet, falls leer.",
                                                "persistent-hint": "",
                                              },
                                              model: {
                                                value:
                                                  _vm.config.mail.general
                                                    .headerImage.src,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.config.mail.general
                                                      .headerImage,
                                                    "src",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                                            config.mail.general.headerImage\n                                                .src\n                                        ",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _c("p", { staticClass: "text-overline my-0" }),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "col-12 col-lg-6" },
                                    [
                                      _c(
                                        "v-sheet",
                                        {
                                          class:
                                            "grey " +
                                            (_vm.$vuetify.theme.isDark
                                              ? "darken-3"
                                              : "lighten-4") +
                                            " pa-5",
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "mx-0 pt-2 pb-3" },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "text-h6 mb-3" },
                                                [_vm._v("Akquise")]
                                              ),
                                              _c("v-spacer"),
                                              _c("v-switch", {
                                                staticClass: "ma-0",
                                                attrs: { dense: "", inset: "" },
                                                model: {
                                                  value:
                                                    _vm.config.mail.acquisition
                                                      .active,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config.mail
                                                        .acquisition,
                                                      "active",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                                                config.mail.acquisition\n                                                    .active\n                                            ",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.config.mail.acquisition.active &&
                                          _vm.config.mail.active
                                            ? [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Betreff",
                                                    filled: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.config.mail
                                                        .acquisition.subject,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.config.mail
                                                          .acquisition,
                                                        "subject",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                                                config.mail.acquisition\n                                                    .subject\n                                            ",
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-overline mb-3",
                                                  },
                                                  [_vm._v(" Body ")]
                                                ),
                                                _c("ckeditor", {
                                                  attrs: { editor: _vm.editor },
                                                  model: {
                                                    value:
                                                      _vm.config.mail
                                                        .acquisition.bodyText,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.config.mail
                                                          .acquisition,
                                                        "bodyText",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                                                config.mail.acquisition\n                                                    .bodyText\n                                            ",
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-overline mb-3 mt-5",
                                                  },
                                                  [_vm._v(" Footer ")]
                                                ),
                                                _c("ckeditor", {
                                                  attrs: { editor: _vm.editor },
                                                  model: {
                                                    value:
                                                      _vm.config.mail
                                                        .acquisition.footerText,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.config.mail
                                                          .acquisition,
                                                        "footerText",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                                                config.mail.acquisition\n                                                    .footerText\n                                            ",
                                                  },
                                                }),
                                              ]
                                            : _vm.config.mail.active
                                            ? [
                                                _c("p", [
                                                  _vm._v(
                                                    "Akquise ist deaktiviert."
                                                  ),
                                                ]),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { staticClass: "col-12 col-lg-6" },
                                    [
                                      _c(
                                        "v-sheet",
                                        {
                                          class:
                                            "grey " +
                                            (_vm.$vuetify.theme.isDark
                                              ? "darken-3"
                                              : "lighten-4") +
                                            " pa-5",
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "mx-0 pt-2 pb-3" },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "text-h6 mb-3" },
                                                [_vm._v("Freigabe")]
                                              ),
                                              _c("v-spacer"),
                                              _c("v-switch", {
                                                staticClass: "ma-0",
                                                attrs: { dense: "", inset: "" },
                                                model: {
                                                  value:
                                                    _vm.config.mail.confirmation
                                                      .active,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config.mail
                                                        .confirmation,
                                                      "active",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                                                config.mail.confirmation\n                                                    .active\n                                            ",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.config.mail.confirmation.active &&
                                          _vm.config.mail.active
                                            ? [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Betreff",
                                                    filled: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.config.mail
                                                        .confirmation.subject,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.config.mail
                                                          .confirmation,
                                                        "subject",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                                                config.mail.confirmation\n                                                    .subject\n                                            ",
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-overline mb-3",
                                                  },
                                                  [_vm._v(" Body ")]
                                                ),
                                                _c("ckeditor", {
                                                  attrs: { editor: _vm.editor },
                                                  model: {
                                                    value:
                                                      _vm.config.mail
                                                        .confirmation.bodyText,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.config.mail
                                                          .confirmation,
                                                        "bodyText",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                                                config.mail.confirmation\n                                                    .bodyText\n                                            ",
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-overline mb-3 mt-5",
                                                  },
                                                  [_vm._v(" Footer ")]
                                                ),
                                                _c("ckeditor", {
                                                  attrs: { editor: _vm.editor },
                                                  model: {
                                                    value:
                                                      _vm.config.mail
                                                        .confirmation
                                                        .footerText,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.config.mail
                                                          .confirmation,
                                                        "footerText",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                                                config.mail.confirmation\n                                                    .footerText\n                                            ",
                                                  },
                                                }),
                                              ]
                                            : _vm.config.mail.active
                                            ? [
                                                _c("p", [
                                                  _vm._v(
                                                    "Freigabe ist deaktiviert."
                                                  ),
                                                ]),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass: "pa-5",
                              attrs: { elevation: "0", tile: "" },
                            },
                            [
                              _c(
                                "p",
                                { staticClass: "headline font-weight-bold" },
                                [_vm._v(" Status Workflows ")]
                              ),
                              _c(
                                "p",
                                { staticClass: "text-overline mb-0 mt-3" },
                                [_vm._v(" Akquise Workflow ")]
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-switch", {
                                        staticStyle: { "max-width": "400px" },
                                        attrs: {
                                          label: _vm.config.status.acquisition
                                            .enabled
                                            ? "aktiviert"
                                            : "deaktiviert",
                                        },
                                        model: {
                                          value:
                                            _vm.config.status.acquisition
                                              .enabled,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config.status.acquisition,
                                              "enabled",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                                        config.status.acquisition.enabled\n                                    ",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "p",
                                { staticClass: "text-overline mb-0 mt-3" },
                                [_vm._v(" Freigabe Workflow ")]
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-switch", {
                                        staticStyle: { "max-width": "400px" },
                                        attrs: {
                                          label: "Automatische Freigabe",
                                        },
                                        model: {
                                          value:
                                            _vm.config.status.confirmation.auto,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config.status.confirmation,
                                              "auto",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                                        config.status.confirmation.auto\n                                    ",
                                        },
                                      }),
                                      _vm.config.status.confirmation.auto
                                        ? [
                                            _c("v-text-field", {
                                              staticClass: "d-inline-block",
                                              staticStyle: {
                                                "max-width": "100px",
                                              },
                                              attrs: {
                                                dense: "",
                                                type: "number",
                                                placeholder: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.config.status.confirmation
                                                    .afterDays,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.config.status
                                                      .confirmation,
                                                    "afterDays",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                                            config.status.confirmation\n                                                .afterDays\n                                        ",
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                " Tage bis zur automatischen Freigabe (0 = sofortige Freigabe)"
                                              ),
                                            ]),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "p",
                                { staticClass: "text-overline mb-0 mt-3" },
                                [
                                  _vm._v(
                                    " Nur benutzerdefinierte Status anzeigen "
                                  ),
                                ]
                              ),
                              _c("p", [
                                _vm._v(
                                  " Wenn diese Option aktiviert ist, werden auch keine automatischen Status-Übergänge mehr ausgeführt. "
                                ),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-switch", {
                                        staticStyle: { "max-width": "400px" },
                                        model: {
                                          value:
                                            _vm.config.status
                                              .onlyCustomWorkflows,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config.status,
                                              "onlyCustomWorkflows",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                                        config.status.onlyCustomWorkflows\n                                    ",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "p",
                                { staticClass: "text-overline mb-0 mt-3" },
                                [_vm._v(" Benutzerdefinierte Status ")]
                              ),
                              _c("StatusList", {
                                model: {
                                  value: _vm.config.status.customStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.config.status,
                                      "customStatus",
                                      $$v
                                    )
                                  },
                                  expression: "config.status.customStatus",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass: "pa-5",
                              attrs: { elevation: "0", tile: "" },
                            },
                            [
                              _c(
                                "p",
                                { staticClass: "headline font-weight-bold" },
                                [_vm._v("Buchhaltung")]
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-switch", {
                                        staticStyle: { "max-width": "400px" },
                                        attrs: {
                                          label: _vm.config.accounting.enabled
                                            ? "aktiviert"
                                            : "deaktiviert",
                                        },
                                        model: {
                                          value: _vm.config.accounting.enabled,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config.accounting,
                                              "enabled",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "config.accounting.enabled",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-btn",
        {
          staticStyle: { "margin-top": "70px" },
          attrs: {
            color: _vm.saveRequired ? "warning" : "success",
            fixed: "",
            top: "",
            right: "",
            fab: "",
            "x-large": "",
            depressed: "",
            loading: _vm.saving,
          },
          on: { click: _vm.saveConfig },
        },
        [_c("v-icon", [_vm._v("save")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }