var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "printsuite" },
    [
      _c(
        "v-btn",
        {
          staticClass: "mb-5 mt-2",
          attrs: {
            text: "",
            depressed: "",
            router: "",
            to:
              "/projects/" +
              _vm.$router.currentRoute.params.projectID +
              "/channels",
          },
        },
        [
          _c("v-icon", { staticClass: "mr-3" }, [_vm._v("arrow_back")]),
          _vm._v(" alle Channels "),
        ],
        1
      ),
      _c("h1", { staticClass: "display-1 mt-4 font-weight-bold" }, [
        _vm._v(_vm._s(_vm.channelName)),
      ]),
      _c("p", { staticClass: "mb-5" }, [
        _vm._v(
          " Hier können Sie alle print-relevanten Datensätze und Einstellungen pflegen. "
        ),
      ]),
      !_vm.loadingCatalogs &&
      _vm.$permGuard.chP("actions.import_settings") &&
      _vm.$settings.getValue("noSettingsYet")
        ? [
            _c("p", { staticClass: "mt-5 pt-5 caption" }, [
              _vm._v(
                " Für diesen Kanal gibt es noch keine Versionen/Einstellungen. "
              ),
            ]),
            _c(
              "v-btn",
              {
                staticClass: "success mb-5",
                attrs: { depressed: "", rounded: "", "x-large": "" },
                on: {
                  click: function ($event) {
                    _vm.configCloneDialog = true
                  },
                },
              },
              [_vm._v(" Einstellungen importieren? ")]
            ),
            _c("v-spacer", { staticClass: "py-5" }),
          ]
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.configCloneDialog,
            callback: function ($$v) {
              _vm.configCloneDialog = $$v
            },
            expression: "configCloneDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("span", [_vm._v("Einstellungen importieren")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", fab: "", text: "", small: "" },
                      on: {
                        click: function ($event) {
                          _vm.configCloneDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("h5", [_vm._v("Hinweis:")]),
                  _c("ul", { staticClass: "mb-4" }, [
                    _c("li", [_vm._v("Eintragstypen werden kopiert")]),
                    _c("li", [_vm._v("Templates werden kopiert")]),
                    _c("li", [
                      _vm._v(" Einstellungen werden "),
                      _c("strong", [_vm._v("überschrieben")]),
                    ]),
                  ]),
                  _c("p", [
                    _vm._v(
                      " von welchem Channel sollen die Einstellungen übernommen werden? "
                    ),
                  ]),
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.processedChannels,
                      placeholder: "Channel wählen...",
                      "item-value": "id",
                      "item-text": "label",
                    },
                    model: {
                      value: _vm.sourceChannelID,
                      callback: function ($$v) {
                        _vm.sourceChannelID = $$v
                      },
                      expression: "sourceChannelID",
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        rounded: "",
                        color: "info",
                        depressed: "",
                        disabled: !_vm.sourceChannelID,
                        loading: _vm.loadingCloneConfig,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.cloneConfig(_vm.sourceChannelID)
                        },
                      },
                    },
                    [_vm._v(" Importieren ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        _vm._l(_vm.allowedMenu, function (menuitem, index) {
          return _c(
            "v-col",
            {
              key: index,
              attrs: {
                index: index,
                item: menuitem,
                cols: "12",
                sm: "12",
                md: "6",
                lg: "4",
                xl: "3",
              },
            },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c(
                    "v-list-item",
                    { attrs: { "three-line": "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("div", { staticClass: "overline mb-4" }),
                          _c(
                            "v-list-item-title",
                            { staticClass: "headline mb-1" },
                            [_vm._v(" " + _vm._s(menuitem.title) + " ")]
                          ),
                          _c("v-list-item-subtitle", [
                            _vm._v(_vm._s(menuitem.description)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-avatar",
                        { attrs: { tile: "", size: "80" } },
                        [
                          _c("v-icon", { attrs: { size: "64" } }, [
                            _vm._v(_vm._s(menuitem.icon)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            rounded: "",
                            router: "",
                            to: "" + menuitem.path,
                            append: "",
                          },
                        },
                        [_vm._v(" bearbeiten ")]
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  menuitem.path === "catalogs" &&
                  _vm.$store.getters["allCatalogs"].length > 0
                    ? _c(
                        "v-list",
                        [
                          _c("v-subheader", [_vm._v(" Schnellauswahl: ")]),
                          _vm._l(
                            _vm.$store.getters["allCatalogs"],
                            function (catalog, catalogIndex) {
                              return [
                                _c(
                                  "v-list-item",
                                  {
                                    key: catalogIndex,
                                    attrs: {
                                      router: "",
                                      append: "",
                                      to: "catalogs/" + catalog._id.toString(),
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          { staticClass: "primary--text" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(catalog.name) + " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-icon", [_vm._v("launch")]),
                                  ],
                                  1
                                ),
                                catalogIndex <
                                _vm.$store.getters["allCatalogs"].length - 1
                                  ? _c("v-divider", {
                                      key: catalogIndex + "__divider",
                                    })
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }