var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "form",
      {
        staticStyle: { display: "none" },
        attrs: { action: _vm.editorURL, method: "post", target: "traileditor" },
      },
      [_c("button", { ref: "formSubmit", attrs: { type: "submit" } })]
    ),
    _c("iframe", {
      staticClass: "traileditor-iframe",
      attrs: { name: "traileditor", allowfullscreen: "" },
    }),
    _vm.loading
      ? _c("div", { staticClass: "loader" }, [
          _c(
            "div",
            { staticClass: "mx-5" },
            [
              _c("LoadingSpinner", {
                staticStyle: { margin: "auto !important" },
                attrs: { color: "#720a21", size: 80 },
              }),
            ],
            1
          ),
          _c(
            "p",
            { staticClass: "text-center mt-5 text-h5 font-weight-light" },
            [_vm._v("Editor wird vorbereitet...")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }