var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-list",
        {
          staticClass: "py-0 mt-3",
          attrs: { "max-width": "500", elevation: "1" },
        },
        [
          _c(
            "draggable",
            _vm._b(
              {
                attrs: { handle: ".handle" },
                on: {
                  start: function ($event) {
                    _vm.drag = true
                  },
                  end: function ($event) {
                    _vm.drag = false
                  },
                },
                model: {
                  value: _vm.statusList,
                  callback: function ($$v) {
                    _vm.statusList = $$v
                  },
                  expression: "statusList",
                },
              },
              "draggable",
              _vm.dragOptions,
              false
            ),
            [
              _c(
                "transition-group",
                {
                  attrs: {
                    type: "transition",
                    name: !_vm.drag ? "flip-list" : null,
                  },
                },
                [
                  _vm._l(_vm.statusList, function (status, index) {
                    return [
                      _c(
                        "v-list-item",
                        { key: index, staticClass: "my-0 listing-item" },
                        [
                          [
                            _c(
                              "v-list-item-avatar",
                              {
                                staticClass: "handle",
                                staticStyle: { cursor: "grab" },
                              },
                              [_c("v-icon", [_vm._v(" drag_handle ")])],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-list-item-title",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { color: status.color },
                                      },
                                      [_vm._v("circle")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "vertical-align": "middle",
                                        },
                                      },
                                      [_vm._v(_vm._s(status.label))]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-subtitle",
                                  { staticClass: "ml-9" },
                                  [_vm._v(" " + _vm._s(status.value) + " ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-action",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c("DeleteConfirmation", {
                                      on: {
                                        yes: function ($event) {
                                          return _vm.deleteStatus(index)
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "mr-3",
                                                        attrs: {
                                                          color: "error",
                                                          fab: "",
                                                          depressed: "",
                                                          "x-small": "",
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "white",
                                                          size: "16",
                                                        },
                                                      },
                                                      [_vm._v("close")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          "close-on-content-click": false,
                                          "nudge-width": 200,
                                          "offset-x": "",
                                          "max-width": "800",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass: "mr-3",
                                                          attrs: {
                                                            color: "black",
                                                            fab: "",
                                                            depressed: "",
                                                            "x-small": "",
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "white",
                                                            size: "16",
                                                          },
                                                        },
                                                        [_vm._v("edit")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          { staticClass: "py-3 px-2" },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Name",
                                                value: status.label,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.change(
                                                    status,
                                                    index,
                                                    "label",
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Wert in XML",
                                                value: (
                                                  status.value || ""
                                                ).replaceAll("custom_", ""),
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.change(
                                                    status,
                                                    index,
                                                    "value",
                                                    "custom_" + $event
                                                  )
                                                },
                                              },
                                            }),
                                            _c("v-checkbox", {
                                              staticClass: "my-0",
                                              attrs: { label: "Status sperrt" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.change(
                                                    status,
                                                    index,
                                                    "lock",
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: status.lock,
                                                callback: function ($$v) {
                                                  _vm.$set(status, "lock", $$v)
                                                },
                                                expression: "status.lock",
                                              },
                                            }),
                                            _c("v-checkbox", {
                                              staticClass: "my-0",
                                              attrs: {
                                                label: "Schwarzer Text",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.change(
                                                    status,
                                                    index,
                                                    "blackText",
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: status.blackText,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    status,
                                                    "blackText",
                                                    $$v
                                                  )
                                                },
                                                expression: "status.blackText",
                                              },
                                            }),
                                            _c("v-color-picker", {
                                              attrs: {
                                                label: "Farbe",
                                                "hide-mode-switch": "",
                                                mode: _vm.colorPickerMode,
                                                value: status.color,
                                              },
                                              on: {
                                                "update:mode": function (
                                                  $event
                                                ) {
                                                  _vm.colorPickerMode = $event
                                                },
                                                input: function ($event) {
                                                  return _vm.change(
                                                    status,
                                                    index,
                                                    "color",
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "ml-4 mt-3",
          attrs: { fab: "", depressed: "", small: "", color: "success" },
          on: { click: _vm.addStatus },
        },
        [_c("v-icon", [_vm._v("add")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }