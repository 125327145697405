var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentLang
    ? _c("v-select", {
        style: "max-width: 250px",
        attrs: {
          items: _vm.languages,
          outlined: "",
          dense: _vm.dense,
          label: "Sprache",
          value: _vm.currentLang.value,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "prepend",
              fn: function () {
                return [
                  _c("v-avatar", { attrs: { size: _vm.dense ? 20 : 28 } }, [
                    _c("img", {
                      staticStyle: { "object-fit": "cover" },
                      attrs: {
                        src:
                          "https://webimages.we2p.de/static/flags/svg/" +
                          _vm.currentLang.value.slice(-2) +
                          ".svg",
                      },
                    }),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3819369393
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }