var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "text-h6 mb-4 grey--text" }, [
        _vm._v("E-Mail Templates"),
      ]),
      _c("v-container", { staticClass: "my-5" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }