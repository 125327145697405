var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline-block" },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mr-3 white--text",
                          attrs: {
                            depressed: "",
                            rounded: "",
                            color: "blue darken-3",
                            disabled: _vm.isLocked,
                          },
                          on: { click: _vm.sendToEditor },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { left: "" } }, [_vm._v("image")]),
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("text_fields"),
                      ]),
                      _vm._v(" InDesign "),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c(
                "v-toolbar",
                { attrs: { elevation: "2" } },
                [
                  _c("v-toolbar-title", [
                    _c("img", {
                      staticClass: "mt-3",
                      attrs: { src: _vm.logoURL, height: "20" },
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "ml-2 font-weight-light",
                        staticStyle: { "vertical-align": "text-bottom" },
                      },
                      [_vm._v("InDesign Editor")]
                    ),
                  ]),
                  _vm.language
                    ? _c(
                        "v-avatar",
                        { staticClass: "ml-3 my-auto", attrs: { size: "20" } },
                        [
                          _c("v-img", {
                            attrs: {
                              src:
                                "https://webimages.we2p.de/static/flags/svg/" +
                                _vm.currentLanguage.slice(-2) +
                                ".svg",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "mr-1",
                                      attrs: {
                                        icon: "",
                                        text: "",
                                        color: "info",
                                      },
                                      on: { click: _vm.forceReload },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("refresh")])],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Editor neu laden")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c("DeleteConfirmation", {
                                attrs: {
                                  msg: "Nicht gespeicherte Änderungen gehen eventuell verloren.",
                                  title: "Wirklich beenden?",
                                },
                                on: {
                                  yes: function ($event) {
                                    _vm.dialog = false
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "mr-0",
                                                      attrs: {
                                                        icon: "",
                                                        text: "",
                                                        color: "error",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("close"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Schließen ohne Speichern")])]
                  ),
                ],
                1
              ),
              _vm.template && _vm.template.sideAware && !_vm.chosenSide
                ? _c("div", { staticStyle: { height: "calc(100vh - 80px)" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                          position: "absolute",
                        },
                      },
                      [
                        _c("p", {}, [
                          _vm._v(
                            " Die Darstellung des Templates ist abhängig davon, ob es mit einer linken oder rechten Seite beginnt. Bitte wählen Sie, in welcher Darstellung Sie das Template bearbeiten wollen. "
                          ),
                        ]),
                        _c(
                          "v-btn-toggle",
                          {
                            attrs: { borderless: "" },
                            model: {
                              value: _vm.chosenSide,
                              callback: function ($$v) {
                                _vm.chosenSide = $$v
                              },
                              expression: "chosenSide",
                            },
                          },
                          [
                            _c(
                              "v-btn",
                              { attrs: { value: "left", "x-large": "" } },
                              [
                                _c(
                                  "span",
                                  { staticClass: "hidden-sm-and-down" },
                                  [_vm._v("Links")]
                                ),
                                _c(
                                  "v-icon",
                                  { staticClass: "mr-0", attrs: { right: "" } },
                                  [_vm._v(" auto_stories ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              { attrs: { value: "right", "x-large": "" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-2 ml-0",
                                    staticStyle: { transform: "scaleX(-1)" },
                                    attrs: { right: "" },
                                  },
                                  [_vm._v(" auto_stories ")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "hidden-sm-and-down" },
                                  [_vm._v("Rechts")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.template && (!_vm.template.sideAware || _vm.chosenSide)
                ? _c(
                    "div",
                    { staticStyle: { height: "calc(100vh - 80px)" } },
                    [
                      _c("InDesignEditor", {
                        key: _vm.componentKey,
                        attrs: {
                          entry: _vm.entry,
                          side: _vm.chosenSide,
                          language: _vm.currentLanguage,
                        },
                        on: {
                          close: _vm.close,
                          save: function ($event) {
                            return _vm.$emit("save")
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }