var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sort-definition-nested", {
        attrs: {
          items: _vm.sortDefinition,
          "hide-templates": _vm.hideTemplates,
        },
        on: {
          move: _vm.move,
          entrySave: function ($event) {
            return _vm.$emit("entrySave")
          },
          save: _vm.save,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }