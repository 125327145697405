var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.contact
    ? _c(
        "div",
        { staticClass: "my-5" },
        [
          _c(
            "v-row",
            { staticClass: "mx-2 mb-5" },
            [
              _c("v-spacer"),
              _c("DeleteConfirmation", { on: { yes: _vm.remove } }),
            ],
            1
          ),
          _c("p", { staticClass: "text-h4 font-weight-bold" }, [
            _vm._v(_vm._s(_vm.getDisplayName(_vm.contact, "Neuer Kontakt"))),
          ]),
          _c("ContactEditForm", {
            model: {
              value: _vm.contact,
              callback: function ($$v) {
                _vm.contact = $$v
              },
              expression: "contact",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }