<template>
    <div>

        <p class="text-h6 mb-4 grey--text">Auflagen</p>

        <v-container class="my-5">

            <div class="my-5 text-center" v-if="allCatalogs.length === 0">
                <v-icon size="128" class="mb-4 grey--text">menu_book</v-icon>
                <p class="text-h4 my-5">Noch keine Produktionen.</p>
            </div>

            <v-card flat class="px-3 py-5 mb-4" v-for="(catalog, index) in allCatalogs" :item="catalog" :index="index" :key="catalog._id">
                <v-layout row wrap class="px-4">
                    <v-flex xs12 md4>
                        <div class="caption grey--text">Auflage</div>
                        <p class="text-h5 mb-0">
                            {{ catalog.name }}
                        </p>
                        <p class="mb-0 text-caption">erstellt am {{ toDate(catalog.createdAt) }}</p>
                    </v-flex>

                    <v-spacer></v-spacer>

                    <v-flex xs12 sm3>
                        <v-container fill-height class="ml-auto">
                            <v-spacer/>
                            <!-- edit -->

                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" class="white--text mr-3" small depressed fab dark color="info" router :to="`/projects/${$router.currentRoute.params.projectID}/channels/${$router.currentRoute.params.channelID}/print/catalogs/${catalog._id}`">
                                        <v-icon>read_more</v-icon>
                                    </v-btn>
                                </template>
                                <span>Einträge ansehen</span>
                            </v-tooltip>

                            <!-- duplicate -->
                            <DuplicateCatalog v-if="$permGuard.chP('catalogs.c')" :catalog="catalog"></DuplicateCatalog>

                            <DeleteConfirmation v-if="$permGuard.chP('catalogs.d')" @yes="removeCatalog(catalog._id)"/>

                        </v-container>
                    </v-flex>


                </v-layout>
            </v-card>
        </v-container>

        <CatalogCreate/>

    </div>

</template>

<script>

import Vue from 'vue';

import { mapGetters, mapActions } from 'vuex';
import CatalogCreate from "./CatalogCreate";
import UserChip from "../users/UserChip";
import DeleteConfirmation from "../utility/DeleteConfirmation";
import CatalogPlannerEditorDialog from "@/components/entries/fullscreen/CatalogPlannerEditorDialog";
import StatusRuleBuilder from "./StatusRuleBuilder";
import DuplicateCatalog from "./DuplicateCatalog";
import { DateTime} from "luxon";

export default {
    name: 'CatalogList',
    components: {
        DuplicateCatalog,
        DeleteConfirmation,
        CatalogCreate,
        // UserChip
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['allCatalogs']),
    },
    methods: {
        ...mapActions(['fetchCatalogsByChannel', 'removeCatalog']),
        toDate(date) {
            return new DateTime.fromISO(date).setLocale('de').toLocaleString(DateTime.DATE_SHORT);
        }
    },
    created() {
        this.fetchCatalogsByChannel(this.$router.currentRoute.params.channelID);
    }
}
</script>

<style scoped>
.content {
    padding: 30px;
    margin: 30px;
}
</style>
