var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "800" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "white--text",
                      attrs: {
                        color: "black",
                        fab: "",
                        depressed: "",
                        small: "",
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_c("v-icon", [_vm._v(" edit_note ")])],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                { staticClass: "my-4 mx-0" },
                [
                  _c("span", { staticClass: "text-h5 font-weight-bold" }, [
                    _vm._v("Informationen"),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        depressed: "",
                        color: "success",
                        small: "",
                        fab: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("save")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("save")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        depressed: "",
                        color: "error",
                        text: "",
                        small: "",
                        fab: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                staticClass: "align-right mb-0",
                staticStyle: { "max-width": "300px" },
                attrs: {
                  type: "number",
                  label: "Anzeigenpreis",
                  "append-outer-icon": "euro",
                  hint: _vm.template.price
                    ? "wenn abweichend vom Template-Preis"
                    : "",
                  "persistent-hint": "",
                },
                model: {
                  value: _vm.meta.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.meta, "price", $$v)
                  },
                  expression: "meta.price",
                },
              }),
              _vm.template.price
                ? _c("p", { staticClass: "mt-1" }, [
                    _vm._v("Standard-Preis für dieses Template: "),
                    _c("span", { staticClass: "blue--text" }, [
                      _vm._v(_vm._s(_vm.template.price + " €")),
                    ]),
                  ])
                : _vm._e(),
              _c("p", { staticClass: "mt-8 mb-0 font-wseight-bold text-h6" }, [
                _vm._v("Rechnungskontakt"),
              ]),
              _c(
                "v-row",
                { staticClass: "mx-0 mt-0" },
                [
                  _c("v-autocomplete", {
                    staticStyle: { "max-width": "400px" },
                    attrs: {
                      label: _vm.getSalutationLabel(_vm.billingContact),
                      loading:
                        _vm.$store.getters["contacts/getStatus"] !== "success",
                      items: _vm.contacts,
                    },
                    model: {
                      value: _vm.meta.billingContact,
                      callback: function ($$v) {
                        _vm.$set(_vm.meta, "billingContact", $$v)
                      },
                      expression: "meta.billingContact",
                    },
                  }),
                  _vm.billingContact
                    ? _c("v-dialog", {
                        attrs: {
                          transition: "dialog-top-transition",
                          "max-width": "800",
                          persistent: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "my-auto ml-3",
                                          attrs: {
                                            "x-small": "",
                                            depressed: "",
                                            fab: "",
                                            color: "grey",
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [_vm._v("edit")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (dialog) {
                                return [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "mx-0 my-2" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getDisplayName(
                                                      _vm.billingContact,
                                                      "Neuer Kontakt"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "red",
                                                    fab: "",
                                                    small: "",
                                                    loading: _vm.savingContact,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      dialog.value = false
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("close"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("ContactEditForm", {
                                            on: {
                                              input: _vm.saveBillingContact,
                                            },
                                            model: {
                                              value: _vm.billingContact,
                                              callback: function ($$v) {
                                                _vm.billingContact = $$v
                                              },
                                              expression: "billingContact",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1307413514
                        ),
                        model: {
                          value: _vm.billingContactDialog,
                          callback: function ($$v) {
                            _vm.billingContactDialog = $$v
                          },
                          expression: "billingContactDialog",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "my-auto ml-3",
                      attrs: {
                        "x-small": "",
                        depressed: "",
                        fab: "",
                        color: "success",
                        loading: _vm.creatingContact,
                      },
                      on: { click: _vm.createNewContact },
                    },
                    [
                      _c("v-icon", { attrs: { color: "white" } }, [
                        _vm._v("add"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.billingContact
                ? [
                    _c("p", { staticClass: "my-0" }, [
                      _vm._v("Bank: "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.billingContact.bank)),
                      ]),
                    ]),
                    _c("p", { staticClass: "my-0" }, [
                      _vm._v("IBAN: "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.billingContact.iban)),
                      ]),
                    ]),
                    _vm.billingContact.bic
                      ? _c("p", { staticClass: "my-0" }, [
                          _vm._v("BIC: "),
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.billingContact.bic)),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
              _c("v-textarea", {
                staticClass: "mt-5",
                attrs: { label: "Informationen zur Rechnungsstellung" },
                model: {
                  value: _vm.meta.billingNotice,
                  callback: function ($$v) {
                    _vm.$set(_vm.meta, "billingNotice", $$v)
                  },
                  expression: "meta.billingNotice",
                },
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }