var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("LabelComponent", {
        attrs: { definition: _vm.definition, "is-locked": _vm.isLocked },
      }),
      _c(
        "v-text-field",
        {
          attrs: {
            dense: "",
            filled: "",
            readonly: _vm.isLocked,
            rules: _vm.rules,
            value: _vm.data,
            "hide-details": "auto",
          },
          on: {
            input: function ($event) {
              return _vm.change($event, _vm.currentLang)
            },
          },
        },
        [
          _c(
            "template",
            { slot: "append" },
            [
              _c(
                "v-avatar",
                { attrs: { size: "20" } },
                [
                  _c("v-img", {
                    attrs: {
                      src:
                        "https://webimages.we2p.de/static/flags/svg/" +
                        _vm.currentLang.slice("-2") +
                        ".svg",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }