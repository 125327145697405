var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1000", persistent: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              !_vm.create
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { fab: "", depressed: "", "x-small": "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("edit")])],
                    1
                  )
                : _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            fab: "",
                            depressed: "",
                            large: "",
                            fixed: "",
                            bottom: "",
                            right: "",
                            "x-small": "",
                            color: "success",
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("add")])],
                    1
                  ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                { staticClass: "py-5 px-3" },
                [
                  _c("h3", [
                    _vm._v(
                      "Import " +
                        _vm._s(_vm.create ? "registrieren" : "anpassen")
                    ),
                  ]),
                  _c("v-spacer"),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-autocomplete", {
                attrs: {
                  multiple: "",
                  chips: "",
                  "deletable-chips": "",
                  items: _vm.projects,
                  label: "Projekte",
                },
                model: {
                  value: _vm.cronjob.projectIdentifiers,
                  callback: function ($$v) {
                    _vm.$set(_vm.cronjob, "projectIdentifiers", $$v)
                  },
                  expression: "cronjob.projectIdentifiers",
                },
              }),
              _c("v-autocomplete", {
                staticStyle: { "max-width": "300px" },
                attrs: { items: _vm.schemas, label: "Schema" },
                model: {
                  value: _vm.cronjob.importSchema,
                  callback: function ($$v) {
                    _vm.$set(_vm.cronjob, "importSchema", $$v)
                  },
                  expression: "cronjob.importSchema",
                },
              }),
              _c("v-text-field", {
                staticStyle: { "max-width": "300px" },
                attrs: { multiple: "", label: "Quelle" },
                model: {
                  value: _vm.cronjob.source,
                  callback: function ($$v) {
                    _vm.$set(_vm.cronjob, "source", $$v)
                  },
                  expression: "cronjob.source",
                },
              }),
              _c("v-text-field", {
                staticClass: "mb-6",
                staticStyle: { "max-width": "300px" },
                attrs: {
                  multiple: "",
                  label: "Cronjob",
                  "persistent-hint": "",
                  hint: _vm.cron,
                },
                model: {
                  value: _vm.cronjob.cron,
                  callback: function ($$v) {
                    _vm.$set(_vm.cronjob, "cron", $$v)
                  },
                  expression: "cronjob.cron",
                },
              }),
              _c("v-text-field", {
                attrs: { multiple: "", label: "Feed URL" },
                model: {
                  value: _vm.cronjob.feedUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.cronjob, "feedUrl", $$v)
                  },
                  expression: "cronjob.feedUrl",
                },
              }),
              _c("v-checkbox", {
                staticStyle: { "max-width": "220px" },
                attrs: { label: "Bilder importieren?" },
                model: {
                  value: _vm.cronjob.imageImport,
                  callback: function ($$v) {
                    _vm.$set(_vm.cronjob, "imageImport", $$v)
                  },
                  expression: "cronjob.imageImport",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "py-4" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { depressed: "", large: "", color: "error" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("Abbrechen")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    depressed: "",
                    large: "",
                    loading: _vm.saving,
                    color: "success",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.create ? "Erstellen" : "OK"))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }