var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "overflow-y-auto",
          staticStyle: { "max-height": "80vh" },
        },
        [
          _c("v-text-field", {
            staticClass: "ml-5",
            attrs: { "prepend-icon": "search" },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _c(
            "v-list",
            { attrs: { subheader: "" } },
            [
              _vm.groups.length > 0
                ? _c(
                    "v-list-item-group",
                    _vm._l(_vm.groups, function (group) {
                      return _c(
                        "v-list-item",
                        {
                          key: group._id,
                          attrs: {
                            router: "",
                            to: {
                              name: "AdminGroupEdit",
                              params: {
                                groupID: group._id,
                              },
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-avatar",
                            [_c("v-icon", [_vm._v(" group ")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: { textContent: _vm._s(group.name) },
                              }),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  _vm._s((group.users || []).length) +
                                    " Mitglied" +
                                    _vm._s(
                                      (group.users || []).length !== 1
                                        ? "er"
                                        : ""
                                    )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }