var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "users" },
    [
      _c("p", { staticClass: "text-h5" }, [_vm._v("Gruppen")]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticStyle: { "max-width": "450px" } },
            [_c("GroupList")],
            1
          ),
          _vm.$router.currentRoute.params.groupID
            ? _c(
                "v-col",
                { staticStyle: { "max-height": "80vh" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "overflow-y-auto pa-5",
                      staticStyle: { "max-height": "80vh" },
                    },
                    [
                      _c("router-view", {
                        key: _vm.$router.currentRoute.params.groupID,
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            fab: "",
            fixed: "",
            bottom: "",
            right: "",
            color: "success",
          },
          on: {
            click: function ($event) {
              return _vm.create()
            },
          },
        },
        [_c("v-icon", [_vm._v(" add ")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }