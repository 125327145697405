<template>
    <div class="">

        <div class="login-bg"></div>
        <div class="login-bg-gradient"></div>

        <p class="login-copyright font-weight-bold white--text ma-0">&copy; {{ new Date().getFullYear() }} VENUS GmbH</p>


        <v-card elevation="10" class="login-card pa-3" style="background-color: rgba(255,255,255,0.8); backdrop-filter: blur(10px)" width="380px">

            <div class="pa-5">

                <img width="100%" :src="logoURL" class="my-2">

                <!--h1>Login</h1-->
                <form class="">

                    <v-text-field id="username" type="email" label="Benutzer" placeholder=" " v-model="username"></v-text-field>
                    <v-text-field id="password" type="password" label="Passwort" placeholder=" " v-model="password"></v-text-field>

                    <v-alert
                        v-model="alert"
                        border="left"
                        icon="lock"
                        type="error"
                        dismissible
                    >Login fehlgeschlagen.</v-alert>

                    <v-btn type="submit" width="100%" color="secondary" x-large class="mt-4" @click="handleSubmit" depressed :loading="loading">
                        Login
                    </v-btn>

                    <br>

                </form>


            </div>

        </v-card>

    </div>
</template>

<script>

    import axios from 'axios';
    import Snowflakes from 'magic-snowflakes';


    export default {
        props: [
            "nextUrl",
        ],
        data(){
            return {
                snowflakes: null,
                username : "",
                password : "",
                alert: false,
                loading: false,
                nyanCounter: 1,
            }
        },
        computed: {
            logoURL: function() {
                if (this.nyanCounter > 20) {
                    return "https://raw.githubusercontent.com/gist/T-Jedsada/dbee22959762fa6c0ccad8153830b51a/raw/8957088c2e31dba6d72ce86c615cb3c7bb7f0b0c/nyan-cat.gif";
                } else {
                    return this.$store.getters['system-config/getConfig']?.appearance?.logos?.main;
                }
            },
            logoScale: function() {
                if (this.nyanCounter > 20) {
                    return 3;
                } else {
                    return 1;
                }
            }
        },
        methods : {
            async handleSubmit(e){

                e.preventDefault();

                this.alert = false;
                this.loading = true;


                // LOGIN action
                this.$store.dispatch('auth/login', {
                    username: this.username,
                    password: this.password
                }).then(() => {
                    if (this.$route.params.nextUrl != null) {
                        this.$router.push(this.$route.params.nextUrl);
                    } else {
                        this.$router.push('/projects');
                    }
                }).catch(err => {
                    /*this.$toasted.error('Login fehlgeschlagen.', {
                        position: "top-center",
                        duration: 2000,
                        keepOnHover: true,
                    });*/
                    this.alert = true;
                    console.log(err);
                }).finally(() => {
                    this.loading = false;
                })

            }
        },
        created() {

        },
        beforeDestroy() {

        }
    }
</script>

<style scoped>

    .login-card {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .login-bg {
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        background-image: url("https://webimages.we2p.de/static/cockpit_login/");
        background-position: center;
        background-size: cover;
    }
    .login-bg-gradient {
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        background: rgb(2,0,36);
        background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(0,0,0,0.3) 100%);
    }
    .login-app-title {
        position: fixed;
        bottom: 20px;
        right: 30px;
    }
    .login-copyright {
        position: fixed;
        bottom: 20px;
        right: 30px;
    }
</style>
