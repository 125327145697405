var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        staticStyle: { "max-width": "250px" },
        attrs: { label: "Anrede", items: _vm.salutations },
        model: {
          value: _vm.contact.salutation,
          callback: function ($$v) {
            _vm.$set(_vm.contact, "salutation", $$v)
          },
          expression: "contact.salutation",
        },
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { label: "Nachname" },
                model: {
                  value: _vm.contact.lastName,
                  callback: function ($$v) {
                    _vm.$set(_vm.contact, "lastName", $$v)
                  },
                  expression: "contact.lastName",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { label: "Vorname" },
                model: {
                  value: _vm.contact.firstName,
                  callback: function ($$v) {
                    _vm.$set(_vm.contact, "firstName", $$v)
                  },
                  expression: "contact.firstName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-text-field", {
        attrs: {
          label: "Firma/Organisation",
          "prepend-inner-icon": "business",
        },
        model: {
          value: _vm.contact.organization,
          callback: function ($$v) {
            _vm.$set(_vm.contact, "organization", $$v)
          },
          expression: "contact.organization",
        },
      }),
      _c("v-text-field", {
        attrs: { label: "E-Mail", "prepend-inner-icon": "mail" },
        model: {
          value: _vm.contact.email,
          callback: function ($$v) {
            _vm.$set(_vm.contact, "email", $$v)
          },
          expression: "contact.email",
        },
      }),
      _c("v-text-field", {
        attrs: { label: "Telefon", "prepend-inner-icon": "phone" },
        model: {
          value: _vm.contact.phone,
          callback: function ($$v) {
            _vm.$set(_vm.contact, "phone", $$v)
          },
          expression: "contact.phone",
        },
      }),
      _c("p", { staticClass: "caption mt-8 font-weight-bold" }, [
        _vm._v("Adresse:"),
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                attrs: { label: "Straße und Hausnummer" },
                model: {
                  value: _vm.contact.address.street,
                  callback: function ($$v) {
                    _vm.$set(_vm.contact.address, "street", $$v)
                  },
                  expression: "contact.address.street",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", md: "3", lg: "2" } },
            [
              _c("v-text-field", {
                attrs: { label: "PLZ" },
                model: {
                  value: _vm.contact.address.zip,
                  callback: function ($$v) {
                    _vm.$set(_vm.contact.address, "zip", $$v)
                  },
                  expression: "contact.address.zip",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", md: "9", lg: "5" } },
            [
              _c("v-text-field", {
                attrs: { label: "Ort" },
                model: {
                  value: _vm.contact.address.city,
                  callback: function ($$v) {
                    _vm.$set(_vm.contact.address, "city", $$v)
                  },
                  expression: "contact.address.city",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", md: "6", lg: "5" } },
            [
              _c("v-text-field", {
                attrs: { label: "Land" },
                model: {
                  value: _vm.contact.address.country,
                  callback: function ($$v) {
                    _vm.$set(_vm.contact.address, "country", $$v)
                  },
                  expression: "contact.address.country",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("p", { staticClass: "caption mt-8 font-weight-bold" }, [
        _vm._v("Rechnungsinfos:"),
      ]),
      _c("v-text-field", {
        attrs: { label: "Bank" },
        model: {
          value: _vm.contact.bank,
          callback: function ($$v) {
            _vm.$set(_vm.contact, "bank", $$v)
          },
          expression: "contact.bank",
        },
      }),
      _c("v-text-field", {
        attrs: { label: "IBAN", rules: _vm.isIBAN },
        model: {
          value: _vm.contact.iban,
          callback: function ($$v) {
            _vm.$set(_vm.contact, "iban", $$v)
          },
          expression: "contact.iban",
        },
      }),
      _c("v-text-field", {
        attrs: { label: "BIC" },
        model: {
          value: _vm.contact.bic,
          callback: function ($$v) {
            _vm.$set(_vm.contact, "bic", $$v)
          },
          expression: "contact.bic",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }