var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-sheet", [
        _c("p", { staticClass: "ma-0 pt-4 pl-5" }, [
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm.total) + " Treffer"),
          ]),
          _vm.value.length > 0
            ? _c("span", { staticClass: "ml-3" }, [
                _vm._v("(" + _vm._s(_vm.value.length) + " ausgewählt)"),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("v-data-table", {
        staticClass: "pt-5",
        attrs: {
          loading: _vm.loading || false,
          "items-per-page": _vm.itemsPerPage,
          items: _vm.items || [],
          headers: _vm.headers || [],
          "sort-by": "name",
          options: _vm.options,
          "server-items-length": _vm.total,
          "item-key": "_id",
          "show-select": "",
          "footer-props": {
            "items-per-page-options": [15, 25, 50, 100],
          },
        },
        on: {
          "update:items-per-page": _vm.saveItemsPerPage,
          "update:options": function ($event) {
            _vm.options = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item.name",
            fn: function (props) {
              return [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(props.item.name)),
                ]),
              ]
            },
          },
          {
            key: "item.types",
            fn: function (props) {
              return [
                _c("span", { staticClass: "font-weight-bold blue--text" }, [
                  _vm._v(_vm._s(props.item.types.join(", "))),
                ]),
              ]
            },
          },
          {
            key: "item.controls",
            fn: function (props) {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3 my-2",
                    attrs: {
                      to: "entries/" + props.item._id,
                      fab: "",
                      depressed: "",
                      "x-small": "",
                      router: "",
                      append: "",
                    },
                  },
                  [_c("v-icon", [_vm._v("edit")])],
                  1
                ),
                _c("DeleteConfirmation", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "mr-3 my-2",
                                    attrs: {
                                      fab: "",
                                      color: "error",
                                      depressed: "",
                                      "x-small": "",
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_c("v-icon", [_vm._v("delete")])],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }