var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          staticClass: "pa-0",
          attrs: {
            persistent: "",
            fullscreen: "",
            "close-on-content-click": false,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mr-5 black--text",
                          attrs: {
                            color: "white",
                            rounded: "",
                            depressed: "",
                            "aria-label": "Support-Ticket Dialog öffnen",
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" Hilfe ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-3",
              staticStyle: { "z-index": "1000" },
              attrs: {
                depressed: "",
                fixed: "",
                top: "",
                right: "",
                fab: "",
                small: "",
                color: "error",
              },
              on: {
                click: function ($event) {
                  _vm.dialog = false
                },
              },
            },
            [_c("v-icon", { attrs: { color: "white" } }, [_vm._v(" close ")])],
            1
          ),
          _c("div", {
            staticStyle: {
              position: "fixed",
              width: "100vw",
              height: "100vh",
              "backdrop-filter": "blur(20px)",
            },
          }),
          _c("iframe", {
            staticStyle: {
              position: "fixed",
              "z-index": "999",
              width: "100vw",
              height: "100vh",
              border: "none",
            },
            attrs: { src: _vm.iframeURL },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }