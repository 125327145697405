var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "max-width": "900",
            "close-on-content-click": false,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mr-5",
                          attrs: { color: "white", fab: "", "x-small": "" },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-badge",
                        { attrs: { color: "info", value: false, dot: "" } },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "grey darken-4", size: "24" } },
                            [_vm._v(" newspaper ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 font-weight-bold pb-10 pt-5" },
                [
                  _c("span", [_vm._v(" Update-Neuigkeiten ")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: { text: "", depressed: "", fab: "", small: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { color: "error" } }, [
                        _vm._v(" close "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _vm.$store.getters["changelog/getStatus"] === "loading"
                    ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
                    : _vm._e(),
                  _vm.$store.getters["changelog/getRelevant"] !== "loading"
                    ? [
                        _vm._l(_vm.changelogs, function (item, index) {
                          return [
                            _c("div", { key: index }, [
                              _c(
                                "p",
                                { staticClass: "text-h6 mb-0" },
                                [
                                  _c("span", [_vm._v(_vm._s(item.title))]),
                                  item.new
                                    ? _c(
                                        "v-chip",
                                        {
                                          staticClass: "ml-2",
                                          attrs: {
                                            small: "",
                                            color: "success",
                                          },
                                        },
                                        [_vm._v("neu")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("p", { staticClass: "grey--text" }, [
                                _vm._v(_vm._s(_vm.getDate(item.date))),
                              ]),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    item.body.replaceAll(
                                      "<a ",
                                      "<a target='_blank' "
                                    )
                                  ),
                                },
                              }),
                            ]),
                            index < _vm.changelogs.length - 1
                              ? _c("v-divider", {
                                  key: index,
                                  staticClass: "my-5",
                                  attrs: { insets: "" },
                                })
                              : _vm._e(),
                          ]
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }