var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "white--text ml-3",
                      attrs: {
                        small: "",
                        depressed: "",
                        fab: "",
                        dark: "",
                        color: "blue",
                      },
                      on: { click: _vm.sendToEditor },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_c("v-icon", [_vm._v("low_priority")])],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-0",
              attrs: {
                icon: "",
                text: "",
                fab: "",
                fixed: "",
                top: "",
                right: "",
                color: "error",
              },
              on: {
                click: function ($event) {
                  _vm.dialog = false
                },
              },
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          ),
          _c("CatalogPlannerEditor", {
            key: _vm.componentKey,
            attrs: { "catalog-id": _vm.catalogId },
            on: { close: _vm.close },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }