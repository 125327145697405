var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.project
    ? _c(
        "div",
        { staticClass: "my-5" },
        [
          _c(
            "v-row",
            { staticClass: "mx-2 mb-5" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: {
                    color: "success",
                    depressed: "",
                    fab: "",
                    small: "",
                    loading:
                      _vm.$store.getters["projects/getStatus"] === "loading",
                  },
                  on: { click: _vm.save },
                },
                [_c("v-icon", [_vm._v(" save ")])],
                1
              ),
              _c("DeleteConfirmation", { on: { yes: _vm.remove } }),
            ],
            1
          ),
          _c("v-text-field", {
            staticClass: "ml-5",
            staticStyle: { "max-width": "800px" },
            attrs: { label: "Name", filled: "" },
            model: {
              value: _vm.project.name,
              callback: function ($$v) {
                _vm.$set(_vm.project, "name", $$v)
              },
              expression: "project.name",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }