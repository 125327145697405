import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import vuetify from './plugins/vuetify';
import store from './store';
import chat from 'vue-beautiful-chat';
import VueNotification from 'vue-notification';
import PermissionGuard from './services/permission-guard';
import PPSettings from './services/pp-settings';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import SocketIO from 'socket.io-client';
import SocketAdapter from './services/socket-adapter';
import { LMap, LTileLayer, LMarker, LControl } from 'vue2-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import AsyncComputed from 'vue-async-computed';
import CountryFlag from 'vue-country-flag';

import VAnimateCss from 'v-animate-css';

Vue.use(VAnimateCss);
Vue.use(AsyncComputed);

// country flag
Vue.component('country-flag', CountryFlag);

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// SENTRY
/* Sentry.init({
  Vue: Vue,
  logErrors: true,
  environment: process.env.NODE_ENV || "unknown_env",
  dsn: "https://abbbe9ac027a4a0bbfc2e2a42d951844@sentry.we2p.com/6",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});*/

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-control', LControl);

Vue.prototype.createObjectURL = window.URL.createObjectURL;
// COOKIES
Vue.use(VueCookies);
// set default config
Vue.$cookies.config('30d');
// set global cookie
Vue.$cookies.set('dark', 'false');

// AXIOS
Vue.prototype.$http = axios;
const token = localStorage.getItem('token');
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] =
        'Bearer ' + token;
}
// logout if token is invalid
Vue.prototype.$http.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            store.dispatch('auth/logout', { displaySessionExpiredMsg: true });
        }
        return Promise.reject(error);
    }
);
//Getting user settings, to ensure oAuth token is active
axios
    .get(
        process.env.VUE_APP_MEDIA_COLLECTION_API_URL + 'user/settings/config',
        {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth'),
            },
        }
    )
    .then((response) => {
        //Do nothing
    })
    .catch((error) => {
        if (error.response.status === 401)
            store.dispatch('auth/logout', { displaySessionExpiredMsg: false });
    });

// CKEDITOR
Vue.use(CKEditor);

// TOASTS
Vue.use(VueNotification);

// ROUTER
Vue.use(VueRouter);

// PERMISSIONS
Vue.prototype.$permGuard = PermissionGuard;

// USER SETTINGS
Vue.prototype.$settings = PPSettings;

// SOCKET IO
SocketAdapter.init();

// CHAT
Vue.use(chat);

// service-worker
const prod = process.env.NODE_ENV !== 'production';
const shouldSW = 'serviceWorker' in navigator && prod;
if (shouldSW) {
    navigator.serviceWorker.register('/service-worker.js').then(() => {
        console.log('Service Worker Registered!');
    });
}

console.log('APP is running in ' + process.env.NODE_ENV + '-mode');

Vue.config.productionTip = false;

export default new Vue({
    vuetify,
    router,
    store,
    chat,
    render: (h) => h(App),
}).$mount('#app');
