<template>

    <div>
        <v-dialog
            persistent
            v-model="dialog"
            fullscreen
            class="pa-0"
            :close-on-content-click="false"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="white"
                       class="mr-5 black--text"
                       rounded
                       depressed
                       v-bind="attrs"
                       v-on="on"
                       aria-label="Support-Ticket Dialog öffnen"
                >
                        Hilfe
                        <!--v-icon color="grey darken-4" size="24">
                            question_mark
                        </v-icon-->
                </v-btn>
            </template>

            <v-btn depressed fixed top right fab small @click="dialog = false" color="error" class="mr-3" style="z-index: 1000">
                <v-icon color="white">
                    close
                </v-icon>
            </v-btn>

            <div  style="position: fixed; width: 100vw; height: 100vh; backdrop-filter: blur(20px);"></div>
            <iframe style="position: fixed; z-index: 999; width: 100vw; height: 100vh; border: none;" :src="iframeURL"></iframe>

        </v-dialog>
    </div>

</template>

<script>

import jwt from 'jsonwebtoken';
import platform from 'platform';

export default {
    name: "TicketSystem",
    data() {
        return {
            dialog: false,
            showBadge: true,
            iframeURL: "",
        }
    },
    computed: {
        user: function () {
            return this.$store.getters['auth/getUser'];
        },
    },
    methods: {

    },
    watch: {
        dialog: function (val) {
            console.log(open);
            if (val) {

                let token = jwt.sign({
                    userName: this.user.name,
                    email: this.user.mail,
                    os: platform.os.toString() + " (erkannt)",
                    browser: platform.name.toString() + " " + platform.version.toString()  + " (erkannt)",
                    datasetId: this.$router.currentRoute.params.entryId || "",
                    listId: "62f123903f05792aef2549e6",
                    pos: "top",
                    idMembers: "",
                    appShortcut: "PP-",
                }, "RCu*LJFSkR06s61YT^X82q#Fhd7TR!4VDJcQ@hw760oeh", {
                    algorithm: "HS512"
                }, null);

                this.iframeURL = "https://ticket.venus.bayern/index.php?jwt=" + token + "&system=" + process.env.NODE_ENV;
            }
        }
    },
    created() {

    }
}
</script>

<style scoped>

</style>
