var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800px" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [
                _vm._t(
                  "default",
                  function () {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticStyle: { top: "80px" },
                              attrs: {
                                fab: "",
                                fixed: "",
                                right: "",
                                top: "",
                                "x-large": "",
                                color: "green",
                                dark: "",
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("add")])],
                        1
                      ),
                    ]
                  },
                  { on: on, attrs: attrs }
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                { staticClass: "py-2" },
                [
                  _c("span", { staticClass: "ml-2 mt-3" }, [
                    _vm._v(" Neuen Eintrag anlegen "),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        color: "error",
                        depressed: "",
                        icon: "",
                        small: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.entryTypes,
                                  label: "Eintrags-Typ",
                                  loading: _vm.entryTypesLoading,
                                  required: "",
                                  "item-value": "_id",
                                  placeholder: "Eintrags-Typ wählen...",
                                  "item-text": "name",
                                },
                                model: {
                                  value: _vm.entryType,
                                  callback: function ($$v) {
                                    _vm.entryType = $$v
                                  },
                                  expression: "entryType",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: { label: "Name", required: "" },
                                model: {
                                  value: _vm.name,
                                  callback: function ($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name",
                                },
                              }),
                              _vm.createInCockpit &&
                              _vm.$settings.getValue("general.create.mode") > 1
                                ? _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.entityTypes,
                                      label: "Typ im Knowledge Graph",
                                      loading: _vm.entityTypesLoading,
                                      required: "",
                                      "item-value": "value",
                                      "item-text": "label",
                                      placeholder: "Typ wählen...",
                                    },
                                    model: {
                                      value: _vm.entityType,
                                      callback: function ($$v) {
                                        _vm.entityType = $$v
                                      },
                                      expression: "entityType",
                                    },
                                  })
                                : _vm._e(),
                              _vm.$settings.getValue("general.create.mode") ===
                              2
                                ? _c("v-checkbox", {
                                    attrs: {
                                      label: "Im Knowledge Graph anlegen",
                                      hint: "Einen Datensatz im Knowledge Graph anlegen und als Original-Datensatz des Eintrags verlinken",
                                      "persistent-hint": "",
                                    },
                                    model: {
                                      value: _vm.createInCockpit,
                                      callback: function ($$v) {
                                        _vm.createInCockpit = $$v
                                      },
                                      expression: "createInCockpit",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", depressed: "" },
                      on: {
                        click: function ($event) {
                          return _vm.create(_vm.entry)
                        },
                      },
                    },
                    [_vm._v("Erstellen")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "DeleteConfirmation",
            {
              ref: "redirectDialog",
              attrs: {
                hidden: true,
                msg: "Wollen Sie zur Bearbeitungsmaske des eben angelegten Datensatzes wechseln?",
                title: "Jetzt bearbeiten?",
              },
              on: { yes: _vm.redirectEdit },
            },
            [void 0],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }