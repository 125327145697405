var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("LabelComponent", {
        attrs: { definition: _vm.definition, "is-locked": _vm.isLocked },
      }),
      _c("v-checkbox", {
        attrs: {
          dense: "",
          filled: "",
          readonly: _vm.isLocked,
          "input-value": _vm.data,
          "hide-details": "auto",
        },
        on: {
          change: function ($event) {
            _vm.$emit("input", Boolean($event))
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }