var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("p", [_vm._v("Version: " + _vm._s(_vm.sysInfo.self.node.appVersion))]),
      _c("p", { staticClass: "text-h6 mb-0" }, [_vm._v("System")]),
      _c("p", { staticClass: "text-caption mb-5" }, [
        _c("span", [_vm._v(_vm._s(_vm.sysInfo.self.os.platform))]),
        _c("span", { staticClass: "mx-2" }, [_vm._v("|")]),
        _c("span", [_vm._v(_vm._s(_vm.sysInfo.self.os.distro))]),
      ]),
      _c("p", { staticClass: "text-h6 mb-0" }, [_vm._v("Node.js")]),
      _c("p", { staticClass: "text-caption mb-5" }, [
        _c("span", [
          _vm._v("Version: " + _vm._s(_vm.sysInfo.self.node.version)),
        ]),
        _c("span", { staticClass: "mx-2" }, [_vm._v("|")]),
        _c("span", [_vm._v("NODE_ENV: " + _vm._s(_vm.sysInfo.self.node.env))]),
      ]),
      _c("p", { staticClass: "text-h6 mb-1" }, [_vm._v("Memory")]),
      _c("v-progress-linear", {
        staticStyle: { "max-width": "1000px", "background-color": "darkgrey" },
        attrs: {
          rounded: "",
          rotate: 90,
          size: 100,
          "background-color": "red",
          "background-opacity": "1",
          value:
            (_vm.sysInfo.self.ram.used / _vm.sysInfo.self.ram.total) * 100 -
            (_vm.sysInfo.self.ram.usedProcess.heapTotal /
              _vm.sysInfo.self.ram.total) *
              100,
          width: 15,
          "buffer-value":
            (_vm.sysInfo.self.ram.used / _vm.sysInfo.self.ram.total) * 100,
          color: "success",
        },
      }),
      _c("p", { staticClass: "text-caption mt-2" }, [
        _c("span", [
          _vm._v(
            "Used (Process): " +
              _vm._s(
                _vm.toGB(_vm.sysInfo.self.ram.usedProcess.heapTotal) * 1000
              ) +
              " MB"
          ),
        ]),
        _c("span", { staticClass: "mx-2" }, [_vm._v("|")]),
        _c("span", [
          _vm._v(
            "Used (total): " +
              _vm._s(_vm.toGB(_vm.sysInfo.self.ram.used)) +
              " GB"
          ),
        ]),
        _c("span", { staticClass: "mx-2" }, [_vm._v("|")]),
        _c("span", [
          _vm._v(
            "Free: " + _vm._s(_vm.toGB(_vm.sysInfo.self.ram.free)) + " GB"
          ),
        ]),
        _c("span", { staticClass: "mx-2" }, [_vm._v("|")]),
        _c("span", [
          _vm._v(
            "Total: " + _vm._s(_vm.toGB(_vm.sysInfo.self.ram.total)) + " GB"
          ),
        ]),
      ]),
      _c("p", { staticClass: "text-h6 mb-1" }, [
        _c("span", [_vm._v("Cluster")]),
      ]),
      _c(
        "v-row",
        { staticClass: "my-5 ml-3" },
        [
          _c(
            "div",
            { staticClass: "mr-10" },
            [
              _c("v-btn", {
                attrs: { fab: "", large: "", color: "info", depressed: "" },
              }),
              _c("p", { staticClass: "caption text-center mt-2" }, [
                _vm._v("Master"),
              ]),
            ],
            1
          ),
          _vm._l(_vm.workerNodes, function (node, index) {
            return [
              _c(
                "div",
                { key: index, staticClass: "mr-10" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        large: "",
                        color: node.active ? "success" : "grey",
                        depressed: "",
                      },
                    },
                    [
                      node.active
                        ? _c("v-icon", { attrs: { color: "white" } }, [
                            _vm._v("check"),
                          ])
                        : _c("v-icon", { attrs: { color: "white" } }, [
                            _vm._v("bedtime"),
                          ]),
                    ],
                    1
                  ),
                  node.pid
                    ? _c("p", { staticClass: "caption text-center mt-2" }, [
                        _vm._v("PID: " + _vm._s(node.pid)),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
      _c("p", { staticClass: "text-h6 mb-1" }, [
        _c("span", [_vm._v("Datamodel")]),
      ]),
      _c("v-progress-linear", {
        staticStyle: { "max-width": "1000px" },
        attrs: {
          rounded: "",
          rotate: 90,
          size: 100,
          value:
            (_vm.sysInfo.remote.datamodel.cache.types.cacheCurrent /
              _vm.sysInfo.remote.datamodel.cache.types.cacheTarget) *
            100,
          width: 15,
          color:
            _vm.sysInfo.remote.datamodel.cache.types.cacheCurrent ===
            _vm.sysInfo.remote.datamodel.cache.types.cacheTarget
              ? "success"
              : "info",
        },
      }),
      _vm.sysInfo.remote.datamodel.cache.types.cacheTarget === 0
        ? _c("p", { staticClass: "text-caption mt-2" }, [
            _c("span", [_vm._v("Cache disabled.")]),
          ])
        : _vm.sysInfo.remote.datamodel.cache.types.cacheCurrent ===
          _vm.sysInfo.remote.datamodel.cache.types.cacheTarget
        ? _c("p", { staticClass: "text-caption mt-2" }, [
            _c("span", { staticClass: "success--text" }, [
              _vm._v("Cache stored."),
            ]),
          ])
        : _c("p", { staticClass: "text-caption mt-2" }, [
            _c("span", [
              _vm._v(
                "Local: " +
                  _vm._s(_vm.sysInfo.remote.datamodel.cache.types.cacheCurrent)
              ),
            ]),
            _c("span", { staticClass: "mx-2" }, [_vm._v("|")]),
            _c("span", [
              _vm._v(
                "Remote: " +
                  _vm._s(_vm.sysInfo.remote.datamodel.cache.types.cacheTarget)
              ),
            ]),
          ]),
      _vm.sysInfo.remote.datamodel.cache.types.cacheCurrent ===
      _vm.sysInfo.remote.datamodel.cache.types.cacheTarget
        ? _c("DeleteConfirmation", {
            attrs: {
              title: "Cache neu aufbauen?",
              msg: "Während der Cache neu aufgebaut wird, kann es zu kleineren Performance-Problemen kommen.",
            },
            on: { yes: _vm.resetCache },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                small: "",
                                color: "info",
                                depressed: "",
                                rounded: "",
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { size: "18" } }, [
                            _vm._v(" cached "),
                          ]),
                          _c("span", [_vm._v("purge cache")]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1634667449
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }