var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.user
        ? _c(
            "v-menu",
            {
              attrs: {
                bottom: "",
                right: "",
                transition: "scale-transition",
                origin: "top left",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-chip",
                          _vm._g(
                            { staticClass: "mb-2", attrs: { pill: "" } },
                            on
                          ),
                          [
                            _c(
                              "v-avatar",
                              { attrs: { left: "", color: "error" } },
                              [
                                _c("span", { staticClass: "white--text" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.user.name
                                        .split(" ")
                                        .map(function (x) {
                                          return x.charAt(0).toUpperCase()
                                        })
                                        .slice(0, 2)
                                        .join("")
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" " + _vm._s(_vm.user.name) + " "),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                2854516734
              ),
              model: {
                value: _vm.menu,
                callback: function ($$v) {
                  _vm.menu = $$v
                },
                expression: "menu",
              },
            },
            [
              _c(
                "v-card",
                { attrs: { width: "300" } },
                [
                  _c(
                    "v-list",
                    { attrs: { dark: "" } },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-avatar",
                            { attrs: { color: "white" } },
                            [
                              _c("v-avatar", { attrs: { size: "40" } }, [
                                _c("span", { staticClass: "black--text" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.user.name
                                        .split(" ")
                                        .map(function (x) {
                                          return x.charAt(0).toUpperCase()
                                        })
                                        .slice(0, 2)
                                        .join("")
                                    )
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.user.name)),
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(_vm._s(_vm.user.firmation)),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.menu = false
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-close-circle")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list",
                    [
                      _vm.user.telephone
                        ? _c(
                            "v-list-item",
                            {
                              attrs: {
                                append: false,
                                href: "tel:" + _vm.user.telephone,
                              },
                            },
                            [
                              _c(
                                "v-list-item-action",
                                [_c("v-icon", [_vm._v("phone")])],
                                1
                              ),
                              _c("v-list-item-subtitle", [
                                _vm._v(_vm._s(_vm.user.telephone)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.user.mail
                        ? _c(
                            "v-list-item",
                            { attrs: { href: "mailto:" + _vm.user.mail } },
                            [
                              _c(
                                "v-list-item-action",
                                [_c("v-icon", [_vm._v("mail")])],
                                1
                              ),
                              _c("v-list-item-subtitle", [
                                _vm._v(_vm._s(_vm.user.mail)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.user ? _c("div", [_c("p", [_vm._v("-")])]) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }