var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalogs", staticStyle: { "max-height": "100vh" } },
    [
      _c(
        "v-row",
        { staticClass: "overflow-hidden" },
        [
          _c(
            "v-navigation-drawer",
            {
              staticClass: "ma-3 pa-4 absolute",
              staticStyle: { "z-index": "1" },
              attrs: {
                absolute: "",
                height: "calc(100vh - 90px)",
                width: "400",
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mb-5 mt-2",
                  attrs: {
                    text: "",
                    color: "transparent",
                    depressed: "",
                    router: "",
                    to:
                      "/projects/" +
                      _vm.$router.currentRoute.params.projectID +
                      "/channels/" +
                      _vm.$router.currentRoute.params.channelID +
                      "/print/catalogs",
                  },
                },
                [
                  _c("v-icon", { staticClass: "mr-3 black--text" }, [
                    _vm._v("arrow_back"),
                  ]),
                  _c("span", { staticClass: "black--text" }, [
                    _vm._v("alle Versionen"),
                  ]),
                ],
                1
              ),
              _c("p", { staticClass: "text-body-1 grey--text mt-4 mb-0" }, [
                _vm._v("Katalog-Planung"),
              ]),
              _c("p", { staticClass: "text-h3" }, [_vm._v("Demo Katalog")]),
              _vm._v(" tools "),
            ],
            1
          ),
          _c(
            "v-col",
            { staticStyle: { height: "calc(100vh - 90px)" } },
            [
              _c(
                "v-row",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { fab: "", color: "info" } },
                    [_c("v-icon", [_vm._v(" east ")])],
                    1
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }