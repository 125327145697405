var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.changelog
    ? _c(
        "div",
        { staticClass: "my-5" },
        [
          _c(
            "v-row",
            { staticClass: "mx-2 mb-5" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { color: "info", depressed: "", fab: "", small: "" },
                  on: { click: _vm.clone },
                },
                [_c("v-icon", [_vm._v(" content_copy ")])],
                1
              ),
              _c("DeleteConfirmation", { on: { yes: _vm.remove } }),
            ],
            1
          ),
          _c("v-text-field", {
            staticStyle: { "max-width": "800px" },
            attrs: { label: "Titel", filled: "" },
            model: {
              value: _vm.changelog.title,
              callback: function ($$v) {
                _vm.$set(_vm.changelog, "title", $$v)
              },
              expression: "changelog.title",
            },
          }),
          _c(
            "v-row",
            { staticClass: "mx-0" },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": false,
                    "nudge-right": 40,
                    transition: "scale-transition",
                    "offset-y": "",
                    "min-width": "auto",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-text-field",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "ml-0",
                                    staticStyle: { "max-width": "180px" },
                                    attrs: {
                                      filled: "",
                                      value: _vm.formatDate,
                                      label: "Datum",
                                      "append-icon": "event",
                                      readonly: "",
                                    },
                                  },
                                  "v-text-field",
                                  attrs,
                                  false
                                ),
                                on
                              )
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    910357299
                  ),
                  model: {
                    value: _vm.dateDialog,
                    callback: function ($$v) {
                      _vm.dateDialog = $$v
                    },
                    expression: "dateDialog",
                  },
                },
                [
                  _c("v-date-picker", {
                    on: {
                      input: function ($event) {
                        _vm.dateDialog = false
                      },
                    },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                ],
                1
              ),
              _c("v-select", {
                staticClass: "ml-5",
                staticStyle: { "max-width": "400px" },
                attrs: { label: "System", filled: "", items: _vm.systems },
                model: {
                  value: _vm.changelog.system,
                  callback: function ($$v) {
                    _vm.$set(_vm.changelog, "system", $$v)
                  },
                  expression: "changelog.system",
                },
              }),
            ],
            1
          ),
          _c("ckeditor", {
            attrs: { editor: _vm.editor, config: _vm.editorConfig },
            model: {
              value: _vm.changelog.body,
              callback: function ($$v) {
                _vm.$set(_vm.changelog, "body", $$v)
              },
              expression: "changelog.body",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }