var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "text-h6 mb-4 grey--text" }, [
        _vm._v("Eintragstypen"),
      ]),
      _c(
        "v-container",
        { staticClass: "my-5" },
        [
          _vm.getAll.length === 0
            ? _c(
                "div",
                { staticClass: "my-5 text-center" },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mb-4 grey--text", attrs: { size: "128" } },
                    [_vm._v("category")]
                  ),
                  _c("p", { staticClass: "text-h4 my-5" }, [
                    _vm._v("Noch keine Eintragstypen."),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.getAll.length > 0
            ? _c(
                "v-card",
                { staticClass: "mb-4", attrs: { flat: "", tile: "" } },
                [
                  _vm._l(_vm.getAll, function (item, index) {
                    return [
                      _c(
                        "v-list-item",
                        { key: item._id, attrs: { index: item._id } },
                        [
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(" " + _vm._s(item.name) + " "),
                                  item.disableIXEdit
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-caption warning--text",
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-4",
                                              attrs: { size: "18" },
                                            },
                                            [_vm._v("info")]
                                          ),
                                          _vm._v(" IXEdit deaktiviert "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ],
                              1
                            ),
                            _c("v-list-item-action", [
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-3",
                                      attrs: {
                                        fab: "",
                                        depressed: "",
                                        light: "",
                                        color: "lightgray",
                                        small: "",
                                        router: "",
                                        to: item._id,
                                        append: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "black" } },
                                        [_vm._v(" edit ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-3",
                                      attrs: {
                                        fab: "",
                                        depressed: "",
                                        light: "",
                                        color: "info",
                                        small: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.duplicateItem(item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [_vm._v(" content_copy ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("DeleteConfirmation", {
                                    on: {
                                      yes: function ($event) {
                                        return _vm.removeItem(item._id)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                        ],
                        2
                      ),
                      index < _vm.getAll.length - 1
                        ? _c("v-divider", { key: index })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }