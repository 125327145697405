var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: "12", sm: "6" } },
            [
              _c("v-select", {
                staticClass: "pt-0",
                attrs: {
                  items: _vm.workflow.steps,
                  "menu-props": "auto",
                  "item-text": "label",
                  "item-value": "keyword",
                  label: "bitte wählen...",
                  "hide-details": "",
                  "single-line": "",
                  "prepend-icon": "donut_large",
                },
                on: {
                  change: function ($event) {
                    return _vm.updateWorkflow(_vm.catalog)
                  },
                },
                model: {
                  value: _vm.catalog.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.catalog, "status", $$v)
                  },
                  expression: "catalog.status",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.timeout },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function (ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "success", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.snackbar = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" OK ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [_vm._v(" Status aktualisiert! ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }