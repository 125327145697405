var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalogs" },
    [
      _c(
        "v-btn",
        {
          staticClass: "mb-5 mt-2",
          attrs: {
            text: "",
            depressed: "",
            router: "",
            to:
              "/projects/" +
              _vm.$router.currentRoute.params.projectID +
              "/channels/" +
              _vm.$router.currentRoute.params.channelID +
              "/print/catalogs/" +
              _vm.$router.currentRoute.params.catalogID,
          },
        },
        [
          _c("v-icon", { staticClass: "mr-3" }, [_vm._v("arrow_back")]),
          _vm._v(" zurück "),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.printBlockersDialog,
            callback: function ($$v) {
              _vm.printBlockersDialog = $$v
            },
            expression: "printBlockersDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Achtung!"),
              ]),
              _c(
                "v-card-text",
                [
                  _c("p", [
                    _vm._v(
                      " Bevor der Katalog für die Produktion generiert werden kann müssen folgende Probleme behoben werden: "
                    ),
                  ]),
                  _c(
                    "v-list",
                    { attrs: { subheader: "", "two-line": "", dense: "" } },
                    _vm._l(_vm.printBlockers, function (blocker, index) {
                      return _c(
                        "v-list-item",
                        { key: index },
                        [
                          _c(
                            "v-list-item-avatar",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "warning",
                                  attrs: { color: "white" },
                                },
                                [_vm._v("warning")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: { textContent: _vm._s(blocker.name) },
                              }),
                              _c("v-list-item-subtitle", {
                                domProps: {
                                  textContent: _vm._s(blocker.reason),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.printBlockersDialog = false
                        },
                      },
                    },
                    [_vm._v("OK")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("p", { staticClass: "text-h3" }, [
        _vm._v(_vm._s(_vm.currentCatalog.name)),
      ]),
      _vm.$permGuard.chP("actions.get_xml")
        ? _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  loading: _vm.downloadingXML,
                                  color: "primary",
                                  depressed: "",
                                  dark: "",
                                  rounded: "",
                                },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _vm._v("XML"),
                            _c("v-icon", [_vm._v("expand_more")]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                620521808
              ),
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    { on: { click: _vm.downloadExport } },
                    [_c("v-list-item-title", [_vm._v("Download (Alle)")])],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.downloadExport({ onlyConfirmed: true })
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v("Download (Freigegeben)"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        href:
                          "/api/export/catalog/" +
                          _vm.currentCatalog._id +
                          "?format=xml&sortBy=" +
                          this.$router.currentRoute.params.sortID,
                        target: "_blank",
                      },
                    },
                    [_c("v-list-item-title", [_vm._v("Ansehen (Alle)")])],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        href:
                          "/api/export/catalog/" +
                          _vm.currentCatalog._id +
                          "?format=xml&statusPolicy=confirmed&sortBy=" +
                          this.$router.currentRoute.params.sortID,
                        target: "_blank",
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v("Ansehen (Freigegeben)"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.$permGuard.chP("actions.generate_catalog")
        ? _c(
            "v-btn",
            {
              staticClass: "ml-3",
              attrs: {
                depressed: "",
                rounded: "",
                color: "secondary",
                loading: _vm.loadingCometCatalog,
              },
              on: { click: _vm.generateCometCatalog },
            },
            [
              _vm._v(" Gesamtdokument "),
              _c("v-icon", { staticClass: "ml-2" }, [
                _vm._v("send_and_archive"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("CometPreview", {
        attrs: { urls: _vm.allPreviews },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [
                _c(
                  "v-btn",
                  _vm._g(
                    {
                      staticClass: "ml-3",
                      attrs: {
                        bind: attrs,
                        depressed: "",
                        rounded: "",
                        color: "info",
                        disabled: _vm.allPreviews.length < 1,
                      },
                    },
                    on
                  ),
                  [
                    _vm._v(" Gesamtvorschau "),
                    _c("v-icon", { staticClass: "ml-2" }, [
                      _vm._v("remove_red_eye"),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("DeleteConfirmation", {
        on: { yes: _vm.deleteSort },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [
                _c(
                  "v-btn",
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "ml-3",
                        attrs: { depressed: "", rounded: "", color: "error" },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    on
                  ),
                  [_vm._v(" Löschen ")]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-row",
        { class: "my-5 sort-ui " + (_vm.fullscreen ? "fullscreen" : "") },
        [
          _c(
            "v-col",
            { staticStyle: { position: "relative" }, attrs: { cols: "5" } },
            [
              _c(
                "v-sheet",
                { staticClass: "pa-5 sort-sheet" },
                [
                  _c("p", { staticClass: "my-4 text-h6" }, [
                    _vm._v("Sortierung"),
                  ]),
                  _c("v-checkbox", {
                    attrs: { label: "Templates ausblenden" },
                    model: {
                      value: _vm.hideTemplates,
                      callback: function ($$v) {
                        _vm.hideTemplates = $$v
                      },
                      expression: "hideTemplates",
                    },
                  }),
                  _vm.$store.getters["entries/getStatus"] !== "error"
                    ? [
                        _c("SortDefinition", {
                          attrs: {
                            "sort-definition": _vm.sortDefinition,
                            "hide-templates": _vm.hideTemplates,
                          },
                          on: {
                            move: _vm.checkSortDefinition,
                            entrySave: _vm.handleEntrySave,
                            save: _vm.save,
                          },
                        }),
                      ]
                    : [
                        _c("v-progress-linear", {
                          attrs: { indeterminate: "" },
                        }),
                      ],
                ],
                2
              ),
              _c(
                "v-btn",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "20px",
                    right: "0",
                  },
                  attrs: { fab: "", small: "", color: "grey", depressed: "" },
                  on: {
                    click: function ($event) {
                      _vm.fullscreen = !_vm.fullscreen
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v(
                      _vm._s(_vm.fullscreen ? "fullscreen_exit" : "fullscreen")
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticStyle: { position: "relative" }, attrs: { cols: "7" } },
            [
              _c("v-text-field", {
                staticClass: "mr-3 pl-3 mt-3",
                staticStyle: {
                  position: "absolute",
                  top: "0",
                  right: "0",
                  width: "400px",
                  "backdrop-filter": "blur(10px)",
                },
                attrs: { "prepend-icon": "search" },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _vm.$settings.getValue("general.create.mode") > 0
                ? _c("EntryCreate", {
                    ref: "entryCreate",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticStyle: {
                                        position: "absolute",
                                        bottom: "15px",
                                        right: "15px",
                                      },
                                      attrs: {
                                        fab: "",
                                        depressed: "",
                                        color: "green",
                                        dark: "",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("add")])],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3259606082
                    ),
                  })
                : _vm._e(),
              _c(
                "v-sheet",
                { staticClass: "pa-5 sort-sheet" },
                [
                  _c("p", { staticClass: "my-4 text-h6" }, [
                    _vm._v("Verfügbare Einträge"),
                  ]),
                  _c(
                    "p",
                    { staticClass: "text-caption" },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [_vm._v("info")]),
                      _c("span", [
                        _vm._v(
                          "Ziehen Sie die Flaggen in die linke Spalte, um den Eintrag einzusortieren."
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      items: _vm.tableData,
                      "items-per-page": -1,
                      search: _vm.search,
                      loading: _vm.isEntriesLoading,
                      "disable-pagination": "",
                      headers: _vm.headers,
                      "item-key": "_id",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.controls",
                        fn: function (props) {
                          return [
                            _c(
                              "v-dialog",
                              {
                                attrs: {
                                  persistent: "",
                                  "max-width": "1800",
                                  scrollable: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    depressed: "",
                                                    "x-small": "",
                                                    text: "",
                                                    fab: "",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_c("v-icon", [_vm._v(" edit ")])],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.openDialogs[props.item._id],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.openDialogs,
                                      props.item._id,
                                      $$v
                                    )
                                  },
                                  expression: "openDialogs[props.item._id]",
                                },
                              },
                              [
                                _c(
                                  "v-card",
                                  [
                                    _c(
                                      "v-toolbar",
                                      { attrs: { elevation: "1" } },
                                      [
                                        _c(
                                          "v-card-title",
                                          { staticClass: "headline" },
                                          [_vm._v(" Eintrag bearbeiten ")]
                                        ),
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mr-0",
                                            attrs: { color: "red", icon: "" },
                                            on: {
                                              click: function ($event) {
                                                _vm.openDialogs[
                                                  props.item._id
                                                ] = false
                                              },
                                            },
                                          },
                                          [_c("v-icon", [_vm._v("close")])],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c("EntryEdit", {
                                          attrs: {
                                            minimal: true,
                                            "entry-id": props.item._id,
                                          },
                                          on: {
                                            save: function ($event) {
                                              return _vm.$emit(
                                                "handleEntrySave"
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.languages",
                        fn: function (props) {
                          return [
                            (_vm.$settings.getValue("language.available") || [])
                              .length
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "draggable",
                                      {
                                        staticClass: "dragArea",
                                        attrs: {
                                          list: props.item.languages.map(
                                            function (l) {
                                              return Object.assign({}, l, {
                                                entry: props.item,
                                              })
                                            }
                                          ),
                                          group: {
                                            name: "g1",
                                            pull: "clone",
                                            put: false,
                                          },
                                          options: {
                                            swapThreshold: 0.8,
                                            ghostClass: "ghost",
                                          },
                                          clone: _vm.cloneLanguage,
                                        },
                                        on: { end: _vm.checkSortDefinition },
                                      },
                                      [
                                        _vm._l(
                                          props.item.languages,
                                          function (lang, index) {
                                            return [
                                              _c(
                                                "v-avatar",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "mx-1 my-2 v-btn--outlined",
                                                  staticStyle: {
                                                    cursor: "grab",
                                                  },
                                                  attrs: { size: "28" },
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src:
                                                        "https://webimages.we2p.de/static/flags/svg/" +
                                                        lang.code.slice(-2) +
                                                        ".svg",
                                                    },
                                                  }),
                                                  _vm.flatSort.find(function (
                                                    sort
                                                  ) {
                                                    return (
                                                      sort._id ===
                                                        props.item._id.toString() &&
                                                      sort.lang === lang.code
                                                    )
                                                  })
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          staticStyle: {
                                                            position:
                                                              "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform:
                                                              "translate(",
                                                            "background-color":
                                                              "rgba(",
                                                          },
                                                          attrs: {
                                                            color: "success",
                                                            size: "24",
                                                          },
                                                        },
                                                        [_vm._v("check")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "item.templateID",
                        fn: function (props) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (
                                    _vm.$store.getters["templates/getAll"].find(
                                      function (t) {
                                        return (
                                          t._id.toString() ===
                                          props.item.templateID
                                        )
                                      }
                                    ) || {}
                                  ).name
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "item.type",
                        fn: function (props) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (
                                    _vm.$store.getters[
                                      "entrytypes/getAll"
                                    ].find(function (t) {
                                      return (
                                        t._id.toString() === props.item.type
                                      )
                                    }) || {}
                                  ).name
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticStyle: {
            top: "80px",
            "backdrop-filter": "blur(5px)",
            "z-index": "25",
          },
          attrs: {
            fab: "",
            "x-large": "",
            fixed: "",
            color: "success",
            top: "",
            right: "",
          },
          on: { click: _vm.save },
        },
        [
          !_vm.saving
            ? _c("v-icon", { attrs: { color: "white" } }, [_vm._v(" save ")])
            : _vm._e(),
          _vm.saving
            ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }