var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-5", attrs: { elevation: "0" } },
    [
      _c(
        "v-card-title",
        [
          !_vm.loadingEntryType &&
          _vm.$store.getters["user/getSettingsStatus"] === "success"
            ? _c("ColumnConfig", {
                attrs: {
                  properties: _vm.columnProperties,
                  "column-config": _vm.columnConfig,
                  "ignore-fields": ["name"],
                },
                on: { apply: _vm.generateHeader },
              })
            : _vm._e(),
          _vm.$permGuard.chP("entries.d")
            ? _c("DeleteConfirmation", {
                attrs: {
                  msg:
                    "Wollen Sie wirklich " +
                    _vm.selectedRows.length +
                    " Datensätze löschen? <br><br>Es werden nur die Print-Datensätze aus dieser Auflage gelöscht. Die Cockpit-Datensätze werden nicht gelöscht.",
                },
                on: { yes: _vm.deleteSelected },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "div",
                            [
                              _vm.selectedRows.length > 0
                                ? _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "ml-3",
                                          attrs: {
                                            color: "error",
                                            text: "",
                                            rounded: "",
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " Auswahl Löschen (" +
                                          _vm._s(_vm.selectedRows.length) +
                                          ") "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2748242983
                ),
              })
            : _vm._e(),
          _vm.$permGuard.chP("entries.c") &&
          _vm.selectedRows.length > 0 &&
          _vm.$settings.getValue("mail.acquisition.active") &&
          _vm.$settings.getValue("status.acquisition.enabled")
            ? _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  attrs: {
                    color: "info",
                    text: "",
                    rounded: "",
                    loading: _vm.loading.acquisitions,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.downloadSelectedPDFs("acquisition")
                    },
                  },
                },
                [
                  _vm._v(
                    " Akquisen PDF (" + _vm._s(_vm.selectedRows.length) + ") "
                  ),
                ]
              )
            : _vm._e(),
          _vm.$permGuard.chP("entries.c") &&
          _vm.selectedRows.length > 0 &&
          _vm.$settings.getValue("mail.confirmation.active")
            ? _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  attrs: {
                    text: "",
                    rounded: "",
                    loading: _vm.loading.confirmations,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.downloadSelectedPDFs("confirmation")
                    },
                  },
                },
                [
                  _vm._v(
                    " Freigabe PDF (" + _vm._s(_vm.selectedRows.length) + ") "
                  ),
                ]
              )
            : _vm._e(),
          _c("v-spacer"),
          _c("v-text-field", {
            staticStyle: { "max-width": "300px" },
            attrs: {
              "append-icon": "search",
              label: "Suchen",
              "single-line": "",
              "hide-details": "",
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mx-4 mb-3 mt-1" },
        [
          _c("v-autocomplete", {
            staticClass: "mr-3",
            staticStyle: { "max-width": "450px" },
            attrs: {
              "prepend-icon": "filter_alt",
              label: "Status",
              chips: "",
              "small-chips": "",
              clearable: "",
              "deletable-chips": "",
              multiple: "",
              items: _vm.statusList,
              "item-value": "value",
              "item-text": "label",
              "itemm-color": "color",
            },
            model: {
              value: _vm.statusFilter,
              callback: function ($$v) {
                _vm.statusFilter = $$v
              },
              expression: "statusFilter",
            },
          }),
          _c("v-autocomplete", {
            staticClass: "mr-3",
            staticStyle: { "max-width": "450px" },
            attrs: {
              label: "Eintragstyp",
              chips: "",
              "small-chips": "",
              clearable: "",
              "deletable-chips": "",
              multiple: "",
              items: _vm.entryTypes,
              "item-value": "_id",
              "item-text": "name",
              "itemm-color": "color",
            },
            model: {
              value: _vm.entryTypesFilter,
              callback: function ($$v) {
                _vm.entryTypesFilter = $$v
              },
              expression: "entryTypesFilter",
            },
          }),
          _c("v-autocomplete", {
            staticClass: "mr-3",
            staticStyle: { "max-width": "450px" },
            attrs: {
              label: "Template",
              chips: "",
              "small-chips": "",
              clearable: "",
              "deletable-chips": "",
              multiple: "",
              items: _vm.templates,
              "item-value": "_id",
              "item-text": "name",
              "itemm-color": "color",
            },
            model: {
              value: _vm.templatesFilter,
              callback: function ($$v) {
                _vm.templatesFilter = $$v
              },
              expression: "templatesFilter",
            },
          }),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          page: _vm.currentPage,
          elevation: 0,
          "items-per-page":
            _vm.$settings.getUserSettings("entries_itemsPerPage") || 50,
          "show-select": "",
          "item-key": "_id",
          loading: _vm.isEntriesLoading || _vm.loadingEntryType,
          headers: _vm.headers,
          items: _vm.tableData,
          search: _vm.search,
          "footer-props": {
            "items-per-page-options": [15, 25, 50, 100],
          },
        },
        on: {
          "update:items-per-page": _vm.saveItemsPerPage,
          pagination: function ($event) {
            _vm.currentPage = $event.page
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item.status",
            fn: function (props) {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "v-chip",
                      {
                        class: props.item.status.blackText
                          ? "black--text"
                          : "white--text",
                        attrs: { color: props.item.status.color, small: "" },
                      },
                      [_vm._v(_vm._s(props.item.status.name))]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item.content.name",
            fn: function (props) {
              return [
                typeof (props.item.content || {}).name === "string"
                  ? _c(
                      "span",
                      [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(_vm._s((props.item.content || {}).name)),
                        ]),
                        props.item.parentID
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: { right: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    "x-small": "",
                                                    color: "info",
                                                  },
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" link ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v("Mit Entität im Graph verlinkt"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : typeof (props.item.content || {}).name !== "undefined" &&
                    props.item.status.type
                  ? _c(
                      "span",
                      [
                        _c("v-progress-linear", {
                          attrs: {
                            indeterminate: "",
                            color: "grey",
                            rounded: "",
                            height: "2",
                            reverse: "",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.languages",
            fn: function (props) {
              return [
                (_vm.$settings.getValue("language.available").length || []) > 1
                  ? _c(
                      "span",
                      _vm._l(props.item.languages, function (lang, index) {
                        return _c(
                          "v-avatar",
                          {
                            key: index,
                            staticClass: "mx-1 my-2 v-btn--outlined",
                            attrs: { size: "28" },
                          },
                          [
                            _c("v-img", {
                              attrs: {
                                src:
                                  "https://webimages.we2p.de/static/flags/svg/" +
                                  lang.code.slice(-2) +
                                  ".svg",
                              },
                            }),
                            lang.status === "locked"
                              ? _c(
                                  "v-icon",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%,-50%)",
                                      "background-color":
                                        "rgba(255,255,255,0.6)",
                                    },
                                    attrs: { color: "black", size: "18" },
                                  },
                                  [_vm._v("lock")]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.controls",
            fn: function (props) {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3 my-2",
                    attrs: {
                      to: "entries/" + props.item._id,
                      fab: "",
                      depressed: "",
                      "x-small": "",
                      router: "",
                      append: "",
                    },
                  },
                  [_c("v-icon", [_vm._v("edit")])],
                  1
                ),
                _c("CometPreview", {
                  key: props.item._id + "_comet",
                  attrs: { index: props.item._id, "entry-id": props.item._id },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "mr-3 my-2",
                                    attrs: {
                                      disabled: !props.item.templateID,
                                      fab: "",
                                      color: "blue",
                                      depressed: "",
                                      "x-small": "",
                                      router: "",
                                      append: "",
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { color: "white" } }, [
                                  _vm._v("remove_red_eye"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _vm.$permGuard.chP("entries.c") &&
                (_vm.$settings.getValue("mail.acquisition.active") ||
                  (_vm.$settings.getValue("mail.confirmation.active") &&
                    _vm.$settings.getValue("status.acquisition.enabled")))
                  ? _c("MailAssistant", {
                      key: props.item._id + "_mail",
                      ref: "mailAssistant__" + props.item._id,
                      attrs: {
                        index: props.item._id,
                        entry: _vm.allEntries.find(function (x) {
                          return x._id === props.item._id
                        }),
                      },
                    })
                  : _vm._e(),
                _vm.$permGuard.chP("entries.c") &&
                _vm.$settings.getValue("general.create.mode") > 0
                  ? _c("DeleteConfirmation", {
                      attrs: {
                        title: "Eintrag für diese Publikation duplizieren?",
                        msg: "Die entstehende Kopie ist nicht mehr mit dem Knowledge Graph verlinkt.",
                      },
                      on: {
                        yes: function ($event) {
                          return _vm.cloneEntry(props.item._id)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mr-3 my-2",
                                        attrs: {
                                          fab: "",
                                          color: "grey",
                                          depressed: "",
                                          "x-small": "",
                                          router: "",
                                          append: "",
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      { staticClass: "white--text" },
                                      [_vm._v("content_copy")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm._e(),
                _vm.$permGuard.chP("entries.d")
                  ? _c("DeleteConfirmation", {
                      attrs: {
                        msg: "Es wird nur der Print-Datensatz aus dieser Auflage gelöscht. Der Cockpit-Datensatz wird nicht gelöscht.",
                      },
                      on: {
                        yes: function ($event) {
                          return _vm.triggerRemoveEntry(props.item._id)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mr-3 my-2",
                                        attrs: {
                                          fab: "",
                                          color: "error",
                                          depressed: "",
                                          "x-small": "",
                                          router: "",
                                          append: "",
                                          disabled: props.item.status.lock,
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    !_vm.deleteInProgress[props.item._id]
                                      ? _c("v-icon", [_vm._v("delete")])
                                      : _vm._e(),
                                    _vm.deleteInProgress[props.item._id]
                                      ? _c("v-progress-circular", {
                                          attrs: {
                                            indeterminate: "",
                                            size: 20,
                                            width: 2,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selectedRows,
          callback: function ($$v) {
            _vm.selectedRows = $$v
          },
          expression: "selectedRows",
        },
      }),
      _c(
        "DeleteConfirmation",
        {
          ref: "redirectDialog",
          attrs: {
            hidden: true,
            msg: "Wollen Sie zur Bearbeitungsmaske des eben angelegten Datensatzes wechseln?",
            title: "Jetzt bearbeiten?",
          },
          on: { yes: _vm.redirectEdit },
        },
        [void 0],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }