import { render, staticRenderFns } from "./ImportForm.vue?vue&type=template&id=b580ad1a&scoped=true&"
import script from "./ImportForm.vue?vue&type=script&lang=js&"
export * from "./ImportForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b580ad1a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCheckbox,VDialog,VIcon,VRow,VSpacer,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/root/print-suite/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b580ad1a')) {
      api.createRecord('b580ad1a', component.options)
    } else {
      api.reload('b580ad1a', component.options)
    }
    module.hot.accept("./ImportForm.vue?vue&type=template&id=b580ad1a&scoped=true&", function () {
      api.rerender('b580ad1a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/import/ImportForm.vue"
export default component.exports