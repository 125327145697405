var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.group
    ? _c(
        "div",
        { staticClass: "my-5" },
        [
          _c(
            "v-row",
            { staticClass: "mx-2 mb-5" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: {
                    depressed: "",
                    color: "success",
                    fab: "",
                    small: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_c("v-icon", [_vm._v(" save ")])],
                1
              ),
              _c("DeleteConfirmation", { on: { yes: _vm.remove } }),
            ],
            1
          ),
          _c("v-text-field", {
            staticClass: "ml-5",
            staticStyle: { "max-width": "800px" },
            attrs: { label: "Name", filled: "" },
            model: {
              value: _vm.group.name,
              callback: function ($$v) {
                _vm.$set(_vm.group, "name", $$v)
              },
              expression: "group.name",
            },
          }),
          _c("v-autocomplete", {
            staticClass: "ml-5 mt-10",
            staticStyle: { "max-width": "500px" },
            attrs: {
              items: _vm.users,
              placeholder: "",
              "item-text": "name",
              "item-value": "id",
              label: "Mitglieder hinzufügen...",
              multiple: "",
              loading: _vm.$store.getters["users/getStatus"] === "loading",
              filled: "",
              chips: "",
              "deletable-chips": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "selection",
                  fn: function (ref) {
                    var item = ref.item
                    var index = ref.index
                    return undefined
                  },
                },
              ],
              null,
              false,
              3337074436
            ),
            model: {
              value: _vm.group.users,
              callback: function ($$v) {
                _vm.$set(_vm.group, "users", $$v)
              },
              expression: "group.users",
            },
          }),
          _c("p", { staticClass: "ml-5 mt-2 text-caption mb-0" }, [
            _vm._v("Mitglieder"),
          ]),
          _vm.selectedUsers.length === 0
            ? _c("p", { staticClass: "text-center my-3" }, [
                _vm._v("Keine Mitglieder"),
              ])
            : _vm._e(),
          _c(
            "v-list",
            {
              staticClass: "ml-5 mb-5",
              staticStyle: { "max-width": "500px" },
              attrs: { dense: "" },
            },
            _vm._l(_vm.selectedUsers, function (user) {
              return _c(
                "v-list-item",
                { key: user.id },
                [
                  _c(
                    "v-list-item-avatar",
                    [
                      user
                        ? _c(
                            "v-avatar",
                            { attrs: { size: "40" } },
                            [
                              _c("UserImage", {
                                attrs: { user: user, alt: "profile image" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v(_vm._s(user.name))]),
                  _c(
                    "v-list-item-action",
                    [
                      _c("DeleteConfirmation", {
                        attrs: {
                          title: user.name + " aus dieser Gruppe entfernen?",
                        },
                        on: {
                          yes: function ($event) {
                            return _vm.removeUser(user.id)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          fab: "",
                                          depressed: "",
                                          "x-small": "",
                                          color: "error",
                                          bind: attrs,
                                        },
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("close")])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }