var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CockpitLabelComponent", {
        attrs: { definition: _vm.definition, "is-locked": _vm.isLocked },
      }),
      _c(
        "v-row",
        { staticStyle: { "max-width": "1000px" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  filled: "",
                  readonly: _vm.isLocked,
                  label: "Straße und Hausnummer",
                  value: _vm.data.street,
                  "hide-details": "auto",
                },
                on: {
                  input: function ($event) {
                    _vm.data = Object.assign({}, _vm.data, { street: $event })
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  filled: "",
                  readonly: _vm.isLocked,
                  label: "PLZ",
                  value: _vm.data.zip,
                  "hide-details": "auto",
                },
                on: {
                  input: function ($event) {
                    _vm.data = Object.assign({}, _vm.data, { zip: $event })
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  filled: "",
                  readonly: _vm.isLocked,
                  label: "Ort",
                  value: _vm.data.city,
                  "hide-details": "auto",
                },
                on: {
                  input: function ($event) {
                    _vm.data = Object.assign({}, _vm.data, { city: $event })
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  filled: "",
                  readonly: _vm.isLocked,
                  label: "Ortsteil",
                  value: _vm.data.urbanDistrict,
                  "hide-details": "auto",
                },
                on: {
                  input: function ($event) {
                    _vm.data = Object.assign({}, _vm.data, {
                      urbanDistrict: $event,
                    })
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  filled: "",
                  readonly: _vm.isLocked,
                  label: "Land",
                  value: _vm.data.country,
                  "hide-details": "auto",
                },
                on: {
                  input: function ($event) {
                    _vm.data = Object.assign({}, _vm.data, { country: $event })
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "mt-3",
          attrs: {
            rounded: "",
            disabled: _vm.isLocked,
            depressed: "",
            color: "success",
            loading: _vm.loadingValidation,
          },
          on: { click: _vm.checkAddress },
        },
        [
          _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
            _vm._v(" beenhere "),
          ]),
          _vm._v(" Adresse validieren "),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 3000 },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function (ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "success", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.snackbarCorrect = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v("OK")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.snackbarCorrect,
            callback: function ($$v) {
              _vm.snackbarCorrect = $$v
            },
            expression: "snackbarCorrect",
          },
        },
        [_vm._v(" Adresse plausibel! ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 3000 },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function (ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "error", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.snackbarNotFound = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v("OK")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.snackbarNotFound,
            callback: function ($$v) {
              _vm.snackbarNotFound = $$v
            },
            expression: "snackbarNotFound",
          },
        },
        [_vm._v(" Keine Adresse zum Vergleich gefunden! ")]
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "500" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(" Adresse korrigieren? "),
              ]),
              _c("v-card-text", [
                _c("p", { staticClass: "text-h6 font-weight-bold mb-2" }, [
                  _vm._v("Ihre Eingabe:"),
                ]),
                _c("p", { staticClass: "my-0" }, [
                  _vm._v(_vm._s(_vm.data.street || "STRASSE???")),
                ]),
                _c("p", { staticClass: "my-0" }, [
                  _vm._v(
                    _vm._s(_vm.data.zip || "PLZ???") +
                      " " +
                      _vm._s(_vm.data.city || "ORT???")
                  ),
                ]),
                _c("p", { staticClass: "my-0" }, [
                  _vm._v(_vm._s(_vm.data.country || "LAND???")),
                ]),
                _c("p", { staticClass: "mt-4 text-h6 font-weight-bold mb-2" }, [
                  _vm._v("Gefundene Adresse:"),
                ]),
                _c("p", { staticClass: "my-0" }, [
                  _c(
                    "span",
                    {
                      class: _vm.alternativeAddress.street
                        ? "warning--text"
                        : "",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.alternativeAddress.street ||
                            _vm.data.street ||
                            "STRASSE???"
                        )
                      ),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "my-0" }, [
                  _c(
                    "span",
                    {
                      class: _vm.alternativeAddress.zip ? "warning--text" : "",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.alternativeAddress.zip || _vm.data.zip || "PLZ???"
                        )
                      ),
                    ]
                  ),
                  _vm._v("  "),
                  _c(
                    "span",
                    {
                      class: _vm.alternativeAddress.city ? "warning--text" : "",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.alternativeAddress.city ||
                            _vm.data.city ||
                            "ORT???"
                        )
                      ),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "my-0" }, [
                  _c(
                    "span",
                    {
                      class: _vm.alternativeAddress.country
                        ? "warning--text"
                        : "",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.alternativeAddress.country ||
                            _vm.data.country ||
                            "LAND???"
                        )
                      ),
                    ]
                  ),
                ]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(" Nein ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", text: "" },
                      on: { click: _vm.useAlternativeAddress },
                    },
                    [_vm._v(" Ja ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }