import { render, staticRenderFns } from "./InDesignEditorDialog.vue?vue&type=template&id=f72dffe6&scoped=true&"
import script from "./InDesignEditorDialog.vue?vue&type=script&lang=js&"
export * from "./InDesignEditorDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f72dffe6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VBtn,VBtnToggle,VCard,VDialog,VIcon,VImg,VSpacer,VToolbar,VToolbarTitle,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/root/print-suite/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f72dffe6')) {
      api.createRecord('f72dffe6', component.options)
    } else {
      api.reload('f72dffe6', component.options)
    }
    module.hot.accept("./InDesignEditorDialog.vue?vue&type=template&id=f72dffe6&scoped=true&", function () {
      api.rerender('f72dffe6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/entries/fullscreen/InDesignEditorDialog.vue"
export default component.exports