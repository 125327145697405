var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "overflow-y-auto",
          staticStyle: { "max-height": "80vh" },
        },
        [
          _c("v-text-field", {
            staticClass: "ml-5",
            attrs: { "prepend-icon": "search" },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _c(
            "v-btn",
            {
              staticClass: "ma-4",
              attrs: { depressed: "" },
              on: { click: _vm.clearOwnLastOpened },
            },
            [_vm._v(" Eigene Aufrufzeit zurücksetzen ")]
          ),
          _c(
            "v-list",
            { attrs: { subheader: "" } },
            [
              _vm.changelogs.length > 0
                ? _c(
                    "v-list-item-group",
                    _vm._l(_vm.changelogs, function (changelog) {
                      return _c(
                        "v-list-item",
                        {
                          key: changelog._id,
                          attrs: {
                            "two-line": "",
                            router: "",
                            to: {
                              name: "AdminChangelogEdit",
                              params: {
                                changelogID: changelog._id,
                              },
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-avatar",
                            [_c("v-icon", [_vm._v(" checklist ")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: {
                                  textContent: _vm._s(changelog.title),
                                },
                              }),
                              _c("v-list-item-subtitle", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.getDate(changelog.date) +
                                      " - " +
                                      (
                                        _vm.systems.find(function (s) {
                                          return changelog.system === s.value
                                        }) || {}
                                      ).text
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }