var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "800" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "white--text",
                          attrs: {
                            small: "",
                            depressed: "",
                            fab: "",
                            dark: "",
                            color: "grey",
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("content_copy")])],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { elevation: "0" } },
                [
                  _c("v-toolbar-title", { staticClass: "text-h5 py-5" }, [
                    _vm._v(" Auflage duplizieren "),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", text: "", fab: "", color: "error" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              !_vm.loading
                ? _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        attrs: {
                          placeholder: _vm.catalog.name + " (Kopie)",
                          label: "Name der neuen Auflage",
                        },
                        model: {
                          value: _vm.newCatalogName,
                          callback: function ($$v) {
                            _vm.newCatalogName = $$v
                          },
                          expression: "newCatalogName",
                        },
                      }),
                      _vm._v(
                        " Alle Einträge in der aktuellen Auflage werden in die neue Auflage kopiert. "
                      ),
                      false
                        ? _c("StatusRuleBuilder", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var attrs = ref.attrs
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                small: "",
                                                depressed: "",
                                                color: "info",
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" Status-Regeln ergänzen ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2355683613
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.loading
                ? _c(
                    "v-card-text",
                    [
                      _c("p", { staticClass: "my-8" }, [
                        _vm._v("Die Auflage wird dupliziert. "),
                        _c("br"),
                        _vm._v(
                          "Je nach Anzahl der Einträge und Bilder kann dieser Vorgang etwas länger dauern."
                        ),
                      ]),
                      _c("v-progress-linear", { attrs: { indeterminate: "" } }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: this.loading,
                        color: "primary",
                        text: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.clone(_vm.catalog)
                        },
                      },
                    },
                    [_vm._v(" Duplizieren ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }