import axios from 'axios';
import router from '../../router';
import Vue from 'vue';

const store = {
    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        user: JSON.parse(localStorage.getItem('user') || '{}') || {},
    },

    getters: {
        isLoggedIn: (state) => !!state.token,
        authStatus: (state) => state.status,
        getUser: (state) => state.user,
    },

    actions: {
        async login({ commit, dispatch }, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request');

                axios
                    .post(
                        '/api/v2/auth/login',
                        {
                            username: user.username,
                            password: user.password,
                        },
                        {
                            timeout: 5000,
                        }
                    )
                    .then((res) => {
                        if (res.data.jwt) {
                            const token = res.data.jwt;
                            const authToken = res.data.authToken;
                            const user = res.data.user;
                            const cockpitAuthToken = res.data.cockpitAuthToken;
                            const cockpitCsrfToken = res.data.cockpitCsrfToken;

                            localStorage.setItem('token', token);
                            localStorage.setItem('auth', authToken);
                            localStorage.setItem(
                                'cockpitAuthToken',
                                cockpitAuthToken
                            );
                            localStorage.setItem(
                                'cockpitCsrfToken',
                                cockpitCsrfToken
                            );
                            localStorage.setItem('user', JSON.stringify(user));

                            // set jwt token for pp-backend
                            axios.defaults.headers.common['Authorization'] =
                                'Bearer ' + token;

                            commit('auth_success', { token, user });
                            dispatch(
                                'user/fetchPermissions',
                                {},
                                { root: true }
                            );
                            dispatch('user/fetchSettings', {}, { root: true });

                            resolve(res);
                        } else {
                            commit('auth_error');
                            localStorage.removeItem('token');
                            reject('no_token');
                        }
                    })
                    .catch(function (err) {
                        commit('auth_error');
                        localStorage.removeItem('token');
                        reject('wrong_credentials');
                    });
            });
        },

        async actAs({ commit, dispatch }, { userID }) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/v2/auth/actas', {
                        userId: userID,
                    })
                    .then((res) => {
                        if (res.data.jwt) {
                            commit('auth_request');
                            const token = res.data.jwt;
                            const user = res.data.user;

                            localStorage.setItem('token', token);
                            localStorage.setItem('user', JSON.stringify(user));

                            // set jwt token for pp-backend
                            axios.defaults.headers.common['Authorization'] =
                                'Bearer ' + token;

                            commit('auth_success', { token, user });

                            dispatch(
                                'user/fetchPermissions',
                                {},
                                { root: true }
                            );
                            dispatch('user/fetchSettings', {}, { root: true });
                            dispatch(
                                'projects/fetchAll',
                                { flush: true },
                                { root: true }
                            ).then(() => {
                                dispatch(
                                    'channels/flush',
                                    { flush: true },
                                    { root: true }
                                ).then(() => {
                                    if (userID) router.push('/projects');
                                });
                            });

                            resolve(res);
                        } else {
                            commit('auth_error');
                            localStorage.removeItem('token');
                            reject('no_token');
                        }
                    })
                    .catch(function (err) {
                        commit('auth_error');
                        reject('failed');
                    });
            });
        },

        async logout({ commit, getters }, options) {
            return new Promise((resolve, reject) => {
                // todo add "session exprired" notification
                commit('logout');
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('auth');
                localStorage.removeItem('cockpitCsrfToken');
                localStorage.removeItem('cockpitAuthToken');
                if (router.currentRoute.name !== 'login') {
                    router.push({ name: 'login' });
                }
                delete axios.defaults.headers.common['Authorization'];
                resolve();
            });
        },
    },

    mutations: {
        auth_request(state) {
            state.status = 'loading';
        },
        auth_success(state, { token, user }) {
            state.status = 'success';
            state.token = token;
            state.user = user;
        },
        auth_error(state) {
            state.status = 'error';
        },
        logout(state) {
            state.status = '';
            state.token = '';
            state.user = {};
        },
    },
};

export default {
    ...store,
    namespaced: true,
};
