var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      [
        _c(
          "v-card",
          [
            _c(
              "v-card-title",
              [
                _vm._v(" Datenbestand "),
                _c("v-spacer"),
                _c("v-text-field", {
                  attrs: {
                    "append-icon": "search",
                    label: "Suchen",
                    "single-line": "",
                    "hide-details": "",
                  },
                  model: {
                    value: _vm.search,
                    callback: function ($$v) {
                      _vm.search = $$v
                    },
                    expression: "search",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-btn",
              {
                staticClass: "ma-4",
                attrs: {
                  color: "success",
                  loading: _vm.isSyncing,
                  rounded: "",
                  depressed: "",
                },
                on: { click: _vm.syncAll },
              },
              [_vm._v(" Alle an Onlim Senden ")]
            ),
            _c("v-data-table", {
              attrs: {
                "items-per-page": 100,
                loading: _vm.loading,
                "loading-text": "Wird geladen...",
                headers: _vm.headers,
                items: _vm.tableData,
                search: _vm.search,
              },
              scopedSlots: _vm._u([
                {
                  key: "item.action",
                  fn: function (props) {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-3 my-2",
                          attrs: {
                            loading: _vm.syncing.includes(props.item.entityID),
                            color: _vm.getActionColor(props.item.action),
                            small: "",
                            depressed: "",
                            rounded: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.singleSync(props.item.entityID)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(props.item.action) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "item.inOnlim",
                  fn: function (props) {
                    return [
                      props.item.inOnlim
                        ? _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v("check"),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }