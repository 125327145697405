var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1000", scrollable: "" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [
                _vm._t(
                  "default",
                  function () {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "my-auto mx-1",
                              attrs: {
                                color: _vm.$vuetify.theme.dark
                                  ? "grey darken-4"
                                  : "white",
                                depressed: "",
                                rounded: "",
                                large: "",
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v("view_column"),
                          ]),
                          _c("span", [_vm._v("Spalten")]),
                        ],
                        1
                      ),
                    ]
                  },
                  { on: on, attrs: attrs }
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { elevation: "1" } },
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(" Spaltenkonfiguration "),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-0",
                  attrs: { color: "red", icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = !_vm.dialog
                    },
                  },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-subheader", [_vm._v("Ausgewählt:")]),
                      _c(
                        "v-card",
                        { staticStyle: { "min-height": "100px" } },
                        [
                          _vm.selectedColumns.length === 0
                            ? _c("v-card-text", [
                                _vm._v(" Keine Spalten ausgewählt... "),
                              ])
                            : _vm._e(),
                          _c(
                            "draggable",
                            _vm._b(
                              {
                                staticClass: "list-group",
                                staticStyle: { "min-height": "100px" },
                                attrs: {
                                  list: _vm.selectedColumns,
                                  group: "columns",
                                  handle: ".handle",
                                },
                                on: {
                                  start: function ($event) {
                                    _vm.drag = true
                                  },
                                  end: function ($event) {
                                    _vm.drag = false
                                  },
                                },
                              },
                              "draggable",
                              _vm.dragOptions,
                              false
                            ),
                            [
                              _c(
                                "transition-group",
                                {
                                  attrs: {
                                    type: "transition",
                                    name: !_vm.drag ? "flip-list" : null,
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.selectedColumns,
                                    function (column, index) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          {
                                            key: index,
                                            staticClass: "white listing-item",
                                            attrs: { index: index },
                                          },
                                          [
                                            _c(
                                              "v-list-item-avatar",
                                              {
                                                staticClass: "handle",
                                                staticStyle: { cursor: "grab" },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(" drag_handle "),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c("div", [
                                              _c("span", [
                                                _vm._v(_vm._s(column.label)),
                                              ]),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "info--text ml-4",
                                                },
                                                [_vm._v(_vm._s(column.name))]
                                              ),
                                            ]),
                                            _c("v-spacer"),
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "ml-3",
                                                attrs: {
                                                  fab: "",
                                                  depressed: "",
                                                  color: "red",
                                                  "x-small": "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.unselect(index)
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { color: "white" } },
                                                  [_vm._v(" close ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: { "append-icon": "search", label: "Suchen" },
                        model: {
                          value: _vm.searchTerm,
                          callback: function ($$v) {
                            _vm.searchTerm = $$v
                          },
                          expression: "searchTerm",
                        },
                      }),
                      _c(
                        "v-card",
                        {
                          staticClass: "py-3",
                          staticStyle: { "min-height": "100px" },
                        },
                        [
                          _vm.availableColumns.length === 0 && !_vm.searchTerm
                            ? _c(
                                "v-card-text",
                                { staticClass: "text-center" },
                                [
                                  _vm._v(
                                    " Bereits alle Datenfelder ausgewählt. "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.availableColumns.length === 0 && _vm.searchTerm
                            ? _c(
                                "v-card-text",
                                { staticClass: "text-center" },
                                [_vm._v(" Keine Suchergebnisse. ")]
                              )
                            : _vm._e(),
                          _vm._l(
                            _vm.availableColumns,
                            function (column, index) {
                              return [
                                _c(
                                  "v-list-item",
                                  {
                                    key: index,
                                    staticClass: "mb-3 white listing-item",
                                    attrs: { index: index },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-3",
                                        attrs: {
                                          fab: "",
                                          "x-small": "",
                                          depressed: "",
                                          color: "success",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.select(index)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "white" } },
                                          [_vm._v(" add ")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(_vm._s(column.label)),
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "info--text ml-4" },
                                        [_vm._v(_vm._s(column.name))]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "px-4",
                  attrs: { depressed: "", color: "success" },
                  on: { click: _vm.apply },
                },
                [_vm._v(" Anwenden ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }