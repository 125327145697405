var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contacts" },
    [
      _c(
        "v-row",
        { staticClass: "mx-0 my-0" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                color: "info",
                depressed: "",
                loading: _vm.downloadingCSV,
              },
              on: { click: _vm.downloadCSV },
            },
            [
              _c("span", [_vm._v("CSV exportieren")]),
              _c("v-icon", { staticClass: "ml-2" }, [_vm._v(" download ")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-0" },
        [
          _c(
            "v-col",
            { staticStyle: { "max-width": "450px", position: "relative" } },
            [
              _c("ContactList"),
              _c(
                "v-btn",
                {
                  staticStyle: { right: "-15px", top: "32px" },
                  attrs: { fab: "", absolute: "", color: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.createContact()
                    },
                  },
                },
                [_c("v-icon", [_vm._v(" add ")])],
                1
              ),
            ],
            1
          ),
          _vm.$router.currentRoute.params.contactID
            ? _c(
                "v-col",
                { staticStyle: { "max-height": "80vh" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "overflow-y-auto pa-5",
                      staticStyle: { "max-height": "80vh" },
                      attrs: { elevation: "0" },
                    },
                    [
                      _c("router-view", {
                        key: _vm.$router.currentRoute.params.contactID,
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }