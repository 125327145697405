var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channels" },
    [
      _c("p", { staticClass: "text-caption mb-0 ml-5 mt-5" }, [
        _vm._v("Projekt"),
      ]),
      _c("h1", { staticClass: "mx-5 display-2" }, [
        _vm._v(_vm._s(_vm.$store.getters["projects/getCurrent"].name)),
      ]),
      _c("v-spacer", { staticStyle: { height: "30px" } }),
      false
        ? _c(
            "router-link",
            {
              staticStyle: {
                "text-decoration": "none",
                "max-width": "500px",
                display: "block",
              },
              attrs: {
                router: "",
                to: {
                  name: "GraphEntities",
                },
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "mt-5 mx-5" },
                [
                  _c(
                    "v-img",
                    {
                      staticClass: "white--text align-end",
                      attrs: {
                        src: "https://i1.wp.com/chelseatroy.com/wp-content/uploads/2017/12/graph-db.png?fit=1000%2C745&ssl=1",
                        gradient: "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.8)",
                        height: "300px",
                      },
                    },
                    [
                      _c("v-card-title", [
                        _c(
                          "span",
                          {
                            staticClass: "ml-3 text-h4 font-weight-bold",
                            staticStyle: { "word-break": "normal" },
                          },
                          [_vm._v("Knowledge Graph")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        { attrs: { text: "" } },
                        [
                          _c("v-icon", [_vm._v("subdirectory_arrow_right")]),
                          _c("span", [_vm._v("Alle Datensätze")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("v-spacer", { staticStyle: { height: "40px" } }),
      _c(
        "v-container",
        { staticClass: "mx-3" },
        [
          _c("h3", { staticClass: "text-h3" }, [_vm._v("Channels")]),
          _c("ChannelGrid"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }