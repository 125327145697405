var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "1000", scrollable: "", fullscreen: _vm.fullscreen },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            color: "darkgrey",
                            depressed: "",
                            rounded: "",
                            dark: "",
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("compare_arrows"),
                      ]),
                      _vm._v(" vergleichen "),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline text-h4" },
                [
                  _vm._v(" Snapshot: "),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0",
                      attrs: { icon: "", text: "", color: "error", large: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-switch", {
                staticClass: "ml-5",
                attrs: { label: "Vollbild" },
                model: {
                  value: _vm.fullscreen,
                  callback: function ($$v) {
                    _vm.fullscreen = $$v
                  },
                  expression: "fullscreen",
                },
              }),
              _c("v-divider"),
              _c("v-card-text", {
                staticClass: "my-5",
                domProps: {
                  innerHTML: _vm._s(_vm.diff(_vm.newData, _vm.oldData)),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }