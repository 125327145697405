var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", scrollable: "", "max-width": "800px" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [
                _vm._t(
                  "default",
                  function () {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-3",
                              attrs: {
                                fab: "",
                                color: "success",
                                depressed: "",
                                "x-small": "",
                                router: "",
                                append: "",
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { color: "white" } }, [
                            _vm._v("sync"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  { on: on, attrs: attrs }
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "pt-5" }, [
            _c(
              "span",
              { staticClass: "headline" },
              [
                _vm._v(" Status Migration "),
                _c("v-icon", { staticClass: "ml-2" }, [_vm._v(" nat ")]),
              ],
              1
            ),
          ]),
          _c(
            "v-card-text",
            { staticClass: "mt-5" },
            [
              _c("p", [
                _vm._v(
                  "Ändert den Status aller Einträge in dieser Auflage nach den unten definierten Regeln:"
                ),
              ]),
              _vm._l(_vm.rules, function (rule, index) {
                return _c(
                  "v-row",
                  { key: index },
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-select", {
                          staticClass: "my-0 py-0",
                          attrs: {
                            items: _vm.statusList,
                            "item-value": "value",
                            "item-text": "label",
                          },
                          model: {
                            value: rule.from,
                            callback: function ($$v) {
                              _vm.$set(rule, "from", $$v)
                            },
                            expression: "rule.from",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "text-center", attrs: { cols: "1" } },
                      [_c("v-icon", [_vm._v("east")])],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c("v-select", {
                          staticClass: "my-0 py-0",
                          attrs: {
                            items: _vm.targetStatusList,
                            "item-value": "value",
                            "item-text": "label",
                          },
                          model: {
                            value: rule.to,
                            callback: function ($$v) {
                              _vm.$set(rule, "to", $$v)
                            },
                            expression: "rule.to",
                          },
                        }),
                      ],
                      1
                    ),
                    index !== 0
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "my-3",
                            attrs: {
                              color: "error",
                              fab: "",
                              "x-small": "",
                              depressed: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.removeRow(index)
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("close")])],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _c(
                "v-row",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        depressed: "",
                        small: "",
                        color: "success",
                      },
                      on: { click: _vm.addRow },
                    },
                    [_c("v-icon", [_vm._v("add")])],
                    1
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "error", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Abbrechen ")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { color: "success" }, on: { click: _vm.convert } },
                [_vm._v(" Konvertieren ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }