var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "users" },
    [
      _c("p", { staticClass: "text-h5" }, [_vm._v("Importe")]),
      _c("p", { staticClass: "body-1 my-5" }, [
        _vm._v(
          "Hier können die über den Publication Planner registrierten Importe verwaltet werden."
        ),
      ]),
      _c("v-text-field", {
        staticClass: "my-4",
        staticStyle: { "max-width": "300px" },
        attrs: {
          "append-icon": "mdi-magnify",
          label: "Suchen",
          "single-line": "",
          "hide-details": "",
        },
        model: {
          value: _vm.search,
          callback: function ($$v) {
            _vm.search = $$v
          },
          expression: "search",
        },
      }),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.tableData,
          "sort-by": "name",
          "items-per-page": 100,
          loading: _vm.$store.getters["users/getStatus"] === "loading",
          search: _vm.search,
        },
        scopedSlots: _vm._u([
          {
            key: "item.controls",
            fn: function (props) {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3 my-2",
                    attrs: {
                      color: "success",
                      fab: "",
                      depressed: "",
                      "x-small": "",
                      title: "Jetzt ausführen",
                    },
                    on: {
                      click: function ($event) {
                        _vm.triggerImport(props.item._id.toString())
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("play_arrow")])],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3 my-2",
                    attrs: {
                      color: "success",
                      fab: "",
                      depressed: "",
                      "x-small": "",
                      title: "Mit Cache ausführen",
                    },
                    on: {
                      click: function ($event) {
                        _vm.triggerImport(props.item._id.toString(), {
                          debug: true,
                        })
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("bug_report")])],
                  1
                ),
                _c("ImportForm", {
                  attrs: {
                    value: _vm.imports.find(function (i) {
                      return i._id.toString() === props.item._id.toString()
                    }),
                  },
                }),
                _c("DeleteConfirmation", {
                  attrs: {
                    title: "Import entfernen?",
                    msg: "Der Import wird für immer und ewig gelöscht (das ist sehr lange)!",
                  },
                  on: {
                    yes: function ($event) {
                      _vm.$store.dispatch("imports/remove", {
                        id: props.item._id.toString(),
                      })
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "ml-3",
                                    attrs: {
                                      depressed: "",
                                      "x-small": "",
                                      color: "error",
                                      fab: "",
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_c("v-icon", [_vm._v("delete")])],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
          },
          {
            key: "item.feedUrl",
            fn: function (props) {
              return [
                _c(
                  "a",
                  {
                    attrs: { target: "_blank", href: "" + props.item.feedUrl },
                  },
                  [_vm._v(" " + _vm._s(props.item.feedUrl) + " ")]
                ),
              ]
            },
          },
          {
            key: "item.prev",
            fn: function (props) {
              return [
                _c(
                  "span",
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-1", attrs: { small: "" } },
                      [_vm._v("history")]
                    ),
                    props.item.lastSuccess
                      ? [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                bottom: "",
                                "min-width": "200px",
                                rounded: "",
                                "offset-y": "",
                                "nudge-left": "12px",
                                "nudge-bottom": "4px",
                                "open-on-hover": "",
                                transition: "slide-y-transition",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c("span", _vm._g({}, on), [
                                          _vm._v(
                                            _vm._s(props.item.lastSuccess)
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("v-card", { staticClass: "py-3 px-3" }, [
                                _c(
                                  "p",
                                  { staticClass: "my-0 font-weight-bold" },
                                  [_vm._v("Kurzübersicht:")]
                                ),
                                _c("p", { staticClass: "my-0" }, [
                                  _vm._v(
                                    "Erstellt: " +
                                      _vm._s(props.item.stats.created)
                                  ),
                                ]),
                                _c("p", { staticClass: "my-0" }, [
                                  _vm._v(
                                    "Aktualisiert: " +
                                      _vm._s(props.item.stats.updated)
                                  ),
                                ]),
                                _c("p", { staticClass: "my-0" }, [
                                  _vm._v(
                                    "Gelöscht: " +
                                      _vm._s(props.item.stats.deleted)
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ]
                      : [_c("span", [_vm._v("keine")])],
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "item.projectIdentifiers",
            fn: function (props) {
              return [
                props.item.projectIdentifiers.length === 1
                  ? [
                      _c("span", [
                        _vm._v(_vm._s(props.item.projectIdentifiers[0])),
                      ]),
                    ]
                  : [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            bottom: "",
                            "min-width": "200px",
                            rounded: "",
                            "offset-y": "",
                            "nudge-left": "12px",
                            "nudge-bottom": "4px",
                            "open-on-hover": "",
                            transition: "slide-y-transition",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c("span", _vm._g({}, on), [
                                      _vm._v(
                                        _vm._s(
                                          props.item.projectIdentifiers.length
                                        ) + " Projekte"
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "py-1 pl-3 pr-5" },
                            [
                              _vm._l(
                                props.item.projectIdentifiers,
                                function (project, index) {
                                  return [
                                    _c("span", { key: index }, [
                                      _vm._v(_vm._s(project)),
                                      _c("br"),
                                    ]),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
              ]
            },
          },
          {
            key: "item.next",
            fn: function (props) {
              return [
                _c(
                  "span",
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-1", attrs: { small: "" } },
                      [_vm._v("update")]
                    ),
                    props.item.active
                      ? _c("span", [_vm._v(_vm._s(props.item.next))])
                      : _c("span", [_vm._v("inaktiv")]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item.active",
            fn: function (props) {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { depressed: "", fab: "", "x-small": "", icon: "" },
                    on: {
                      click: function ($event) {
                        _vm.toggleActiveState(props.item._id.toString())
                      },
                    },
                  },
                  [
                    props.item.active
                      ? _c(
                          "v-icon",
                          { attrs: { color: "success", size: "26" } },
                          [_vm._v("check")]
                        )
                      : _c(
                          "v-icon",
                          { attrs: { color: "error", size: "26" } },
                          [_vm._v("close")]
                        ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("ImportForm", {
        attrs: { create: true, value: { cron: "0 0 * * *" } },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }