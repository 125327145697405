import { render, staticRenderFns } from "./SyncButtons.vue?vue&type=template&id=575bc0c0&scoped=true&"
import script from "./SyncButtons.vue?vue&type=script&lang=js&"
export * from "./SyncButtons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "575bc0c0",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VScrollXReverseTransition } from 'vuetify/lib/components/transitions';
import { VScrollXTransition } from 'vuetify/lib/components/transitions';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VIcon,VScrollXReverseTransition,VScrollXTransition,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/root/print-suite/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('575bc0c0')) {
      api.createRecord('575bc0c0', component.options)
    } else {
      api.reload('575bc0c0', component.options)
    }
    module.hot.accept("./SyncButtons.vue?vue&type=template&id=575bc0c0&scoped=true&", function () {
      api.rerender('575bc0c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/entries/formComponents/SyncButtons.vue"
export default component.exports