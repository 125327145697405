var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "offset-y": "",
            "nudge-bottom": "10",
            "close-on-content-click": false,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mr-5",
                          attrs: { color: "white", fab: "", "x-small": "" },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-badge",
                        {
                          attrs: {
                            color: "error",
                            value: _vm.unreadNotifications.length,
                            content: _vm.unreadNotifications.length,
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "grey darken-4", size: "24" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.unreadNotifications.length > 0
                                      ? "notifications_active"
                                      : "notifications"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { "min-width": "400" } },
            [
              _vm.$store.getters["notifications/getAll"] === "loading"
                ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
                : _vm._e(),
              _c(
                "v-list",
                {
                  key: _vm.key,
                  attrs: { "max-width": "700", "three-line": "" },
                },
                [
                  _vm.$store.getters["notifications/getAll"] !== "loading"
                    ? [
                        _vm._l(_vm.notifications, function (item, index) {
                          return [
                            _c(
                              "v-list-item",
                              { key: item._id.toString() },
                              [
                                item.type === "success"
                                  ? _c(
                                      "v-list-item-avatar",
                                      { attrs: { color: "success" } },
                                      [
                                        _c(
                                          "v-avatar",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "white" } },
                                              [_vm._v("check")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : item.type === "error"
                                  ? _c(
                                      "v-list-item-avatar",
                                      { attrs: { color: "error" } },
                                      [
                                        _c(
                                          "v-avatar",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "white" } },
                                              [_vm._v("close")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-list-item-avatar",
                                      { attrs: { color: "info" } },
                                      [
                                        _c(
                                          "v-avatar",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "white" } },
                                              [_vm._v("info")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      [
                                        _vm._v(" " + _vm._s(item.title) + " "),
                                        _c("v-badge", {
                                          staticClass: "ml-2 mb-2",
                                          attrs: {
                                            color: "info",
                                            dot: "",
                                            value: !item.read,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-subtitle",
                                      { staticClass: "my-1" },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.timeAgo.format(
                                                new Date(item.createdAt),
                                                "round"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c("v-list-item-subtitle", [
                                      _vm._v(_vm._s(item.message)),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-action",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          depressed: "",
                                          color: "error",
                                          icon: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.$store.dispatch(
                                              "notifications/remove",
                                              { id: item._id.toString() }
                                            )
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("delete")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            index < _vm.notifications.length - 1
                              ? _c("v-divider", { key: index })
                              : _vm._e(),
                          ]
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm.notifications.length === 0
                ? _c(
                    "div",
                    { staticClass: "text-center py-5" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "py-5",
                          attrs: { size: "48", color: "success" },
                        },
                        [_vm._v("done_all")]
                      ),
                      _c("p", { staticClass: "py-5" }, [
                        _vm._v("Keine Benachrichtigungen."),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }