var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalogs" },
    [
      _c(
        "v-btn",
        {
          staticClass: "mb-5 mt-2",
          attrs: {
            text: "",
            depressed: "",
            router: "",
            to:
              "/projects/" +
              _vm.$router.currentRoute.params.projectID +
              "/channels/" +
              _vm.$router.currentRoute.params.channelID +
              "/print",
          },
        },
        [
          _c("v-icon", { staticClass: "mr-3" }, [_vm._v("arrow_back")]),
          _vm._v(" dashboard "),
        ],
        1
      ),
      _c("CatalogList"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }