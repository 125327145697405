<template>

    <div class="my-5" v-if="changelog">

        <v-row class="mx-2 mb-5">
            <v-spacer></v-spacer>
            <v-btn class="mr-3" color="info" depressed fab small @click="clone">
                <v-icon>
                    content_copy
                </v-icon>
            </v-btn>
            <DeleteConfirmation  @yes="remove">
            </DeleteConfirmation>
        </v-row>

        <v-text-field v-model="changelog.title" label="Titel" style="max-width: 800px;" filled/>

        <v-row class='mx-0'>
            <v-menu
                v-model="dateDialog"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        class="ml-0"
                        filled
                        style="max-width: 180px"
                        :value="formatDate"
                        label="Datum"
                        append-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    @input="dateDialog = false"
                ></v-date-picker>
            </v-menu>
            
            <v-select label='System'
                      filled
                      style='max-width: 400px'
                      class='ml-5'
                      v-model='changelog.system'
                      :items='systems'
            ></v-select>
        </v-row>
        

        <ckeditor
            :editor="editor"
            :config="editorConfig"
            v-model="changelog.body"
        ></ckeditor>

    </div>


</template>

<script>
import _ from "lodash";
import DeleteConfirmation from "@/components/utility/DeleteConfirmation";
import {DateTime} from "luxon";

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEUploadAdapter from "../../../services/CKEUploadAdapter";

export default {
    name: "ChangelogEdit",
    components: {
        DeleteConfirmation
    },
    data() {
        return {
            dateDialog: false,
            loading: false,
            editor: ClassicEditor,
            editorConfig: {
                mediaEmbed: {
                    previewsInData: true,
                },
                toolbar: {
                    items: [
                        'bold', 'italic', 'link',
                        '|',
                        'numberedList', 'bulletedList',
                        '|',
                        // 'uploadImage',
                        'mediaEmbed',
                        '|',
                        'undo', 'redo',
                    ],
                },
                extraPlugins: [ this.uploader ],
            },
            systems: [
                {
                    value: 'pp',
                    text: 'Publication Planner'
                },
                {
                    value: 'graph',
                    text: 'Knowledge Graph'
                }
            ]
        }
    },
    computed: {
        date: {
            get: function() {
                let date = new Date();
                if (this.changelog.date) {
                    date = (new Date(this.changelog.date));
                }
                console.log(date.toISOString());
                return date.toISOString().split('T')[0] ?? "";
            },
            set: function (value) {
                console.log(value);
                this.changelog.date = value;
            }
        },
        formatDate: function () {
            return DateTime.fromISO(this.date).toFormat('dd.LL.yyyy')
        },
        changelogID: function() {
            return this.$router.currentRoute.params.changelogID;
        },
        changelog: {
            get() {
                return this.$store.getters['changelog/getAll'].find(r => r._id.toString() === this.changelogID);
            },
        },
    },
    watch: {
        changelog: {
            handler(val, oldVal) {
                if (oldVal && val) this.save();
            },
            deep: true,
        }
    },
    methods: {
        uploader(editor) {
            editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                return new CKEUploadAdapter( loader );
            };
        },
        clone() {
            this.$store.dispatch('changelog/add', {
                data: {
                    ...this.changelog,
                    title: this.changelog.title + " - Kopie",
                    date: (new Date()).toISOString(),
                    _id: undefined
                }
            }).then(() => {
                this.$notify({group: 'main', type: 'success', duration: 1000, title: "Changelog kopiert." });
            });
        },
        remove() {
            this.loading = true;
            this.$store.dispatch('changelog/remove', { id: this.changelogID }).then(() => {
                this.loading = false;
                this.$router.push({
                    name: "AdminChangelog"
                })
            });
        },
        save: _.debounce(function() {
            this.$store.dispatch('changelog/update', { id: this.changelogID, data: this.changelog }).then(() => {
                this.loading = false;
                this.$notify({group: 'main', type: 'success', duration: 1000, title: "gespeichert" });
            })
        }, 500)
    },
    created() {
        //this.$store.dispatch('users/fetchAll');
    }
}
</script>

<style>
</style>
