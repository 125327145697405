<template>

    <v-dialog
            v-model="dialog"
            max-width="800"
    >
        <template v-slot:activator="{ on, attrs }">
            <slot :on="on" :attrs="attrs">
                <v-btn class="mr-3" fab color="black" depressed x-small router append  v-on="on" v-bind="attrs">
                    <v-icon color="white">local_post_office</v-icon>
                </v-btn>
            </slot>
        </template>

        <v-card>
            <v-toolbar elevation="0" class="py-2">
                <v-card-title class="headline">
                    Dokumente
                </v-card-title>
                <v-spacer></v-spacer>
                <v-btn class="mr-0"
                       color="red"
                       icon
                       @click="dialog = !dialog"
                >
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <template v-if="$settings.getValue('cometConnection.client') || !$permGuard.chP('super_admin')">
                    <v-row class="px-5">
                        <v-col v-if="$settings.getValue('mail.acquisition.active') && $settings.getValue('status.acquisition.enabled')">
                            <!-- ACQUISITION -->
                            <template v-if="$settings.getValue('mail.active')">
                                <!-- MAIL -->
                                <v-hover v-slot="{ hover }">
                                    <v-btn class="d-block mx-auto mt-5 mb-3" color="info" fab depressed x-large :loading="loadingAcquisitionMail" @click="mailAcquisition">
                                        <v-icon large>{{ hover ? "send" : "local_offer" }}</v-icon>
                                    </v-btn>
                                </v-hover>
                                <p class="text-center text-h6 my-0">Akquise</p>
                                <v-btn class="d-block mx-auto mt-1 mb-5" color="red" depressed rounded text @click="previewAcquisition(true)" :loading="loadingAcquisitionPreview">
                                    <v-icon>picture_as_pdf</v-icon>
                                    <span class="text-caption ml-1 grey--text">Download</span>
                                </v-btn>
                            </template>
                            <template v-else>
                                <!-- DOWNLOAD -->
                                <v-hover v-slot="{ hover }">
                                    <v-btn class="d-block mx-auto mt-5 mb-3" color="info" fab depressed x-large :loading="loadingAcquisitionPreview" @click="previewAcquisition(true)">
                                        <v-icon large>{{ hover ? "download" : "picture_as_pdf" }}</v-icon>
                                    </v-btn>
                                </v-hover>
                                <p class="text-center text-h6 my-0">Akquise</p>
                            </template>
                        </v-col>
                        <v-col v-if="$settings.getValue('mail.confirmation.active')">
                            <!-- CONFIRMATION -->
                            <template v-if="$settings.getValue('mail.active')">
                                <!-- MAIL -->
                                <v-hover v-slot="{ hover }">
                                    <v-btn class="d-block mx-auto mt-5 mb-3" color="lightgrey" fab depressed x-large :loading="loadingConfirmationMail" @click="mailConfirmation">
                                        <v-icon x-large>{{ hover ? "send" : "history_edu" }}</v-icon>
                                    </v-btn>
                                </v-hover>
                                <p class="text-center text-h6 my-0">Freigabe</p>
                                <v-btn class="d-block mx-auto mt-1 mb-5" color="red" depressed rounded text @click="previewConfirmation(true)" :loading="loadingConfirmationPreview">
                                    <v-icon>picture_as_pdf</v-icon>
                                    <span class="text-caption ml-1 grey--text">Download</span>
                                </v-btn>
                            </template>
                            <template v-else>
                                <!-- DOWNLOAD -->
                                <v-hover v-slot="{ hover }">
                                    <v-btn class="d-block mx-auto mt-5 mb-3" color="grey lighten-2" fab depressed x-large :loading="loadingConfirmationPreview" @click="previewConfirmation(true)">
                                        <v-icon large>{{ hover ? "download" : "picture_as_pdf" }}</v-icon>
                                    </v-btn>
                                </v-hover>
                                <p class="text-center text-h6 my-0">Freigabe</p>
                            </template>
                        </v-col>
                    </v-row>

                    <template v-if="$settings.getValue('mail.active')">
                        <v-combobox v-model="mailTo"
                                    placeholder="Bitte E-Mail Adresse wählen..."
                                    label="Empfängeradresse"
                                    class="mt-5"
                                    :items="mails"
                                    item-value="address"
                                    item-text="address"
                                    autocomplete="off"
                        >
                            <template v-slot:no-data>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>Keine E-Mail Adressen gefunden.</v-list-item-title>
                                        <v-list-item-subtitle>Bitte manuell eine E-Mail-Adresse eingeben.</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <template v-slot:item="{ index, item }">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.address }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.source }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-combobox>

                        <v-checkbox
                                v-if="$store.getters['auth/getUser'].mail"
                                v-model="sendBcc"
                                :label="`Blindkopie an mich (${ $store.getters['auth/getUser'].mail }) senden?`"
                                color="success"
                                hide-details
                        ></v-checkbox>
                    </template>
                </template>
                <template v-else>
                    <p class="mt-5 ml-4">
                        <span class="text-h6">Dokumente können nicht generiert werden.</span><br>
                        <span class="error--text font-weight-bold">FEHLER</span>&nbsp;Comet-Client noch nicht konfiguriert.<br>
                    </p>
                </template>


            </v-card-text>
        </v-card>

    </v-dialog>

</template>

<script>

    import axios from 'axios';
    import TranslationService from "../../services/TranslationService";
    import DownloadService from "../../services/DownloadService";
    import SocketAdapter from "../../services/socket-adapter";

    export default {
        name: 'MailAssistant',
        props: ['entry'],
        components: {

        },
        data() {
            return {
                loading: false,
                dialog: false,
                sendBcc: false,
                mailTo: "",
                loadingAcquisitionPreview: false,
                loadingAcquisitionMail: false,
                loadingConfirmationPreview: false,
                loadingConfirmationMail: false,
            }
        },
        computed: {
            mails: function() {

                let mailList = [];

                if (this.entry.recentMail) {
                    mailList.push({
                        address: this.entry.recentMail,
                        source: "Zuletzt verwendet",
                    });
                }

                // look for emails inside of contacts point
                for (const index in (this.entry.content?.contactPoint) ?? []) {
                    let contact = this.entry.content.contactPoint[index];
                    if (contact.email) {
                        mailList.push({
                            address: TranslationService.getValue(contact.email, 'de-DE'),
                            source: "Gefunden in Kontakt " + (parseInt(index)+1) + " (" + contact.contactType?.join(', ') + ")",
                        });
                    }
                }

                return mailList;

            }
        },
        watch: {

        },
        methods: {
            async previewAcquisition(download = false) {

                this.loadingAcquisitionPreview = true;

                let apiURL = `/api/comet/acquisition/preview?entryId=${ this.entry._id }`;

                let popupWindow;

                if (!download) {
                    popupWindow = window.open('/loading.html', '_blank');
                }

                let languages = this.entry.languages.map((l) => l.code);
                if (!languages || languages?.length === 0) languages = ["de-DE"];

                await axios.post(apiURL , {
                    languages
                },{
                    timeout: 30 * 1000,
                }).then((res) => {
                    if (!download) {
                        this.loadingAcquisitionPreview = false;
                        res.data.map((url) => {
                            popupWindow.location.href = url;
                        })
                    } else {
                        res.data.map((url) => {
                            DownloadService.downloadWithAxios(url, `akquise_${ this.entry._id }.pdf`).then(() => {
                                this.loadingAcquisitionPreview = false;
                            }).catch((e) => {
                                console.error(e);
                            });
                        })
                    }
                }).catch((e) => {
                    if (e.code === 'ECONNABORTED' && !download) {
                        popupWindow.location.href = "/errors/comet_timeout.html";
                    } else {
                        console.log(e);
                        this.$notify({
                            group: 'main',
                            type: 'error',
                            duration: -1,
                            title: "Generierung der Akquise fehlgeschlagen.",
                        })
                        popupWindow?.close();
                    }
                    this.loadingAcquisitionPreview = false;
                }).finally(() => {
                    this.$store.dispatch('entries/fetchSingleEntry', { id: this.entry._id });
                    SocketAdapter.socket().emit('entries:refresh_single', {
                        entryID: this.entry._id,
                        roomName: `project:${ this.$router.currentRoute.params.projectID }`
                    });
                })

            },
            async previewConfirmation(download = false) {

                this.loadingConfirmationPreview = true;

                let apiURL = `/api/comet/confirmation/preview?entryId=${ this.entry._id }`;

                let popupWindow;

                if (!download) {
                    popupWindow = window.open('/loading.html', '_blank');
                }

                let languages = this.entry.languages.map((l) => l.code);
                if (!languages || languages?.length === 0) languages = ["de-DE"];

                await axios.post(apiURL , {
                    languages
                }, {
                    timeout: 30 * 1000,
                }).then((res) => {
                    if (!download) {
                        this.loadingConfirmationPreview = false;
                        res.data.map((url) => {
                            popupWindow.location.href = url;
                        })
                    } else {
                        res.data.map((url) => {
                            DownloadService.downloadWithAxios(url, `freigabe_${ this.entry._id }.pdf`).then(() => {
                                this.loadingConfirmationPreview = false;
                            }).catch((e) => {
                                console.error(e);
                            });
                        })
                    }
                }).catch((e) => {
                    if (e.code === 'ECONNABORTED' && !download) {
                        popupWindow.location.href = "/errors/comet_timeout.html";
                    } else {
                        console.log(e);
                        this.$notify({
                            group: 'main',
                            type: 'error',
                            duration: -1,
                            title: "Generierung der Freigabe fehlgeschlagen.",
                        })
                        popupWindow?.close();
                    }
                    this.loadingConfirmationPreview = false;
                }).finally(() => {
                    this.$store.dispatch('entries/fetchSingleEntry', { id: this.entry._id });
                    SocketAdapter.socket().emit('entries:refresh_single', {
                        entryID: this.entry._id,
                        roomName: `project:${ this.$router.currentRoute.params.projectID }`
                    });
                })

            },
            mailAcquisition() {

                if (!this.mailTo) {
                    this.$notify({
                        group: 'main',
                        type: 'warning',
                        duration: 10000,
                        title: "E-Mail kann nicht gesendet werden",
                        text: "Bitte wählen Sie einen gültigen Empfänger."
                    });
                    return;
                }

                this.loadingAcquisitionMail = true;

                axios.post(`/api/comet/acquisition/mail`, {
                    entryID: this.entry._id,
                    recipient: this.mailTo,
                    bcc: this.sendBcc ? this.$store.getters['auth/getUser'].mail : undefined,
                }).then((res) => {
                    this.$notify({
                        group: 'main',
                        type: 'success',
                        duration: 1000,
                        title: "E-Mail erfolgreich versendet.",
                    });
                }).catch((e) => {
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        duration: -1,
                        title: "Senden der E-Mail fehlgeschlagen.",
                    });
                    console.log(e);
                }).finally(() => {
                    this.$store.dispatch('entries/fetchSingleEntry', { id: this.entry._id });
                    SocketAdapter.socket().emit('entries:refresh_single', {
                        entryID: this.entry._id,
                        roomName: `project:${ this.$router.currentRoute.params.projectID }`
                    });
                    this.loadingAcquisitionMail = false;
                })


            },
            mailConfirmation() {

                if (!this.mailTo) {
                    this.$notify({
                        group: 'main',
                        type: 'warning',
                        duration: 10000,
                        title: "E-Mail kann nicht gesendet werden",
                        text: "Bitte wählen Sie einen gültigen Empfänger."
                    });
                    return;
                }

                this.loadingConfirmationMail = true;

                axios.post(`/api/comet/confirmation/mail`, {
                    entryID: this.entry._id,
                    recipient: this.mailTo,
                    bcc: this.sendBcc ? this.$store.getters['auth/getUser'].mail : undefined,
                }).then((res) => {
                    this.$notify({
                        group: 'main',
                        type: 'success',
                        duration: 1000,
                        title: "E-Mail erfolgreich versendet.",
                    });
                }).catch((e) => {
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        duration: -1,
                        title: "Senden der E-Mail fehlgeschlagen.",
                    });
                    console.log(e);
                }).finally(() => {
                    this.$store.dispatch('entries/fetchSingleEntry', { id: this.entry._id });
                    SocketAdapter.socket().emit('entries:refresh_single', {
                        entryID: this.entry._id,
                        roomName: `project:${ this.$router.currentRoute.params.projectID }`
                    });
                    this.loadingConfirmationMail = false;
                })


            },
        },
        created() {

        }
    }
</script>

<style scoped>

</style>
