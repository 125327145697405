var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "venus__pp" } },
    [
      _vm.$store.getters["auth/isLoggedIn"]
        ? _c("NavBar", { attrs: { "app-mode": _vm.appMode } })
        : _vm._e(),
      _c(
        "v-main",
        {
          class:
            (_vm.$vuetify.theme.dark
              ? "main-content--dark "
              : "main-content ") + " pb-5",
        },
        [
          _c(
            "v-container",
            { staticClass: "py-4 px-5", attrs: { fluid: "" } },
            [
              _c("router-view", {
                key: _vm.$route.name + (_vm.$route.params.projectID || ""),
                attrs: { activeUser: _vm.activeUser },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("notifications", {
        attrs: { group: "main", position: "bottom right" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }