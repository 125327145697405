var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.role
    ? _c(
        "div",
        { staticClass: "my-5" },
        [
          _c(
            "v-row",
            { staticClass: "mx-2 mb-5" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: {
                    color: "success",
                    depressed: "",
                    fab: "",
                    small: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_c("v-icon", [_vm._v(" save ")])],
                1
              ),
              _c("DeleteConfirmation", { on: { yes: _vm.remove } }),
            ],
            1
          ),
          _c("v-text-field", {
            staticClass: "ml-5",
            staticStyle: { "max-width": "800px" },
            attrs: { label: "Name", filled: "" },
            model: {
              value: _vm.role.name,
              callback: function ($$v) {
                _vm.$set(_vm.role, "name", $$v)
              },
              expression: "role.name",
            },
          }),
          _c(
            "v-tabs",
            {
              attrs: {
                "background-color": "transparent",
                color: "basil",
                grow: "",
              },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("v-tab", [_vm._v(" Knowledge Graph ")]),
              _c("v-tab", [_vm._v(" Publication Planner ")]),
              _c("v-tab", [_vm._v(" Media Collection ")]),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("v-tab-item", [
                _c("h3", { staticClass: "mt-10" }, [
                  _vm._v("cockpit permissions"),
                ]),
              ]),
              _c("v-tab-item", [_c("PermissionTree")], 1),
              _c("v-tab-item"),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }