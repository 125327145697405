var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [
                _vm._t(
                  "default",
                  function () {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-3",
                              attrs: {
                                fab: "",
                                color: "black",
                                depressed: "",
                                "x-small": "",
                                router: "",
                                append: "",
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { color: "white" } }, [
                            _vm._v("local_post_office"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  { on: on, attrs: attrs }
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { staticClass: "py-2", attrs: { elevation: "0" } },
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(" Dokumente "),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-0",
                  attrs: { color: "red", icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = !_vm.dialog
                    },
                  },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _vm.$settings.getValue("cometConnection.client") ||
              !_vm.$permGuard.chP("super_admin")
                ? [
                    _c(
                      "v-row",
                      { staticClass: "px-5" },
                      [
                        _vm.$settings.getValue("mail.acquisition.active") &&
                        _vm.$settings.getValue("status.acquisition.enabled")
                          ? _c(
                              "v-col",
                              [
                                _vm.$settings.getValue("mail.active")
                                  ? [
                                      _c("v-hover", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var hover = ref.hover
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "d-block mx-auto mt-5 mb-3",
                                                      attrs: {
                                                        color: "info",
                                                        fab: "",
                                                        depressed: "",
                                                        "x-large": "",
                                                        loading:
                                                          _vm.loadingAcquisitionMail,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.mailAcquisition,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { large: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              hover
                                                                ? "send"
                                                                : "local_offer"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          886354986
                                        ),
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-center text-h6 my-0",
                                        },
                                        [_vm._v("Akquise")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "d-block mx-auto mt-1 mb-5",
                                          attrs: {
                                            color: "red",
                                            depressed: "",
                                            rounded: "",
                                            text: "",
                                            loading:
                                              _vm.loadingAcquisitionPreview,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.previewAcquisition(
                                                true
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("picture_as_pdf"),
                                          ]),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-caption ml-1 grey--text",
                                            },
                                            [_vm._v("Download")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : [
                                      _c("v-hover", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var hover = ref.hover
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "d-block mx-auto mt-5 mb-3",
                                                      attrs: {
                                                        color: "info",
                                                        fab: "",
                                                        depressed: "",
                                                        "x-large": "",
                                                        loading:
                                                          _vm.loadingAcquisitionPreview,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.previewAcquisition(
                                                            true
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { large: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              hover
                                                                ? "download"
                                                                : "picture_as_pdf"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2954922004
                                        ),
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-center text-h6 my-0",
                                        },
                                        [_vm._v("Akquise")]
                                      ),
                                    ],
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.$settings.getValue("mail.confirmation.active")
                          ? _c(
                              "v-col",
                              [
                                _vm.$settings.getValue("mail.active")
                                  ? [
                                      _c("v-hover", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var hover = ref.hover
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "d-block mx-auto mt-5 mb-3",
                                                      attrs: {
                                                        color: "lightgrey",
                                                        fab: "",
                                                        depressed: "",
                                                        "x-large": "",
                                                        loading:
                                                          _vm.loadingConfirmationMail,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.mailConfirmation,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            "x-large": "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              hover
                                                                ? "send"
                                                                : "history_edu"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2538339813
                                        ),
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-center text-h6 my-0",
                                        },
                                        [_vm._v("Freigabe")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "d-block mx-auto mt-1 mb-5",
                                          attrs: {
                                            color: "red",
                                            depressed: "",
                                            rounded: "",
                                            text: "",
                                            loading:
                                              _vm.loadingConfirmationPreview,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.previewConfirmation(
                                                true
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("picture_as_pdf"),
                                          ]),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-caption ml-1 grey--text",
                                            },
                                            [_vm._v("Download")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : [
                                      _c("v-hover", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var hover = ref.hover
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "d-block mx-auto mt-5 mb-3",
                                                      attrs: {
                                                        color: "grey lighten-2",
                                                        fab: "",
                                                        depressed: "",
                                                        "x-large": "",
                                                        loading:
                                                          _vm.loadingConfirmationPreview,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.previewConfirmation(
                                                            true
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { large: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              hover
                                                                ? "download"
                                                                : "picture_as_pdf"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2782517753
                                        ),
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-center text-h6 my-0",
                                        },
                                        [_vm._v("Freigabe")]
                                      ),
                                    ],
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.$settings.getValue("mail.active")
                      ? [
                          _c("v-combobox", {
                            staticClass: "mt-5",
                            attrs: {
                              placeholder: "Bitte E-Mail Adresse wählen...",
                              label: "Empfängeradresse",
                              items: _vm.mails,
                              "item-value": "address",
                              "item-text": "address",
                              autocomplete: "off",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "no-data",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  "Keine E-Mail Adressen gefunden."
                                                ),
                                              ]),
                                              _c("v-list-item-subtitle", [
                                                _vm._v(
                                                  "Bitte manuell eine E-Mail-Adresse eingeben."
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "item",
                                  fn: function (ref) {
                                    var index = ref.index
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(item.address)),
                                          ]),
                                          _c("v-list-item-subtitle", [
                                            _vm._v(_vm._s(item.source)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1265338208
                            ),
                            model: {
                              value: _vm.mailTo,
                              callback: function ($$v) {
                                _vm.mailTo = $$v
                              },
                              expression: "mailTo",
                            },
                          }),
                          _vm.$store.getters["auth/getUser"].mail
                            ? _c("v-checkbox", {
                                attrs: {
                                  label:
                                    "Blindkopie an mich (" +
                                    _vm.$store.getters["auth/getUser"].mail +
                                    ") senden?",
                                  color: "success",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.sendBcc,
                                  callback: function ($$v) {
                                    _vm.sendBcc = $$v
                                  },
                                  expression: "sendBcc",
                                },
                              })
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ]
                : [
                    _c("p", { staticClass: "mt-5 ml-4" }, [
                      _c("span", { staticClass: "text-h6" }, [
                        _vm._v("Dokumente können nicht generiert werden."),
                      ]),
                      _c("br"),
                      _c(
                        "span",
                        { staticClass: "error--text font-weight-bold" },
                        [_vm._v("FEHLER")]
                      ),
                      _vm._v(" Comet-Client noch nicht konfiguriert."),
                      _c("br"),
                    ]),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }