var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            app: "",
            dark: "",
            color: _vm.appMode !== "pp" ? "black" : "secondary",
          },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "ml-1 mr-4",
              attrs: {
                fab: "",
                small: "",
                depressed: "",
                color: "transparent",
              },
              on: {
                click: function ($event) {
                  _vm.drawer = !_vm.drawer
                },
              },
            },
            [_c("v-icon", [_vm._v("menu")])],
            1
          ),
          _vm.currentProject.name && !_vm.drawer
            ? _c("span", { staticClass: "caption mr-2" }, [
                _vm._v(_vm._s("" + _vm.currentProject.name)),
              ])
            : _vm._e(),
          _vm.currentProject.name && _vm.channelName && !_vm.drawer
            ? _c("span", { staticClass: "mr-2" }, [_vm._v("/")])
            : _vm._e(),
          _vm.channelName
            ? _c("span", { staticClass: "caption" }, [
                _vm._v(_vm._s("" + _vm.channelName)),
              ])
            : _vm._e(),
          _c("v-spacer"),
          !_vm.dark
            ? _c("v-img", {
                staticClass: "mr-3",
                attrs: {
                  src: "/img/logo_star_gold.svg",
                  "max-height": "26px",
                  "max-width": "26px",
                },
              })
            : _vm._e(),
          _vm.dark
            ? _c(
                "v-icon",
                { staticClass: "mr-3", attrs: { color: "blue darken-2" } },
                [_vm._v(" bedtime ")]
              )
            : _vm._e(),
          _c("v-switch", {
            staticClass: "mr-5 mt-5",
            attrs: {
              autocomplete: "off",
              inset: "",
              color: _vm.dark ? "blue" : "white",
            },
            model: {
              value: _vm.dark,
              callback: function ($$v) {
                _vm.dark = $$v
              },
              expression: "dark",
            },
          }),
          _vm.$settings.getUserSettings("loaded")
            ? _c("TicketSystem")
            : _vm._e(),
          _vm.$settings.getUserSettings("loaded") ? _c("Changelog") : _vm._e(),
          _c("Notifications"),
          _vm.user.actingAs
            ? _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { fab: "", small: "", depressed: "", color: "error" },
                  on: {
                    click: function ($event) {
                      return _vm.$store.dispatch("auth/actAs", { userID: "" })
                    },
                  },
                },
                [_c("v-icon", [_vm._v("person_off")])],
                1
              )
            : _vm.user
            ? _c("v-avatar", { staticClass: "mr-4", attrs: { size: "40" } }, [
                _c("img", {
                  attrs: { src: _vm.user.profile_img, alt: "profile image" },
                }),
              ])
            : _vm._e(),
          _vm.user.actingAs
            ? _c(
                "span",
                { staticClass: "text-button mr-3 d-none d-md-block" },
                [_vm._v('"' + _vm._s(_vm.user.actingAs.name) + '"')]
              )
            : _vm.user
            ? _c(
                "span",
                { staticClass: "text-button mr-3 d-none d-md-block" },
                [_vm._v(_vm._s(_vm.user.name))]
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              attrs: { fab: "", small: "", text: "" },
              on: {
                click: function ($event) {
                  return _vm.$store.dispatch("auth/logout")
                },
              },
            },
            [_c("v-icon", [_vm._v("logout")])],
            1
          ),
          _vm.$store.getters["user/getPermissionsStatus"] === "loading" ||
          _vm.$store.getters["user/getSettingsStatus"] === "loading"
            ? _c("v-progress-linear", {
                staticStyle: { position: "absolute", bottom: "0", left: "0" },
                attrs: { indeterminate: "" },
              })
            : _vm._e(),
        ],
        1
      ),
      this.$route.matched.some(function (ref) {
        var name = ref.name

        return name === "Admin"
      })
        ? _c(
            "v-navigation-drawer",
            {
              attrs: { app: "" },
              model: {
                value: _vm.drawer,
                callback: function ($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer",
              },
            },
            [
              _c("v-list", { staticClass: "pt-0" }, [
                _c("div", { staticClass: "app-logo-container" }, [
                  _c(
                    "div",
                    { staticClass: "application-switch py-4 pl-2" },
                    [
                      _c("v-menu", {
                        attrs: {
                          bottom: "",
                          "offset-x": true,
                          "min-width": "200",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "py-4 px-a",
                                          attrs: {
                                            fab: "",
                                            icon: "",
                                            small: "",
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("shield")])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1990393113
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "app-logo my-auto" },
                    [
                      _c("v-img", {
                        staticClass: "ml-3 we2pLogo",
                        attrs: {
                          contain: "",
                          position: "left center",
                          src: _vm.logoURL,
                          "max-width": "130",
                          "max-height": "30",
                        },
                      }),
                      _c("p", { staticClass: "text-body-1 ml-4 mb-0" }, [
                        _vm._v("Administration"),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "v-list",
                _vm._l(_vm.adminNav, function (item, index) {
                  return _c(
                    "v-list-item",
                    {
                      key: index,
                      staticClass: "text-decoration-none",
                      attrs: {
                        item: item,
                        index: index,
                        router: "",
                        to: "/admin" + item.path,
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", {
                            domProps: { textContent: _vm._s(item.icon) },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", {
                            domProps: { textContent: _vm._s(item.name) },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              false
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ma-5",
                      attrs: { rounded: "", color: "error" },
                      on: { click: _vm.throwError },
                    },
                    [_vm._v(" dont click me ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : this.$route.matched.some(function (ref) {
            var name = ref.name

            return name === "Graph"
          })
        ? _c(
            "v-navigation-drawer",
            {
              attrs: { app: "" },
              model: {
                value: _vm.drawer,
                callback: function ($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer",
              },
            },
            [
              _c(
                "v-list",
                { staticClass: "pt-0" },
                [
                  _c("div", { staticClass: "app-logo-container" }, [
                    _c(
                      "div",
                      { staticClass: "application-switch py-4 pl-2" },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "min-width": "250",
                              "offset-y": "",
                              transition: "scroll-x-transition",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "py-4 px-a",
                                            attrs: {
                                              fab: "",
                                              icon: "",
                                              small: "",
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("apps")])],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "v-list",
                              [
                                _vm.getAppSwitchEnv("manual", "hide") === "n"
                                  ? _c(
                                      "v-list-item",
                                      {
                                        attrs: {
                                          router: "",
                                          href: _vm.getAppSwitchEnv(
                                            "manual",
                                            "url"
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-avatar",
                                          { attrs: { color: "secondary" } },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "white" } },
                                              [_vm._v("help")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.getAppSwitchEnv(
                                                    "manual",
                                                    "name"
                                                  )
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getAppSwitchEnv("cockpit", "hide") === "n"
                                  ? _c(
                                      "v-list-item",
                                      {
                                        attrs: {
                                          router: "",
                                          href: _vm.getAppSwitchEnv(
                                            "cockpit",
                                            "url"
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-avatar",
                                          { attrs: { color: "primary" } },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "white" } },
                                              [_vm._v("account_tree ")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.getAppSwitchEnv(
                                                    "cockpit",
                                                    "name"
                                                  )
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getAppSwitchEnv(
                                  "mediacollection",
                                  "hide"
                                ) === "n"
                                  ? _c(
                                      "v-list-item",
                                      {
                                        attrs: {
                                          router: "",
                                          href: _vm.getAppSwitchEnv(
                                            "mediacollection",
                                            "url"
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-avatar",
                                          { attrs: { color: "secondary" } },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "white" } },
                                              [_vm._v("collections ")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.getAppSwitchEnv(
                                                    "mediacollection",
                                                    "name"
                                                  )
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "app-logo my-auto" },
                      [
                        _c("v-img", {
                          staticClass: "ml-3 we2pLogo",
                          attrs: {
                            contain: "",
                            position: "left center",
                            src: _vm.logoURL,
                            "max-width": "130",
                            "max-height": "30",
                          },
                        }),
                        _c("p", { staticClass: "text-body-1 ml-4 mb-0" }, [
                          _vm._v("Knowledge Graph"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("v-autocomplete", {
                    staticClass: "mx-4",
                    attrs: {
                      items: _vm.$store.getters["projects/getAll"],
                      loading:
                        _vm.$store.getters["projects/getStatus"] === "loading",
                      "item-text": "name",
                      "item-value": "id",
                      "menu-props": { bottom: true, offsetY: true },
                      label: "Projekt",
                      "hide-details": "",
                      "return-object": "",
                    },
                    model: {
                      value: _vm.currentProject,
                      callback: function ($$v) {
                        _vm.currentProject = $$v
                      },
                      expression: "currentProject",
                    },
                  }),
                  _vm.currentProject !== undefined
                    ? _c(
                        "v-list-item-group",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "v-list-item",
                            {
                              staticClass: "text-decoration-none",
                              attrs: {
                                router: "",
                                to:
                                  "/projects/" +
                                  _vm.currentProject.id +
                                  "/channels",
                                exact: true,
                                color: "blue",
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", {
                                    staticStyle: { transform: "rotate(90deg)" },
                                    domProps: {
                                      textContent: _vm._s("call_split"),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", {
                                    domProps: {
                                      textContent: _vm._s("Alle Channels"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-navigation-drawer",
            {
              attrs: { app: "" },
              model: {
                value: _vm.drawer,
                callback: function ($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer",
              },
            },
            [
              _c(
                "v-list",
                { staticClass: "pt-0" },
                [
                  _c("div", { staticClass: "app-logo-container" }, [
                    _c(
                      "div",
                      { staticClass: "application-switch py-4 pl-2" },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: { "min-width": "250", "offset-y": "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "py-4 px-a",
                                            attrs: {
                                              fab: "",
                                              icon: "",
                                              small: "",
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("apps")])],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "v-list",
                              [
                                _vm.getAppSwitchEnv("manual", "hide") === "n"
                                  ? _c(
                                      "v-list-item",
                                      {
                                        attrs: {
                                          router: "",
                                          href: _vm.getAppSwitchEnv(
                                            "manual",
                                            "url"
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-avatar",
                                          { attrs: { color: "secondary" } },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "white" } },
                                              [_vm._v("help")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.getAppSwitchEnv(
                                                    "manual",
                                                    "name"
                                                  )
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getAppSwitchEnv("cockpit", "hide") === "n"
                                  ? _c(
                                      "v-list-item",
                                      {
                                        attrs: {
                                          router: "",
                                          href: _vm.getAppSwitchEnv(
                                            "cockpit",
                                            "url"
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-avatar",
                                          { attrs: { color: "primary" } },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "white" } },
                                              [_vm._v("account_tree ")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.getAppSwitchEnv(
                                                    "cockpit",
                                                    "name"
                                                  )
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getAppSwitchEnv(
                                  "mediacollection",
                                  "hide"
                                ) === "n"
                                  ? _c(
                                      "v-list-item",
                                      {
                                        attrs: {
                                          router: "",
                                          href: _vm.getAppSwitchEnv(
                                            "mediacollection",
                                            "url"
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-avatar",
                                          { attrs: { color: "secondary" } },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "white" } },
                                              [_vm._v("collections ")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.getAppSwitchEnv(
                                                    "mediacollection",
                                                    "name"
                                                  )
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "app-logo my-auto",
                        staticStyle: { width: "0" },
                      },
                      [
                        _c("v-img", {
                          staticClass:
                            "mt-3 mb-1 ml-2 mr-5 we2pLogo h-100 w-100",
                          attrs: {
                            contain: "",
                            position: "left center",
                            src: _vm.logoURL,
                          },
                        }),
                        _c("p", { staticClass: "text-body-1 ml-4 mb-0" }, [
                          _vm._v("Publication Planner"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("v-autocomplete", {
                    staticClass: "mx-4",
                    attrs: {
                      items: _vm.$store.getters["projects/getAll"],
                      loading:
                        _vm.$store.getters["projects/getStatus"] === "loading",
                      "item-text": "name",
                      "item-value": "id",
                      "menu-props": { bottom: true, offsetY: true },
                      label: "Projekt",
                      "hide-details": "",
                      "return-object": "",
                    },
                    model: {
                      value: _vm.currentProject,
                      callback: function ($$v) {
                        _vm.currentProject = $$v
                      },
                      expression: "currentProject",
                    },
                  }),
                  _vm.currentProject !== undefined
                    ? _c(
                        "v-list-item-group",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "v-list-item",
                            {
                              staticClass: "text-decoration-none",
                              attrs: {
                                router: "",
                                to:
                                  "/projects/" +
                                  _vm.currentProject.id +
                                  "/channels",
                                exact: true,
                                color: "blue",
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", {
                                    staticStyle: { transform: "rotate(90deg)" },
                                    domProps: {
                                      textContent: _vm._s("call_split"),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", {
                                    domProps: {
                                      textContent: _vm._s("Alle Channels"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._l(_vm.menus, function (menuGroup) {
                return [
                  _vm.isPath(menuGroup.path)
                    ? _c(
                        "div",
                        { key: menuGroup.path },
                        [
                          _c("v-divider"),
                          _c(
                            "v-list",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-list-item-group",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass: "text-decoration-none",
                                      attrs: {
                                        color: menuGroup.color,
                                        router: "",
                                        to:
                                          "/projects/" +
                                          _vm.$router.currentRoute.params
                                            .projectID +
                                          "/channels/" +
                                          _vm.$router.currentRoute.params
                                            .channelID +
                                          "/" +
                                          menuGroup.segment,
                                        exact: true,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(menuGroup.home.icon)),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              _vm._s(menuGroup.home.label) + " "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list",
                            [
                              _vm._l(
                                menuGroup.content.filter(function (content) {
                                  return _vm.$permGuard.chP(content.perm)
                                }),
                                function (item, index) {
                                  return [
                                    item.condition
                                      ? _c(
                                          "v-list-item",
                                          {
                                            key: index,
                                            staticClass: "text-decoration-none",
                                            attrs: {
                                              item: item,
                                              index: index,
                                              color: menuGroup.color,
                                              router: "",
                                              to:
                                                "/projects/" +
                                                _vm.$router.currentRoute.params
                                                  .projectID +
                                                "/channels/" +
                                                _vm.$router.currentRoute.params
                                                  .channelID +
                                                "/" +
                                                menuGroup.segment +
                                                item.path,
                                              exact: true,
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-icon",
                                              [
                                                _c("v-icon", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.icon
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.name
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
      _vm.drawer &&
      _vm.appMode !== "admin" &&
      _vm.$permGuard.hasPermission("admin.access_backend")
        ? _c(
            "v-btn",
            {
              staticStyle: { "z-index": "6" },
              attrs: {
                bottom: "",
                left: "",
                depressed: "",
                fixed: "",
                text: "",
                router: "",
                to: "/admin/users",
              },
            },
            [
              _c("v-icon", { staticClass: "mr-2" }, [_vm._v("settings")]),
              _vm._v(" Admin "),
            ],
            1
          )
        : _vm._e(),
      _vm.drawer && _vm.appMode === "admin"
        ? _c(
            "v-btn",
            {
              staticStyle: { "z-index": "6" },
              attrs: {
                bottom: "",
                left: "",
                depressed: "",
                fixed: "",
                text: "",
                router: "",
                to: "/projects",
              },
            },
            [
              _c("v-icon", { staticClass: "mr-2" }, [_vm._v("login")]),
              _vm._v(" zum Frontend "),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }