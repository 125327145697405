var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "1000", persistent: "", scrollable: "" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (scope) {
              return [_vm._t("activator", null, null, scope)]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "text-h4 mb-3 py-5" },
            [
              _c("span", [_vm._v("Konfiguration")]),
              _c("v-spacer"),
              !_vm.create
                ? [
                    !_vm.unlockCreateFields
                      ? _c("DeleteConfirmation", {
                          attrs: {
                            title: "Wirklich entsperren?",
                            msg: "Name und Typ eines Datenfelds sollten nur geändert werden, wenn noch nichts gepfelgt wurde. Falls bereits Einträge gepfelgt wurden, bitte vor der Konvertierung mit einem Programmierer besprechen.",
                          },
                          on: {
                            yes: function ($event) {
                              _vm.unlockCreateFields = true
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var attrs = ref.attrs
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { text: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("lock")])],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2521558399
                          ),
                        })
                      : _vm._e(),
                    _vm.unlockCreateFields
                      ? _c(
                          "v-btn",
                          {
                            attrs: { text: "" },
                            on: {
                              click: function ($event) {
                                _vm.unlockCreateFields = false
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("lock_open")])],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "v-card-text",
            { staticStyle: { "max-height": "90vh" } },
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c("v-select", {
                    staticStyle: { "max-width": "300px" },
                    attrs: {
                      items: _vm.propertyTypes,
                      "item-text": "label",
                      "item-value": "value",
                      label: "Datenfeld Typ",
                      rules: _vm.typeRules,
                      disabled: _vm.isLocked,
                    },
                    model: {
                      value: _vm.prop.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.prop, "type", $$v)
                      },
                      expression: "prop.type",
                    },
                  }),
                  _vm.isDataComponent
                    ? _c("v-text-field", {
                        attrs: {
                          label: "Name",
                          rules: _vm.nameRules,
                          "prepend-icon": "code",
                          disabled: _vm.isLocked,
                        },
                        model: {
                          value: _vm.prop.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.prop, "name", $$v)
                          },
                          expression: "prop.name",
                        },
                      })
                    : _vm._e(),
                  _vm.isDataComponent
                    ? _c("v-checkbox", {
                        attrs: {
                          label: "In Maske ausblenden",
                          "prepend-icon": _vm.prop.hidden
                            ? "visibility_off"
                            : "visibility",
                        },
                        model: {
                          value: _vm.prop.hidden,
                          callback: function ($$v) {
                            _vm.$set(_vm.prop, "hidden", $$v)
                          },
                          expression: "prop.hidden",
                        },
                      })
                    : _vm._e(),
                  _c("v-text-field", {
                    attrs: { label: "Label", "prepend-icon": "edit" },
                    model: {
                      value: _vm.prop.label,
                      callback: function ($$v) {
                        _vm.$set(_vm.prop, "label", $$v)
                      },
                      expression: "prop.label",
                    },
                  }),
                  _c("v-textarea", {
                    attrs: {
                      label: "Beschreibung",
                      "prepend-icon": "info",
                      rows: "1",
                      "auto-grow": "",
                    },
                    model: {
                      value: _vm.prop.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.prop, "description", $$v)
                      },
                      expression: "prop.description",
                    },
                  }),
                  _vm.isDataComponent
                    ? _c(
                        "div",
                        [
                          (
                            (
                              _vm.validators.find(function (v) {
                                return v.componentType === _vm.prop.type
                              }) || {}
                            ).validators || []
                          ).length > 0
                            ? _c("v-autocomplete", {
                                staticStyle: { "max-width": "500px" },
                                attrs: {
                                  "prepend-icon": "rule",
                                  "deletable-chips": "",
                                  chips: "",
                                  multiple: "",
                                  items: (
                                    _vm.validators.find(function (v) {
                                      return v.componentType === _vm.prop.type
                                    }) || {}
                                  ).validators,
                                  "item-text": "label",
                                  "item-value": "name",
                                  label: "Validierung",
                                },
                                model: {
                                  value: _vm.prop.validators,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.prop, "validators", $$v)
                                  },
                                  expression: "prop.validators",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" ´ "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isDataComponent
                    ? [
                        _c(
                          "div",
                          [
                            _c("v-autocomplete", {
                              staticStyle: { "max-width": "500px" },
                              attrs: {
                                "prepend-icon": "link",
                                clearable: "",
                                "auto-select-first": "",
                                items: _vm.getLinkProps,
                                filter: _vm.customFilter,
                                "item-text": "name",
                                loading:
                                  _vm.$store.getters["properties/getStatus"] ===
                                  "loading",
                                "item-value": "name",
                                label: "Verlinkte Knowledge Graph Daten",
                              },
                              model: {
                                value: _vm.prop.linkName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.prop, "linkName", $$v)
                                },
                                expression: "prop.linkName",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.prop.linkName
                          ? _c(
                              "div",
                              { staticClass: "ml-5" },
                              [
                                _c("p", { staticClass: "mb-0 text-caption" }, [
                                  _vm._v("Sync-Richtung"),
                                ]),
                                _c(
                                  "v-btn-toggle",
                                  {
                                    attrs: { mandatory: "" },
                                    model: {
                                      value: _vm.prop.linkLock,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.prop, "linkLock", $$v)
                                      },
                                      expression: "prop.linkLock",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      { attrs: { title: "beide" } },
                                      [_c("v-icon", [_vm._v("swap_vert")])],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      { attrs: { title: "nur Graph -> PP" } },
                                      [_c("v-icon", [_vm._v("south")])],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      { attrs: { title: "nur PP -> Graph" } },
                                      [_c("v-icon", [_vm._v("north")])],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      { attrs: { title: "Sync sperren" } },
                                      [_c("v-icon", [_vm._v("lock")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _c("v-divider", { staticClass: "mb-5 mt-2" }),
                  typeof _vm.prop.config !== "undefined"
                    ? _c("div", [
                        _vm.prop.type === "info"
                          ? _c(
                              "div",
                              [
                                _c("v-subheader", [_vm._v("Farbe")]),
                                _c("v-color-picker", {
                                  staticClass: "mx-5",
                                  attrs: {
                                    mode: _vm.colorPickerMode,
                                    "dot-size": "25",
                                    "hide-mode-switch": "",
                                    "swatches-max-height": "200",
                                    "show-swatches": "",
                                    swatches: _vm.colorPickerSwatches,
                                  },
                                  on: {
                                    "update:mode": function ($event) {
                                      _vm.colorPickerMode = $event
                                    },
                                  },
                                  model: {
                                    value: _vm.prop.config.color,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.prop.config, "color", $$v)
                                    },
                                    expression: "prop.config.color",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.prop.type === "textarea"
                          ? _c(
                              "div",
                              [
                                _c("v-subheader", [
                                  _vm._v("Max. Anzahl Zeichen"),
                                ]),
                                _c("v-text-field", {
                                  staticClass: "my-0 py-0 ml-4",
                                  attrs: { dense: "", type: "number" },
                                  model: {
                                    value: _vm.prop.config.charCount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.prop.config,
                                        "charCount",
                                        $$v
                                      )
                                    },
                                    expression: "prop.config.charCount",
                                  },
                                }),
                                _c("v-subheader", [
                                  _vm._v("Initiale Anzahl Zeilen"),
                                ]),
                                _c("v-text-field", {
                                  staticClass: "my-0 py-0 ml-4",
                                  attrs: { dense: "", type: "number" },
                                  model: {
                                    value: _vm.prop.config.rowCount,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.prop.config, "rowCount", $$v)
                                    },
                                    expression: "prop.config.rowCount",
                                  },
                                }),
                                _c("v-subheader", [
                                  _vm._v("Dynmaische Anpassung der Höhe"),
                                ]),
                                _c("v-switch", {
                                  staticClass: "my-0 py-0 ml-4",
                                  attrs: { dense: "", color: "success" },
                                  model: {
                                    value: _vm.prop.config.autoGrow,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.prop.config, "autoGrow", $$v)
                                    },
                                    expression: "prop.config.autoGrow",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.prop.type === "table"
                          ? _c(
                              "div",
                              [
                                _c("v-subheader", [_vm._v("we2p® Types:")]),
                                _c("v-text-field", {
                                  staticClass: "my-0 py-0 ml-4",
                                  attrs: {
                                    dense: "",
                                    "persistent-hint": "",
                                    hint: "Es werden nur Datensätze mit diesen Types (oder deren Subtypes) in das Datenfeld importiert.",
                                  },
                                  model: {
                                    value: _vm.prop.config.types,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.prop.config, "types", $$v)
                                    },
                                    expression: "prop.config.types",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.prop.type === "enumeration"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-subheader", [
                                          _vm._v(
                                            "Abweichender Name im XML Export"
                                          ),
                                        ]),
                                        _c("v-text-field", {
                                          staticClass: "my-0 py-0 ml-4",
                                          attrs: { dense: "" },
                                          model: {
                                            value:
                                              _vm.prop.config
                                                .alternativeNodeName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.prop.config,
                                                "alternativeNodeName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "prop.config.alternativeNodeName",
                                          },
                                        }),
                                        _c("v-subheader", [
                                          _vm._v("Piktogramm-Schriftart"),
                                        ]),
                                        _c("v-autocomplete", {
                                          staticClass: "my-0 py-0 ml-4",
                                          staticStyle: { "max-width": "280px" },
                                          attrs: {
                                            items: _vm.pictoFonts,
                                            dense: "",
                                          },
                                          model: {
                                            value: _vm.prop.config.pictoFont,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.prop.config,
                                                "pictoFont",
                                                $$v
                                              )
                                            },
                                            expression: "prop.config.pictoFont",
                                          },
                                        }),
                                        _c("v-subheader", [
                                          _vm._v("Mehrfach-Auswahl zulässig"),
                                        ]),
                                        _c("v-switch", {
                                          staticClass: "my-0 py-0 ml-4",
                                          staticStyle: { "max-width": "200px" },
                                          attrs: {
                                            dense: "",
                                            color: "success",
                                          },
                                          model: {
                                            value: _vm.prop.config.multiSelect,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.prop.config,
                                                "multiSelect",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "prop.config.multiSelect",
                                          },
                                        }),
                                        _c("v-subheader", [
                                          _vm._v(
                                            "Mögliche Werte für Enumeration"
                                          ),
                                        ]),
                                        _c("v-virtual-scroll", {
                                          attrs: {
                                            items: _vm.prop.config.values,
                                            height: "500",
                                            "item-height": "132",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  var index = ref.index
                                                  return [
                                                    _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "-20px",
                                                                  "margin-right":
                                                                    "-20px",
                                                                },
                                                                attrs: {
                                                                  color:
                                                                    "error",
                                                                  depressed: "",
                                                                  fab: "",
                                                                  "x-small": "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.deleteEnumValue(
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "delete_forever"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                staticClass:
                                                                  "ma-0 pa-0",
                                                                attrs: {
                                                                  dense: "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        attrs: {
                                                                          dense:
                                                                            "",
                                                                          label:
                                                                            "Wert",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.value,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "value",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "item.value",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        attrs: {
                                                                          dense:
                                                                            "",
                                                                          label:
                                                                            "Label",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item
                                                                              .label[
                                                                              "de-DE"
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item.label,
                                                                                "de-DE",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "item.label['de-DE']",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      sm: "2",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        attrs: {
                                                                          dense:
                                                                            "",
                                                                          label:
                                                                            "HEX Unicode",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "prepend",
                                                                                fn: function () {
                                                                                  return [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        style:
                                                                                          "font-size: 24px;transform: translateY(-5px); font-family: '" +
                                                                                          _vm.currentPictoFont +
                                                                                          "';",
                                                                                        domProps:
                                                                                          {
                                                                                            innerHTML:
                                                                                              _vm._s(
                                                                                                _vm.getUnicodeEntity(
                                                                                                  item.unicode
                                                                                                )
                                                                                              ),
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                                proxy: true,
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                        model: {
                                                                          value:
                                                                            item.unicode,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "unicode",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "item.unicode",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm
                                                              .availableLanguages
                                                              .length > 1 &&
                                                            _vm.currentLanguage
                                                              ? _c(
                                                                  "v-row",
                                                                  {
                                                                    staticClass:
                                                                      "ma-0 pa-0",
                                                                    attrs: {
                                                                      dense: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        staticClass:
                                                                          "text-right",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "LangSwitch",
                                                                          {
                                                                            staticClass:
                                                                              "ml-auto",
                                                                            attrs:
                                                                              {
                                                                                dense: true,
                                                                                available:
                                                                                  _vm.availableLanguages,
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm.currentLanguage,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.currentLanguage =
                                                                                      $$v
                                                                                  },
                                                                                expression:
                                                                                  "currentLanguage",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                dense:
                                                                                  "",
                                                                                label:
                                                                                  "Label (" +
                                                                                  _vm.currentLanguage +
                                                                                  ")",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  item
                                                                                    .label[
                                                                                    _vm
                                                                                      .currentLanguage
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      item.label,
                                                                                      _vm.currentLanguage,
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "item.label[currentLanguage]",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          sm: "2",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2606029280
                                          ),
                                        }),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "success",
                                              fab: "",
                                              small: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addEnumValue()
                                              },
                                            },
                                          },
                                          [_c("v-icon", [_vm._v("add")])],
                                          1
                                        ),
                                        _vm.prop.linkName
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "ml-3",
                                                attrs: {
                                                  color: "blue",
                                                  fab: "",
                                                  small: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.importEnumValues()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { color: "white" } },
                                                  [_vm._v("download")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "error", text: "" },
                  on: { click: _vm.cancelEditing },
                },
                [_vm._v(" Abbrechen ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "success" },
                  on: { click: _vm.finishEditing },
                },
                [_vm._v(" OK ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }