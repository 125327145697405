var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DeleteConfirmation", {
        attrs: {
          title: "Wiederherstellen?",
          msg: "Wollen Sie diesen Stand wirklich wiederherstellen? Wenn der Stand zu weit zurück liegt, könnte es zu Problemen aufgrund von Inkompatibiltät zur aktuellen Konfiguration kommen.",
        },
        on: { yes: _vm.restore },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [
                _c(
                  "v-btn",
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "ml-3",
                        attrs: {
                          color: "info",
                          depressed: "",
                          dark: "",
                          small: "",
                          fab: "",
                        },
                        on: {
                          mouseover: function ($event) {
                            _vm.tooltip = true
                          },
                          mouseleave: function ($event) {
                            _vm.tooltip = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    on
                  ),
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { right: "", transition: "slide-x-transition" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticStyle: {
                                            transform: "translateX(-1px)",
                                          },
                                        },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v("restore")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v("Version wiederherstellen")])]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }