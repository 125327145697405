var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500", persistent: "" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [
                _vm._t(
                  "default",
                  function () {
                    return [
                      !_vm.hidden
                        ? _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ml-3",
                                  attrs: {
                                    fab: "",
                                    depressed: "",
                                    color: "red",
                                    small: "",
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { color: "white" } }, [
                                _vm._v(" delete "),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  { on: on, attrs: attrs }
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pb-3" },
        [
          _c("v-card-text", { staticClass: "py-5" }, [
            _c("span", { staticClass: "text-h5" }, [
              _vm._v(_vm._s(_vm.title || "Wirklich löschen?")),
            ]),
            _c("br"),
            _c("br"),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.msg) } }),
          ]),
          _c(
            "v-row",
            { staticClass: "py-5 px-5 mx-1" },
            [
              _c(
                "v-btn",
                {
                  ref: "yes",
                  attrs: {
                    color: "success",
                    large: "",
                    rounded: "",
                    depressed: "",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.yes()
                    },
                  },
                },
                [_vm._v(" Ja ")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  ref: "no",
                  attrs: {
                    color: "error",
                    large: "",
                    rounded: "",
                    depressed: "",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.no()
                    },
                  },
                },
                [_vm._v(" Nein ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }