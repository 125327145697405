import { render, staticRenderFns } from "./UserChip.vue?vue&type=template&id=db708be4&scoped=true&"
import script from "./UserChip.vue?vue&type=script&lang=js&"
export * from "./UserChip.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db708be4",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VAvatar,VBtn,VCard,VChip,VIcon,VList,VListItem,VListItemAction,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle,VMenu})


/* hot reload */
if (module.hot) {
  var api = require("/root/print-suite/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('db708be4')) {
      api.createRecord('db708be4', component.options)
    } else {
      api.reload('db708be4', component.options)
    }
    module.hot.accept("./UserChip.vue?vue&type=template&id=db708be4&scoped=true&", function () {
      api.rerender('db708be4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/users/UserChip.vue"
export default component.exports