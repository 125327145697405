var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "text-h6 mb-4 grey--text" }, [
        _vm._v("Templates"),
      ]),
      _c(
        "v-container",
        { staticClass: "my-5" },
        [
          _vm.getAll.length === 0
            ? _c(
                "div",
                { staticClass: "my-5 text-center" },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mb-4 grey--text", attrs: { size: "128" } },
                    [_vm._v("view_quilt")]
                  ),
                  _c("p", { staticClass: "text-h4 my-5" }, [
                    _vm._v("Noch keine Templates."),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.getAll.length > 0
            ? _c(
                "v-card",
                { staticClass: "mb-4", attrs: { flat: "", tile: "" } },
                [
                  _vm._l(_vm.getAll, function (template, index) {
                    return [
                      _c(
                        "v-list-item",
                        {
                          key: template._id,
                          staticStyle: { height: "100px" },
                          attrs: { index: index },
                        },
                        [
                          [
                            _c(
                              "v-list-item-avatar",
                              { attrs: { tile: "", width: "70" } },
                              [
                                _c(
                                  "span",
                                  { staticClass: "grey--text headline" },
                                  [_vm._v(_vm._s(template.id))]
                                ),
                              ]
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", {
                                  domProps: {
                                    textContent: _vm._s(template.name),
                                  },
                                }),
                                _c("v-list-item-subtitle", {
                                  staticClass: "grey--text",
                                  domProps: {
                                    textContent: _vm._s(
                                      (template.imageCount || "Keine") +
                                        " " +
                                        (template.imageCount === 1
                                          ? "Bild"
                                          : "Bilder")
                                    ),
                                  },
                                }),
                                template.price
                                  ? _c("v-list-item-subtitle", [
                                      _vm._v(
                                        "Anzeigenpreis: " +
                                          _vm._s(
                                            parseFloat(template.price)
                                              .toFixed(2)
                                              .toString()
                                              .replace(".", ",")
                                          ) +
                                          " €"
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("v-list-item-subtitle", [
                                  _vm._v(
                                    "Größe: " +
                                      _vm._s(_vm.getSizeDescription(template))
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c("img", {
                              staticStyle: { "max-height": "100%" },
                              attrs: {
                                src:
                                  "https://cc.we2p.de/" +
                                  _vm.$settings.getValue(
                                    "cometConnection.client"
                                  ) +
                                  "/INSTANCE1/pageitems/preview/" +
                                  template.id +
                                  ".gif",
                              },
                            }),
                            _c("v-list-item-action", { staticClass: "ml-10" }, [
                              _c(
                                "div",
                                [
                                  _vm.$permGuard.chP("templates.u")
                                    ? _c("TemplateEdit", {
                                        attrs: { template: template },
                                      })
                                    : _vm._e(),
                                  _vm.$permGuard.chP("templates.d")
                                    ? _c("DeleteConfirmation", {
                                        on: {
                                          yes: function ($event) {
                                            return _vm.removeTemplate(
                                              template._id
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ],
                        ],
                        2
                      ),
                      index < _vm.getAll.length - 1
                        ? _c("v-divider", { key: index })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }