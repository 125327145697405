var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("LabelComponent", {
        attrs: { definition: _vm.definition, "is-locked": _vm.isLocked },
      }),
      _c("v-text-field", {
        attrs: {
          dense: "",
          filled: "",
          readonly: _vm.isLocked,
          type: "number",
          value: _vm.value,
          "hide-details": "auto",
        },
        on: {
          input: function ($event) {
            return _vm.change($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }