var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "mb-5 mt-3" },
        [
          _c("v-text-field", {
            staticStyle: { "max-width": "500px" },
            attrs: {
              label: "Suchen",
              "prepend-inner-icon": "search",
              autofill: "off",
            },
            model: {
              value: _vm.searchTerm,
              callback: function ($$v) {
                _vm.searchTerm = $$v
              },
              expression: "searchTerm",
            },
          }),
          (_vm.channels.length === 0 || _vm.filteredChannels.length === 0) &&
          _vm.$store.getters["channels/getStatus"] !== "loading"
            ? _c(
                "div",
                { staticClass: "my-5 text-center" },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mb-4 grey--text", attrs: { size: "128" } },
                    [_vm._v("menu_book")]
                  ),
                  _c("p", { staticClass: "text-h4 my-5" }, [
                    _vm._v("Keine Channels gefunden."),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.$store.getters["channels/getStatus"] === "loading"
            ? [
                _vm._v(
                  " " + _vm._s(_vm.$store.getters["channels/getStatus"]) + " "
                ),
                _c(
                  "v-row",
                  { staticClass: "mt-5 pt-5" },
                  _vm._l(4, function (index) {
                    return _c(
                      "v-col",
                      {
                        key: index,
                        attrs: {
                          cols: "12",
                          sm: "6",
                          md: "4",
                          lg: "3",
                          xl: "2",
                        },
                      },
                      [
                        _c("v-skeleton-loader", {
                          attrs: { "max-width": "400", type: "card" },
                        }),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ]
            : _vm._e(),
          [
            _vm._l(_vm.channelGroups, function (channelGroup, index) {
              return _c(
                "div",
                { key: index, staticClass: "pt-3" },
                [
                  _vm.filteredChannels.filter(function (x) {
                    return (x.channelType || []).includes(channelGroup.type)
                  }).length > 0
                    ? [
                        _c(
                          "p",
                          { staticClass: "text-h4 font-weight-bold mt-5" },
                          [_vm._v(_vm._s(channelGroup.label))]
                        ),
                        _c(
                          "v-row",
                          _vm._l(
                            _vm.filteredChannels.filter(function (x) {
                              return (x.channelType || []).includes(
                                channelGroup.type
                              )
                            }),
                            function (channel, index) {
                              return _c(
                                "v-col",
                                {
                                  key: channel._id,
                                  attrs: {
                                    item: channel,
                                    index: index,
                                    cols: "12",
                                    sm: "6",
                                    md: "4",
                                    lg: "3",
                                    xl: "2",
                                  },
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      style: channelGroup.active
                                        ? "text-decoration: none"
                                        : "text-decoration: none; pointer-events: none",
                                      attrs: {
                                        router: "",
                                        to: _vm.getLink(channel),
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { attrs: { elevation: "0" } },
                                        [
                                          _c(
                                            "v-img",
                                            {
                                              staticClass:
                                                "white--text align-end",
                                              attrs: {
                                                src:
                                                  "https://webimages.we2p.de/static/pp/" +
                                                  _vm.environment +
                                                  "/channel_" +
                                                  _vm.$router.currentRoute
                                                    .params.projectID +
                                                  "_" +
                                                  channel.id +
                                                  "_thumbnail.jpg",
                                                gradient:
                                                  "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
                                                height: "200px",
                                              },
                                            },
                                            [
                                              _c("v-card-title", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "word-break": "normal",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        (channel.labels || {})[
                                                          "de-DE"
                                                        ].replace("Print: ", "")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c(
                                                "v-btn",
                                                { attrs: { text: "" } },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "subdirectory_arrow_right"
                                                    ),
                                                  ]),
                                                  channelGroup.active
                                                    ? _c("span", [
                                                        _vm._v("öffnen"),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          "nicht verfügbar"
                                                        ),
                                                      ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            _vm.otherChannels.length > 0
              ? _c(
                  "div",
                  [
                    _c("p", { staticClass: "text-h4 font-weight-bold mt-5" }, [
                      _vm._v("Sonstige"),
                    ]),
                    _c(
                      "v-row",
                      _vm._l(_vm.otherChannels, function (channel, index) {
                        return _c(
                          "v-col",
                          {
                            key: channel._id,
                            attrs: {
                              item: channel,
                              index: index,
                              cols: "12",
                              sm: "6",
                              md: "4",
                              lg: "3",
                              xl: "2",
                            },
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticStyle: {
                                  "text-decoration": "none",
                                  "pointer-events": "none",
                                },
                                attrs: { router: "", to: _vm.getLink(channel) },
                              },
                              [
                                _c(
                                  "v-card",
                                  [
                                    _c(
                                      "v-img",
                                      {
                                        staticClass: "white--text align-end",
                                        attrs: {
                                          src:
                                            "https://webimages.we2p.de/static/pp/" +
                                            _vm.environment +
                                            "/channel_" +
                                            _vm.$router.currentRoute.params
                                              .projectID +
                                            "_" +
                                            channel.id +
                                            "_thumbnail.jpg",
                                          gradient:
                                            "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
                                          height: "200px",
                                        },
                                      },
                                      [
                                        _c("v-card-title", [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "word-break": "normal",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  (channel.labels || {})[
                                                    "de-DE"
                                                  ]
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-actions",
                                      [
                                        _c(
                                          "v-btn",
                                          { attrs: { text: "" } },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                "subdirectory_arrow_right"
                                              ),
                                            ]),
                                            _c("span", [
                                              _vm._v("NICHT VERFÜGBAR"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }