var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        [
          [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("v-text-field", {
                  staticClass: "my-4",
                  attrs: { label: "Types", required: "" },
                  model: {
                    value: _vm.types,
                    callback: function ($$v) {
                      _vm.types = $$v
                    },
                    expression: "types",
                  },
                }),
                _c(
                  "v-btn",
                  {
                    staticClass: "white--text",
                    attrs: {
                      loading: _vm.getStatus.searching,
                      disabled: _vm.getStatus.searching,
                      color: "grey",
                    },
                    on: { click: _vm.startSearch },
                  },
                  [
                    _vm._v(" Datensätze in Knowledge Graph suchen "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("search"),
                    ]),
                  ],
                  1
                ),
                _vm.foundCount !== null && !_vm.getStatus.searching
                  ? _c("p", { staticClass: "my-4" }, [
                      _vm._v(_vm._s(_vm.foundCount + " Datensätze gefunden.")),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  [
                    _vm.foundCount !== null &&
                    !_vm.getStatus.writing &&
                    !_vm.getStatus.searching
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "white--text my-4",
                            attrs: {
                              disabled: _vm.getStatus.writing,
                              color: "success",
                            },
                            on: { click: _vm.startWrite },
                          },
                          [
                            _vm._v(" Datensätze übernehmen "),
                            _c("v-icon", { attrs: { right: "", dark: "" } }, [
                              _vm._v("cloud_download"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.getStatus.writing
                  ? _c("v-progress-linear", {
                      staticClass: "mt-4",
                      attrs: {
                        color: "teal",
                        "buffer-value": "0",
                        value:
                          (_vm.getStatus.written / _vm.getEntities.length) *
                          100,
                        stream: "",
                      },
                    })
                  : _vm._e(),
                _vm.getStatus.writing
                  ? _c("p", { staticClass: "text-caption mb-5" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getStatus.written + "/" + _vm.getEntities.length
                        )
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }