var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          staticClass: "mb-5 mt-2",
          attrs: {
            to: {
              name: "EntryEdit",
              params: {
                entryID: this.entry._id.toString(),
              },
            },
            text: "",
            depressed: "",
            router: "",
          },
        },
        [
          _c("v-icon", { staticClass: "mr-3" }, [_vm._v("arrow_back")]),
          _vm._v(" zurück zum Eintrag "),
        ],
        1
      ),
      _c("v-card", { staticClass: "pa-5" }, [
        _vm.loadingEntry || _vm.loadingEntryType || _vm.loadingEntryHistory
          ? _c(
              "div",
              [_c("v-progress-linear", { attrs: { indeterminate: "" } })],
              1
            )
          : _vm._e(),
        !_vm.loadingEntry && !_vm.loadingEntryType && !_vm.loadingEntryHistory
          ? _c(
              "div",
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "text-body-1 grey--text mt-4 mb-0 text-lowercase",
                  },
                  [_vm._v(_vm._s((_vm.entry || {})._id))]
                ),
                _c("p", { staticClass: "text-h3 font-weight-bold mb-0" }, [
                  _vm._v("Verlauf"),
                ]),
                _c("p", { staticClass: "text-h5" }, [
                  _vm._v(_vm._s(_vm.getLabel)),
                ]),
                _c("v-divider"),
                _c(
                  "v-timeline",
                  { attrs: { dense: "" } },
                  _vm._l(_vm.versions, function (version, index) {
                    return _c(
                      "v-timeline-item",
                      {
                        key: index,
                        attrs: {
                          "fill-dot": "",
                          color: version.color,
                          icon: version.icon,
                        },
                      },
                      [
                        _c("div", { staticClass: "py-4" }, [
                          _c(
                            "p",
                            { staticClass: "mb-0" },
                            [
                              _c(
                                "span",
                                { staticClass: "text-h6 font-weight-bold" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.getDateTimeString(version.t)) +
                                      " Uhr"
                                  ),
                                ]
                              ),
                              _c("span", { staticClass: "mx-5" }, [
                                _vm._v("-"),
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    (version.d.lastEditedBy || {}).fullName ||
                                      "Keine Info zum Nutzer verfügbar"
                                  )
                                ),
                              ]),
                              (version.d.lastEditedBy || {}).comment
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "ml-3",
                                                        attrs: {
                                                          color: "success",
                                                          dark: "",
                                                          small: "",
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v("comment")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            (version.d.lastEditedBy || {})
                                              .comment
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          index === 0
                            ? _c(
                                "p",
                                { staticClass: "my-1 green--text" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        left: "",
                                        small: "",
                                        color: "green",
                                      },
                                    },
                                    [_vm._v(" check ")]
                                  ),
                                  _vm._v(" Aktueller Stand "),
                                ],
                                1
                              )
                            : _vm._e(),
                          version.changes
                            ? _c(
                                "ul",
                                {},
                                _vm._l(
                                  version.changes,
                                  function (change, changeIndex) {
                                    return _c("li", {
                                      key: changeIndex,
                                      domProps: { innerHTML: _vm._s(change) },
                                    })
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "mt-5 ml-2" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("EntryHistoryCompare", {
                                    attrs: {
                                      "new-data": version.d,
                                      "old-data": (
                                        _vm.versions[index + 1] || {}
                                      ).d,
                                      "entry-type-definition":
                                        _vm.entryTypeDefinition,
                                    },
                                  }),
                                  index !== 0
                                    ? _c("EntryHistoryRestore", {
                                        attrs: {
                                          "entry-id": _vm.entry._id,
                                          "version-id": version._id,
                                        },
                                        on: { restore: _vm.loadHistory },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
                _vm.displayCount <= _vm.versions.length
                  ? _c(
                      "v-btn",
                      {
                        attrs: { color: "success" },
                        on: {
                          click: function ($event) {
                            _vm.displayCount += 30
                          },
                        },
                      },
                      [_vm._v(" ältere anzeigen... ")]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }