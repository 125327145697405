var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-5", staticStyle: { position: "relative" } },
    [
      _vm.isSyncedMode
        ? [
            _c("div", { staticClass: "kg-background secondary " }),
            _c(
              "v-row",
              { staticClass: "mb-5 mt-4" },
              [
                _c("v-col", { attrs: { cols: 6 } }, [
                  _c("h3", { staticClass: "text-h4 font-weight-bold" }, [
                    _vm._v("Publication Planner"),
                  ]),
                ]),
                _c(
                  "v-col",
                  { staticStyle: { "z-index": "1" }, attrs: { cols: 6 } },
                  [
                    _c("h3", { staticClass: "text-h4 font-weight-bold ml-5" }, [
                      _vm._v("Knowledge Graph"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._l(_vm.definition, function (component, index) {
        return _c(
          "v-row",
          { key: index, staticStyle: { margin: "20px 0" } },
          [
            typeof component !== "undefined" && !component.hidden
              ? [
                  _c(
                    "v-col",
                    {
                      class:
                        !_vm.isMultilang(component) && _vm.hideNonMultilang
                          ? "component-hidden"
                          : "",
                      staticStyle: { "z-index": "1" },
                      attrs: { cols: _vm.isSyncedMode ? 6 : 12 },
                    },
                    [
                      (
                        !_vm.isMultilang(component) && _vm.hideNonMultilang
                          ? "component-hidden"
                          : ""
                      )
                        ? _c("div")
                        : _vm._e(),
                      component.type === "input"
                        ? [
                            _c("InputComponent", {
                              ref: component.name,
                              refInFor: true,
                              attrs: {
                                language: _vm.language,
                                "is-locked": _vm.isLocked,
                                definition: component,
                              },
                              model: {
                                value: _vm.content[component.name],
                                callback: function ($$v) {
                                  _vm.$set(_vm.content, component.name, $$v)
                                },
                                expression: "content[component.name]",
                              },
                            }),
                          ]
                        : component.type === "number"
                        ? [
                            _c("NumberComponent", {
                              ref: component.name,
                              refInFor: true,
                              attrs: {
                                language: _vm.language,
                                definition: component,
                                "is-locked": _vm.isLocked,
                              },
                              model: {
                                value: _vm.content[component.name],
                                callback: function ($$v) {
                                  _vm.$set(_vm.content, component.name, $$v)
                                },
                                expression: "content[component.name]",
                              },
                            }),
                          ]
                        : component.type === "boolean"
                        ? [
                            _c("BooleanComponent", {
                              ref: component.name,
                              refInFor: true,
                              attrs: {
                                language: _vm.language,
                                definition: component,
                                "is-locked": _vm.isLocked,
                              },
                              model: {
                                value: _vm.content[component.name],
                                callback: function ($$v) {
                                  _vm.$set(_vm.content, component.name, $$v)
                                },
                                expression: "content[component.name]",
                              },
                            }),
                          ]
                        : component.type === "textarea"
                        ? [
                            _c("TextareaComponent", {
                              ref: component.name,
                              refInFor: true,
                              attrs: {
                                language: _vm.language,
                                definition: component,
                                "is-locked": _vm.isLocked,
                              },
                              model: {
                                value: _vm.content[component.name],
                                callback: function ($$v) {
                                  _vm.$set(_vm.content, component.name, $$v)
                                },
                                expression: "content[component.name]",
                              },
                            }),
                          ]
                        : component.type === "enumeration"
                        ? [
                            _c("EnumerationComponent", {
                              ref: component.name,
                              refInFor: true,
                              attrs: {
                                language: _vm.language,
                                definition: component,
                                "is-locked": _vm.isLocked,
                              },
                              model: {
                                value: _vm.content[component.name],
                                callback: function ($$v) {
                                  _vm.$set(_vm.content, component.name, $$v)
                                },
                                expression: "content[component.name]",
                              },
                            }),
                          ]
                        : component.type === "address"
                        ? [
                            _c("AddressComponent", {
                              ref: component.name,
                              refInFor: true,
                              attrs: {
                                language: _vm.language,
                                "is-locked": _vm.isLocked,
                                definition: component,
                              },
                              model: {
                                value: _vm.content[component.name],
                                callback: function ($$v) {
                                  _vm.$set(_vm.content, component.name, $$v)
                                },
                                expression: "content[component.name]",
                              },
                            }),
                          ]
                        : component.type === "table"
                        ? [
                            _c("TableComponent", {
                              ref: component.name,
                              refInFor: true,
                              attrs: {
                                language: _vm.language,
                                columns: _vm.columns,
                                definition: component,
                                "synced-mode": false,
                                "is-locked": _vm.isLocked,
                              },
                              model: {
                                value: _vm.content[component.name],
                                callback: function ($$v) {
                                  _vm.$set(_vm.content, component.name, $$v)
                                },
                                expression: "content[component.name]",
                              },
                            }),
                          ]
                        : component.type === "trailEditor"
                        ? [
                            _c("TrailEditorComponent", {
                              attrs: {
                                language: _vm.language,
                                definition: component,
                                entry: _vm.entry,
                                "is-locked": _vm.isLocked,
                              },
                              on: {
                                importItinerary: function ($event) {
                                  return _vm.$emit("importItinerary")
                                },
                              },
                            }),
                          ]
                        : component.type === "headline"
                        ? [
                            _c("HeadlineComponent", {
                              attrs: {
                                language: _vm.language,
                                definition: component,
                              },
                            }),
                          ]
                        : component.type === "divider"
                        ? [
                            _c("DividerComponent", {
                              attrs: {
                                language: _vm.language,
                                definition: component,
                              },
                            }),
                          ]
                        : component.type === "info"
                        ? [
                            _c("InfoComponent", {
                              attrs: {
                                language: _vm.language,
                                definition: component,
                              },
                            }),
                          ]
                        : [
                            _c("LabelComponent", {
                              attrs: { definition: component },
                            }),
                            _c("p", { staticClass: "my-4 error--text" }, [
                              _vm._v(
                                " Keine Render-Definition für " +
                                  _vm._s(component.type) +
                                  " "
                              ),
                            ]),
                          ],
                    ],
                    2
                  ),
                  _vm.isSyncedMode &&
                  component.linkName &&
                  component.type !== "table" &&
                  component.linkName !== "types"
                    ? _c("SyncButtons", {
                        attrs: {
                          "entry-definition": component,
                          "entity-definition": _vm.$store.getters[
                            "properties/getAll"
                          ].find(function (p) {
                            return p.name === component.linkName
                          }),
                          "entity-content":
                            _vm.entityContent[component.linkName],
                        },
                        on: {
                          updateEntity: function ($event) {
                            var _obj
                            _vm.entityContent = Object.assign(
                              {},
                              _vm.entityContent,
                              ((_obj = {}),
                              (_obj[component.linkName] = $event),
                              _obj)
                            )
                          },
                          saveEntity: _vm.saveEntity,
                        },
                        model: {
                          value: _vm.content[component.name],
                          callback: function ($$v) {
                            _vm.$set(_vm.content, component.name, $$v)
                          },
                          expression: "content[component.name]",
                        },
                      })
                    : _vm._e(),
                  _vm.isSyncedMode
                    ? _c(
                        "transition",
                        { attrs: { name: "fade" } },
                        [
                          _vm.$store.getters["properties/getStatus"] ===
                            "loading" && component.linkName
                            ? _c(
                                "v-col",
                                [
                                  _c("v-progress-linear", {
                                    staticClass: "my-auto",
                                    attrs: { indeterminate: "" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          component.linkName &&
                          _vm.$store.getters["properties/getStatus"] !==
                            "loading"
                            ? _c(
                                "v-col",
                                { staticStyle: { "z-index": "1" } },
                                [
                                  _c(
                                    "v-sheet",
                                    {
                                      attrs: { dark: "", color: "transparent" },
                                    },
                                    [
                                      _vm.getCockpitType(component.linkName) ===
                                      "Text"
                                        ? [
                                            _c("CockpitTextComponent", {
                                              ref: "cockpit__" + component.name,
                                              refInFor: true,
                                              attrs: {
                                                value:
                                                  _vm.entityContent[
                                                    component.linkName
                                                  ],
                                                definition: component,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  var _obj
                                                  _vm.entityContent =
                                                    Object.assign(
                                                      {},
                                                      _vm.entityContent,
                                                      ((_obj = {}),
                                                      (_obj[
                                                        component.linkName
                                                      ] = $event),
                                                      _obj)
                                                    )
                                                },
                                              },
                                            }),
                                          ]
                                        : _vm.getCockpitType(
                                            component.linkName
                                          ) === "String"
                                        ? [
                                            _c("CockpitStringComponent", {
                                              ref: "cockpit__" + component.name,
                                              refInFor: true,
                                              attrs: {
                                                value:
                                                  _vm.entityContent[
                                                    component.linkName
                                                  ],
                                                definition: component,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  var _obj
                                                  _vm.entityContent =
                                                    Object.assign(
                                                      {},
                                                      _vm.entityContent,
                                                      ((_obj = {}),
                                                      (_obj[
                                                        component.linkName
                                                      ] = $event),
                                                      _obj)
                                                    )
                                                },
                                              },
                                            }),
                                          ]
                                        : _vm.getCockpitType(
                                            component.linkName
                                          ) === "Enum"
                                        ? [
                                            _c("CockpitEnumerationComponent", {
                                              ref: "cockpit__" + component.name,
                                              refInFor: true,
                                              attrs: {
                                                value:
                                                  _vm.entityContent[
                                                    component.linkName
                                                  ],
                                                definition: component,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  var _obj
                                                  _vm.entityContent =
                                                    Object.assign(
                                                      {},
                                                      _vm.entityContent,
                                                      ((_obj = {}),
                                                      (_obj[
                                                        component.linkName
                                                      ] = $event),
                                                      _obj)
                                                    )
                                                },
                                              },
                                            }),
                                          ]
                                        : _vm.getCockpitType(
                                            component.linkName
                                          ) === "Integer" ||
                                          _vm.getCockpitType(
                                            component.linkName
                                          ) === "Float"
                                        ? [
                                            _c("CockpitNumberComponent", {
                                              ref: "cockpit__" + component.name,
                                              refInFor: true,
                                              attrs: {
                                                value:
                                                  _vm.entityContent[
                                                    component.linkName
                                                  ],
                                                definition: component,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  var _obj
                                                  _vm.entityContent =
                                                    Object.assign(
                                                      {},
                                                      _vm.entityContent,
                                                      ((_obj = {}),
                                                      (_obj[
                                                        component.linkName
                                                      ] = $event),
                                                      _obj)
                                                    )
                                                },
                                              },
                                            }),
                                          ]
                                        : _vm.getCockpitType(
                                            component.linkName
                                          ) === "Boolean"
                                        ? [
                                            _c("CockpitBooleanComponent", {
                                              ref: "cockpit__" + component.name,
                                              refInFor: true,
                                              attrs: {
                                                value:
                                                  _vm.entityContent[
                                                    component.linkName
                                                  ],
                                                definition: component,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  var _obj
                                                  _vm.entityContent =
                                                    Object.assign(
                                                      {},
                                                      _vm.entityContent,
                                                      ((_obj = {}),
                                                      (_obj[
                                                        component.linkName
                                                      ] = $event),
                                                      _obj)
                                                    )
                                                },
                                              },
                                            }),
                                          ]
                                        : _vm.getCockpitType(
                                            component.linkName
                                          ) === "ENTITYLINK" &&
                                          component.linkName === "address"
                                        ? [
                                            _c("CockpitAddressComponent", {
                                              ref: "cockpit__" + component.name,
                                              refInFor: true,
                                              attrs: {
                                                value:
                                                  _vm.entityContent[
                                                    component.linkName
                                                  ],
                                                definition: component,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  var _obj
                                                  _vm.entityContent =
                                                    Object.assign(
                                                      {},
                                                      _vm.entityContent,
                                                      ((_obj = {}),
                                                      (_obj[
                                                        component.linkName
                                                      ] = $event),
                                                      _obj)
                                                    )
                                                },
                                              },
                                            }),
                                          ]
                                        : _vm.getCockpitType(
                                            component.linkName
                                          ) === "ENTITYLINK"
                                        ? [
                                            _c(
                                              "v-alert",
                                              {
                                                staticClass: "ml-5 mt-5",
                                                attrs: { type: "info" },
                                              },
                                              [
                                                _vm._v(
                                                  " Tabellen können nicht live gesynct werden, sie können aber am Ende der Produktion in den Graph-Datenbestand geschrieben werden. Wenden Sie sich dazu an Ihren Projektleiter! "
                                                ),
                                              ]
                                            ),
                                          ]
                                        : component.linkName === "types"
                                        ? [
                                            _c(
                                              "v-alert",
                                              {
                                                staticClass: "ml-5 mt-5",
                                                attrs: { type: "info" },
                                              },
                                              [
                                                _vm._v(
                                                  " Types können nur direkt im Cockpit ergänzt/entfernt werden. "
                                                ),
                                              ]
                                            ),
                                          ]
                                        : [
                                            _c("CockpitLabelComponent", {
                                              attrs: { definition: component },
                                            }),
                                            _c(
                                              "p",
                                              {
                                                staticClass: "my-4 error--text",
                                              },
                                              [
                                                _vm._v(
                                                  " Keine Render-Definition für " +
                                                    _vm._s(
                                                      _vm.getCockpitType(
                                                        component.linkName
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }