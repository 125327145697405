var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.scroll > 400
    ? _c(
        "v-btn",
        {
          key: "1",
          attrs: { fab: "", fixed: "", right: "", bottom: "", color: "gray" },
          on: { click: _vm.scrollUp },
        },
        [_c("v-icon", [_vm._v("arrow_upward")])],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }