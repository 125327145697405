var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("LabelComponent", {
        attrs: { definition: _vm.definition, "is-locked": _vm.isLocked },
      }),
      _c("TrailEditorDialog", {
        attrs: { "is-locked": _vm.isLocked, entry: _vm.entry },
        on: {
          save: function ($event) {
            return _vm.$emit("importItinerary")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }