var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "users" },
    [
      _c("p", { staticClass: "text-h5" }, [_vm._v("Benutzer")]),
      _c("p", { staticClass: "body-1 my-5" }, [
        _vm._v(
          "Benutzer müssen über das Graph-Backend angelegt/gelöscht werden."
        ),
      ]),
      _c("v-text-field", {
        staticClass: "my-4",
        staticStyle: { "max-width": "300px" },
        attrs: {
          "append-icon": "mdi-magnify",
          label: "Suchen",
          "single-line": "",
          "hide-details": "",
        },
        model: {
          value: _vm.search,
          callback: function ($$v) {
            _vm.search = $$v
          },
          expression: "search",
        },
      }),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.$store.getters["users/getAll"],
          "sort-by": "name",
          "items-per-page": 100,
          loading: _vm.$store.getters["users/getStatus"] === "loading",
          search: _vm.search,
        },
        scopedSlots: _vm._u([
          {
            key: "item.controls",
            fn: function (props) {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3 my-2",
                    attrs: {
                      to: "" + props.item.id,
                      fab: "",
                      depressed: "",
                      "x-small": "",
                      router: "",
                      append: "",
                    },
                  },
                  [_c("v-icon", [_vm._v("edit")])],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3 my-2",
                    attrs: {
                      color: "info",
                      fab: "",
                      depressed: "",
                      "x-small": "",
                      title: "Identität übernehmen",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$store.dispatch("auth/actAs", {
                          userID: props.item.id,
                        })
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("badge")])],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }