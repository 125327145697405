var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "padding-bottom": "70px" } },
    [
      !_vm.minimal
        ? _c(
            "v-btn",
            {
              staticClass: "mb-5 mt-2",
              attrs: {
                text: "",
                depressed: "",
                router: "",
                to:
                  "/projects/" +
                  _vm.$router.currentRoute.params.projectID +
                  "/channels/" +
                  _vm.$router.currentRoute.params.channelID +
                  "/print/catalogs/" +
                  _vm.$router.currentRoute.params.catalogID,
              },
            },
            [
              _c("v-icon", { staticClass: "mr-3" }, [_vm._v("arrow_back")]),
              _vm._v(" zurück zur Übersicht "),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "500" },
          model: {
            value: _vm.switchWarnDialog.opened,
            callback: function ($$v) {
              _vm.$set(_vm.switchWarnDialog, "opened", $$v)
            },
            expression: "switchWarnDialog.opened",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Achtung!"),
              ]),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    "Diese Änderung beeinflusst die Datenstruktur. Bitte beachten Sie folgende Konsequenzen:"
                  ),
                ]),
                _c("ol", [
                  _c("li", [_vm._v("Es gehen möglicherweise Daten verloren.")]),
                  _c("li", [
                    _vm._v(
                      "Die Synchronisierung mit dem Knowledge-Graph-Datensatz wird beendet."
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Das Template ist eventuell beim neuen Typ nicht verfügbar."
                    ),
                  ]),
                ]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green", text: "" },
                      on: { click: _vm.resetTypeSwitch },
                    },
                    [_vm._v("zurück")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: {
                        click: function ($event) {
                          _vm.switchWarnDialog.opened = false
                        },
                      },
                    },
                    [_vm._v("Ich weiß, was ich tue!")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "500" },
          model: {
            value: _vm.invalidSaveDialog.opened,
            callback: function ($$v) {
              _vm.$set(_vm.invalidSaveDialog, "opened", $$v)
            },
            expression: "invalidSaveDialog.opened",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Achtung!"),
              ]),
              _c("v-card-text", [
                _c("p", [
                  _vm._v("Einige Felder sind noch nicht gültig ausgefüllt."),
                ]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.invalidSaveDialog.opened = false
                        },
                      },
                    },
                    [_vm._v("zurück")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success" },
                      on: { click: _vm.saveContent },
                    },
                    [_vm._v("Trotzdem speichern")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loadingEntry || _vm.loadingEntryType
        ? _c(
            "div",
            [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
            1
          )
        : _vm._e(),
      !_vm.loadingEntry && !_vm.loadingEntryType
        ? [
            _c("v-sheet", { staticClass: "px-2 pt-0" }, [
              _c(
                "div",
                { staticClass: "mx-4" },
                [
                  _c("v-spacer", { staticClass: "py-3" }),
                  _c("p", { staticClass: "text-body-1 grey--text mt-4 mb-0" }, [
                    _vm._v("ID: " + _vm._s(_vm.entry._id)),
                  ]),
                  _vm.entry.parentID
                    ? _c("p", { staticClass: "text-body-1 grey--text mb-0" }, [
                        _vm._v("Cockpit ID: " + _vm._s(_vm.entry.parentID)),
                      ])
                    : _vm._e(),
                  _c(
                    "p",
                    { staticClass: "text-h3 mt-3" },
                    [
                      _vm._v(" " + _vm._s(_vm.getLabel) + " "),
                      _vm.$settings.getValue("accounting.enabled")
                        ? _c("EntryMetaInfoEdit", {
                            attrs: {
                              template: _vm.templates.find(function (t) {
                                return t._id === _vm.entry.templateID
                              }),
                            },
                            on: { save: _vm.saveMeta },
                            model: {
                              value: _vm.entry.meta,
                              callback: function ($$v) {
                                _vm.$set(_vm.entry, "meta", $$v)
                              },
                              expression: "entry.meta",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-3 my-1",
                          attrs: {
                            depressed: "",
                            rounded: "",
                            color: "orange darken-2",
                            dark: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.scrollToImages()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("image"),
                          ]),
                          _vm._v(" Bilder "),
                        ],
                        1
                      ),
                      _vm.entry.templateID &&
                      !_vm.entryType.disableIXEdit &&
                      !(_vm.entryLanguages.length > 1)
                        ? _c("InDesignEditorDialog", {
                            staticClass: "my-1",
                            attrs: {
                              entry: _vm.entry,
                              "is-locked": _vm.isLocked,
                            },
                            on: { save: _vm.handleIXEditSave },
                          })
                        : _vm._e(),
                      _vm.entry.templateID
                        ? _c("CometPreview", {
                            ref: "cometPreview",
                            attrs: {
                              "entry-id": _vm.entryId,
                              languages: _vm.entryLanguages.map(function (l) {
                                return l.code
                              }),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mr-3 my-1",
                                              attrs: {
                                                rounded: "",
                                                depressed: "",
                                                color: "blue",
                                                dark: "",
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("remove_red_eye")]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.entryLanguages.length > 1
                                                  ? "Gesamtvorschau"
                                                  : "Vorschau"
                                              ) +
                                              " "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              300098619
                            ),
                          })
                        : _vm._e(),
                      _vm.entry.templateID &&
                      ((_vm.$settings.getValue("mail.acquisition.active") &&
                        _vm.$settings.getValue("status.acquisition.enabled")) ||
                        _vm.$settings.getValue("mail.confirmation.active"))
                        ? _c("MailAssistant", {
                            attrs: { entry: _vm.entry },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mr-3 my-1",
                                              attrs: {
                                                rounded: "",
                                                depressed: "",
                                                color: "black lighten-1",
                                                dark: "",
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("mail")]
                                          ),
                                          _vm._v(" Akquisen/Freigaben "),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3079081824
                            ),
                          })
                        : _vm._e(),
                      _vm.entry.parentID
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-3 my-1",
                              attrs: {
                                depressed: "",
                                rounded: "",
                                color: "primary",
                                dark: "",
                                href:
                                  _vm.cockpitAPI +
                                  "/cockpit/frontend/themes/" +
                                  _vm.$router.currentRoute.params.projectID +
                                  "/entity/" +
                                  "thing" +
                                  "/" +
                                  _vm.entry.parentID,
                                target: "_blank",
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("open_in_browser"),
                              ]),
                              _vm._v(" Im Graph öffnen "),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.minimal
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-3 my-1",
                              attrs: {
                                rounded: "",
                                color: "grey",
                                depressed: "",
                                dark: "",
                                router: "",
                                append: "",
                                to: "history",
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("history"),
                              ]),
                              _vm._v(" Versionierung "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-spacer", { staticClass: "py-3" }),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "6", md: "4" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "max-width": "500px",
                              cursor: "pointer !important",
                            },
                          },
                          [
                            _c("v-select", {
                              attrs: {
                                dense: "",
                                outlined: "",
                                label: "Status",
                                items: _vm.statusOptions,
                                chips: "",
                                loading: _vm.loadingStatus,
                                color: "",
                                "item-text": "label",
                                "menu-props": { maxHeight: 500 },
                                "item-value": "value",
                                "persistent-hint": "",
                                hint: _vm.isLocked
                                  ? "Datensatz kann in diesem Status nicht editiert werden."
                                  : "",
                              },
                              on: { change: _vm.saveStatus },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            class: data.item.lock
                                              ? "warning--text"
                                              : "",
                                          },
                                          [
                                            data.item.lock
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "warning" },
                                                  },
                                                  [_vm._v(" lock ")]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              { staticClass: "pl-1" },
                                              [_vm._v(_vm._s(data.item.label))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "v-list-item-avatar",
                                          {
                                            attrs: {
                                              right: "",
                                              size: "28",
                                              tile: "",
                                            },
                                          },
                                          [
                                            data.item.lock
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: data.item.color,
                                                    },
                                                  },
                                                  [_vm._v("lock")]
                                                )
                                              : _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: data.item.color,
                                                    },
                                                  },
                                                  [_vm._v("circle")]
                                                ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  data.item.label
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                457746281
                              ),
                              model: {
                                value: _vm.entry.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.entry, "status", $$v)
                                },
                                expression: "entry.status",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "6", md: "4" } }, [
                        _c(
                          "div",
                          { staticStyle: { "max-width": "500px" } },
                          [
                            _c("v-select", {
                              attrs: {
                                dense: "",
                                outlined: "",
                                disabled:
                                  _vm.isLocked ||
                                  !_vm.$permGuard.chP("entries.c"),
                                items: _vm.templateOptionsEnum,
                                "item-value": "_id",
                                "item-text": _vm.templateName,
                                label: "Template",
                              },
                              on: { change: _vm.saveTemplate },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "pb-2",
                                            class: { "pt-2": !data.item.id },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      data.item.name
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            data.item.id
                                              ? _c("img", {
                                                  staticStyle: {
                                                    height: "70px",
                                                  },
                                                  attrs: {
                                                    src:
                                                      "https://cc.we2p.de/" +
                                                      _vm.$settings.getValue(
                                                        "cometConnection.client"
                                                      ) +
                                                      "/INSTANCE1/pageitems/preview/" +
                                                      data.item.id +
                                                      ".gif",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "pb-2",
                                            class: { "pt-2": !data.item.id },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      data.item.name
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            data.item.id
                                              ? _c("img", {
                                                  staticStyle: {
                                                    height: "70px",
                                                  },
                                                  attrs: {
                                                    src:
                                                      "https://cc.we2p.de/" +
                                                      _vm.$settings.getValue(
                                                        "cometConnection.client"
                                                      ) +
                                                      "/INSTANCE1/pageitems/preview/" +
                                                      data.item.id +
                                                      ".gif",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1352627228
                              ),
                              model: {
                                value: _vm.entry.templateID,
                                callback: function ($$v) {
                                  _vm.$set(_vm.entry, "templateID", $$v)
                                },
                                expression: "entry.templateID",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", sm: "6", md: "4" } }, [
                        _c(
                          "div",
                          { staticStyle: { "max-width": "500px" } },
                          [
                            _c("v-select", {
                              attrs: {
                                dense: "",
                                outlined: "",
                                disabled:
                                  _vm.isLocked ||
                                  !_vm.$permGuard.chP("entries.c"),
                                items: _vm.entryTypes,
                                label: "Kategorie des Eintrags",
                                "item-value": "_id",
                                "item-text": "name",
                              },
                              on: { change: _vm.saveType },
                              model: {
                                value: _vm.entry.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.entry, "type", $$v)
                                },
                                expression: "entry.type",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("v-sheet", { staticClass: "px-2 py-2 mt-5" }, [
              _c(
                "div",
                { staticClass: "mx-4 my-4 pb-3" },
                [
                  _c("p", { staticClass: "text-uppercase text-caption" }, [
                    _vm._v("Ansicht"),
                  ]),
                  _c(
                    "v-btn-toggle",
                    {
                      attrs: { mandatory: "" },
                      model: {
                        value: _vm.editingMode,
                        callback: function ($$v) {
                          _vm.editingMode = $$v
                        },
                        expression: "editingMode",
                      },
                    },
                    [
                      _vm._l(_vm.editingModes, function (mode) {
                        return [
                          mode.condition()
                            ? _c(
                                "v-btn",
                                {
                                  key: mode.value,
                                  attrs: { value: mode.value, outlined: "" },
                                },
                                [_c("span", [_vm._v(_vm._s(mode.text))])]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                  _vm.isMultilangProject
                    ? _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-uppercase text-caption mb-2 mt-5",
                            },
                            [_vm._v("Übersetzungen")]
                          ),
                          _vm._l(_vm.entryLanguages, function (lang, index) {
                            return [
                              _c(
                                "v-chip",
                                {
                                  key: index,
                                  class:
                                    "pl-4 mr-3 " +
                                    (_vm.entryLanguages.length > 1
                                      ? "pr-0"
                                      : ""),
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s((lang.lang || {}).text)),
                                  ]),
                                  _vm.entryLanguages.length > 1
                                    ? _c(
                                        "v-btn",
                                        {
                                          key: index + "__delete",
                                          staticClass: "ml-3",
                                          staticStyle: {
                                            transform: "scale(0.8)",
                                          },
                                          attrs: {
                                            fab: "",
                                            color: "error",
                                            depressed: "",
                                            "x-small": "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeTranslation(
                                                lang.code
                                              )
                                            },
                                          },
                                        },
                                        [_c("v-icon", [_vm._v("remove")])],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          }),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                fab: "",
                                color: "success",
                                depressed: "",
                                "x-small": "",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.newTranslationDialog = true
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("add")])],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "v-dialog",
                    {
                      attrs: { "max-width": "500px" },
                      model: {
                        value: _vm.newTranslationDialog,
                        callback: function ($$v) {
                          _vm.newTranslationDialog = $$v
                        },
                        expression: "newTranslationDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-title",
                            [
                              _c(
                                "v-row",
                                { staticClass: "py-2" },
                                [
                                  _c("span", { staticClass: "ml-2 mt-3" }, [
                                    _vm._v(" Neue Übersetzung "),
                                  ]),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        fab: "",
                                        color: "error",
                                        depressed: "",
                                        icon: "",
                                        small: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.newTranslationDialog = false
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("close")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.projectLanguages.filter(function (
                                    pL
                                  ) {
                                    return !_vm.entryLanguages.find(function (
                                      eL
                                    ) {
                                      return eL.code === pL.value
                                    })
                                  }),
                                  placeholder: "Sprache wählen...",
                                  outlined: "",
                                  label: "Sprache",
                                },
                                model: {
                                  value: _vm.newTranslationLanguage,
                                  callback: function ($$v) {
                                    _vm.newTranslationLanguage = $$v
                                  },
                                  expression: "newTranslationLanguage",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _vm.newTranslationLanguage
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mb-3",
                                      attrs: {
                                        color: "success",
                                        depressed: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addTranslation(
                                            _vm.newTranslationLanguage
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Hinzufügen ")]
                                  )
                                : _vm._e(),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c(
              "v-sheet",
              { staticClass: "px-2 py-5 mt-5" },
              [
                _vm.editingMode === "translate"
                  ? _c(
                      "v-row",
                      [
                        _vm._l(
                          _vm.entryLanguages.length > 1 ? [0, 1] : [0],
                          function (column, index) {
                            return _c(
                              "v-col",
                              { key: index, attrs: { cols: 6 } },
                              [
                                _vm.isMultilangProject
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-5" },
                                      [
                                        _c(
                                          "v-row",
                                          { staticClass: "mx-4" },
                                          [
                                            _c("LangSwitch", {
                                              attrs: {
                                                available:
                                                  _vm.entryLanguages.map(
                                                    function (l) {
                                                      return l.code
                                                    }
                                                  ),
                                              },
                                              model: {
                                                value:
                                                  _vm.columns[index]["lang"],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.columns[index],
                                                    "lang",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "columns[index]['lang']",
                                              },
                                            }),
                                            _c("v-select", {
                                              staticClass: "ml-3",
                                              staticStyle: {
                                                "max-width": "250px",
                                              },
                                              attrs: {
                                                items: _vm.langStatusOptions,
                                                label: "Status",
                                                outlined: "",
                                                value:
                                                  (
                                                    _vm.entryLanguages.find(
                                                      function (eL) {
                                                        return (
                                                          eL.code ===
                                                          _vm.columns[index]
                                                            .lang
                                                        )
                                                      }
                                                    ) || {}
                                                  ).status || "in_progress",
                                                "append-outer-icon":
                                                  (
                                                    _vm.entryLanguages.find(
                                                      function (eL) {
                                                        return (
                                                          eL.code ===
                                                          _vm.columns[index]
                                                            .lang
                                                        )
                                                      }
                                                    ) || {}
                                                  ).status === "locked"
                                                    ? "lock"
                                                    : "",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.setTranslationStatus(
                                                    _vm.columns[index].lang,
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "mx-4" },
                                          [
                                            _vm.entry.templateID
                                              ? _c("CometPreview", {
                                                  ref: "cometPreview",
                                                  refInFor: true,
                                                  attrs: {
                                                    "entry-id": _vm.entryId,
                                                    languages: [
                                                      _vm.columns[index][
                                                        "lang"
                                                      ],
                                                    ],
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "my-auto mr-5",
                                                                    attrs: {
                                                                      color:
                                                                        "blue",
                                                                      depressed:
                                                                        "",
                                                                      dark: "",
                                                                      rounded:
                                                                        "",
                                                                    },
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "preview"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Vorschau"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                })
                                              : _vm._e(),
                                            _vm.entry.templateID &&
                                            !_vm.entryType.disableIXEdit
                                              ? _c("InDesignEditorDialog", {
                                                  attrs: {
                                                    entry: _vm.entry,
                                                    language:
                                                      _vm.columns[index][
                                                        "lang"
                                                      ],
                                                    "is-locked":
                                                      (
                                                        _vm.entryLanguages.find(
                                                          function (eL) {
                                                            return (
                                                              eL.code ===
                                                              _vm.columns[index]
                                                                .lang
                                                            )
                                                          }
                                                        ) || {}
                                                      ).status === "locked",
                                                  },
                                                  on: {
                                                    save: _vm.handleIXEditSave,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-form",
                                  {
                                    ref: "FormValidation",
                                    refInFor: true,
                                    model: {
                                      value: _vm.dataValid,
                                      callback: function ($$v) {
                                        _vm.dataValid = $$v
                                      },
                                      expression: "dataValid",
                                    },
                                  },
                                  [
                                    _c("EntryEditForm", {
                                      ref: "EntryEditForm",
                                      refInFor: true,
                                      attrs: {
                                        language: _vm.columns[index]["lang"],
                                        "hide-non-multilang": false,
                                        columns: _vm.columns,
                                        entry: _vm.entry,
                                        entity: _vm.entity,
                                        definition: _vm.entryTypeDefinition,
                                        "is-locked": _vm.isColumnLocked(index),
                                      },
                                      on: {
                                        saveEntity: _vm.saveContent,
                                        updateEntity: function ($event) {
                                          _vm.entity = $event
                                        },
                                        importItinerary: function ($event) {
                                          return _vm.requestImportItinerary()
                                        },
                                      },
                                      model: {
                                        value: _vm.entry.content,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.entry, "content", $$v)
                                        },
                                        expression: "entry.content",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        _vm.entryLanguages.length < 2
                          ? _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c(
                                  "v-container",
                                  { staticClass: "my-10 mx-auto text-center" },
                                  [
                                    _c("p", { staticClass: "text-h6" }, [
                                      _vm._v("Jetzt übersetzen"),
                                    ]),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          fab: "",
                                          color: "success",
                                          large: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.newTranslationDialog = true
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("add")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _vm.isMultilangProject
                              ? _c(
                                  "div",
                                  { staticClass: "mt-5" },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "mx-4" },
                                      [
                                        _c("LangSwitch", {
                                          attrs: {
                                            available: _vm.entryLanguages.map(
                                              function (l) {
                                                return l.code
                                              }
                                            ),
                                          },
                                          model: {
                                            value: _vm.columns[0]["lang"],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.columns[0],
                                                "lang",
                                                $$v
                                              )
                                            },
                                            expression: "columns[0]['lang']",
                                          },
                                        }),
                                        _c("v-select", {
                                          staticClass: "ml-3",
                                          staticStyle: { "max-width": "250px" },
                                          attrs: {
                                            items: _vm.langStatusOptions,
                                            label: "Status",
                                            outlined: "",
                                            value:
                                              (
                                                _vm.entryLanguages.find(
                                                  function (eL) {
                                                    return (
                                                      eL.code ===
                                                      _vm.columns[0].lang
                                                    )
                                                  }
                                                ) || {}
                                              ).status || "in_progress",
                                            "append-outer-icon":
                                              (
                                                _vm.entryLanguages.find(
                                                  function (eL) {
                                                    return (
                                                      eL.code ===
                                                      _vm.columns[0].lang
                                                    )
                                                  }
                                                ) || {}
                                              ).status === "locked"
                                                ? "lock"
                                                : "",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.setTranslationStatus(
                                                _vm.columns[0].lang,
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mx-4" },
                                      [
                                        _vm.entry.templateID
                                          ? _c("CometPreview", {
                                              ref: "cometPreview",
                                              attrs: {
                                                "entry-id": _vm.entryId,
                                                languages: [
                                                  _vm.columns[0]["lang"],
                                                ],
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "my-auto mr-5",
                                                                attrs: {
                                                                  color: "blue",
                                                                  depressed: "",
                                                                  dark: "",
                                                                  rounded: "",
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "preview"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                "Vorschau"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3048983649
                                              ),
                                            })
                                          : _vm._e(),
                                        _vm.entry.templateID &&
                                        !_vm.entryType.disableIXEdit
                                          ? _c("InDesignEditorDialog", {
                                              attrs: {
                                                entry: _vm.entry,
                                                language:
                                                  _vm.columns[0]["lang"],
                                                "is-locked":
                                                  (
                                                    _vm.entryLanguages.find(
                                                      function (eL) {
                                                        return (
                                                          eL.code ===
                                                          _vm.columns[0].lang
                                                        )
                                                      }
                                                    ) || {}
                                                  ).status === "locked",
                                              },
                                              on: {
                                                save: _vm.handleIXEditSave,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-form",
                              {
                                ref: "FormValidation",
                                model: {
                                  value: _vm.dataValid,
                                  callback: function ($$v) {
                                    _vm.dataValid = $$v
                                  },
                                  expression: "dataValid",
                                },
                              },
                              [
                                _c("EntryEditForm", {
                                  ref: "EntryEditForm",
                                  attrs: {
                                    language: _vm.columns[0]["lang"],
                                    entry: _vm.entry,
                                    entity: _vm.entity,
                                    columns: _vm.columns.slice(0, 1),
                                    definition: _vm.entryTypeDefinition,
                                    "synced-mode": _vm.syncedMode,
                                    "is-locked": _vm.isColumnLocked(0),
                                  },
                                  on: {
                                    saveEntity: _vm.saveContent,
                                    updateEntity: function ($event) {
                                      _vm.entity = $event
                                    },
                                    importItinerary: function ($event) {
                                      return _vm.requestImportItinerary()
                                    },
                                  },
                                  model: {
                                    value: _vm.entry.content,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.entry, "content", $$v)
                                    },
                                    expression: "entry.content",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ],
              1
            ),
            _c(
              "v-sheet",
              { staticClass: "px-2 py-2 mt-5", attrs: { id: "images" } },
              [
                _c("div", { staticClass: "mx-4 my-4 pb-3" }, [
                  _c("p", { staticClass: "text-uppercase text-caption mb-0" }, [
                    _vm._v("Bilder"),
                  ]),
                  _c("div", {
                    attrs: {
                      id: "venus-media-collection__plugin",
                      "data-settings": _vm.mediaCollectionPluginSettings,
                    },
                  }),
                ]),
              ]
            ),
          ]
        : _vm._e(),
      _c(
        "v-btn",
        {
          staticStyle: { top: "80px", "backdrop-filter": "blur(5px)" },
          attrs: {
            fab: "",
            "x-large": "",
            fixed: "",
            color: _vm.getSaveColor,
            top: "",
            right: "",
            disabled: !_vm.saveRequired,
          },
          on: { click: _vm.saveContent },
        },
        [
          !_vm.syncing && !_vm.saving && _vm.saveRequired
            ? _c("v-icon", { attrs: { color: "white" } }, [_vm._v(" save ")])
            : _vm._e(),
          !_vm.syncing && !_vm.saving && !_vm.saveRequired
            ? _c("v-icon", { attrs: { color: "white" } }, [_vm._v(" check ")])
            : _vm._e(),
          _vm.saving || _vm.syncing
            ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
            : _vm._e(),
        ],
        1
      ),
      _vm.syncedMode && _vm.savingEntity
        ? _c(
            "v-btn",
            {
              staticClass: "my-auto ml-2",
              staticStyle: { top: "160px", right: "30px" },
              attrs: {
                fixed: "",
                top: "",
                right: "",
                loading: _vm.savingEntity,
                fab: "",
                small: "",
                color: "success",
              },
            },
            [
              !_vm.savingEntity
                ? _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v(" save "),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600", persistent: "" },
          model: {
            value: _vm.loadingStatus,
            callback: function ($$v) {
              _vm.loadingStatus = $$v
            },
            expression: "loadingStatus",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card",
                { staticClass: "text-center py-5" },
                [
                  _c("v-progress-circular", {
                    staticClass: "my-5",
                    attrs: {
                      size: 50,
                      width: 4,
                      color: "grey",
                      indeterminate: "",
                    },
                  }),
                  _c("p", { staticClass: "text-center" }, [
                    _vm._v("Status wird aktualisiert."),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DeleteConfirmation", {
        ref: "importItineraryDialog",
        attrs: {
          hidden: true,
          msg: "Falls Sie neue Streckenpunkte im Streckeneditor erstellt/verknüpft haben, sollten diese in den PublicationPlanner importiert werden. Streckenpunkte jetzt synchronisieren?",
          title: "Streckenpunkte aktualisieren?",
        },
        on: {
          yes: function ($event) {
            return _vm.execImportItinerary(false)
          },
        },
      }),
      _c("DeleteConfirmation", {
        ref: "importDescriptionDialog",
        attrs: {
          hidden: true,
          msg: "Falls Sie etwas an den Texten geändert haben, sollten diese in den PublicationPlanner importiert werden. Texte jetzt synchronisieren?",
          title: "Texte aktualisieren?",
        },
        on: {
          yes: function ($event) {
            return _vm.execImportDescription(false)
          },
        },
      }),
      _c("DeleteConfirmation", {
        ref: "itineraryForceSaveDialog",
        attrs: {
          hidden: true,
          msg: "Es bestehen noch ungespeicherte Änderungen, die zum Forfahren gespeichert werden müssen. Sollen diese jetzt gespeichert werden?",
          title: "Jetzt speichern?",
        },
        on: {
          yes: function ($event) {
            return _vm.execImportItinerary(true)
          },
        },
      }),
      _c("DeleteConfirmation", {
        ref: "descriptionForceSaveDialog",
        attrs: {
          hidden: true,
          msg: "Es bestehen noch ungespeicherte Änderungen, die zum Forfahren gespeichert werden müssen. Sollen diese jetzt gespeichert werden?",
          title: "Jetzt speichern?",
        },
        on: {
          yes: function ($event) {
            return _vm.execImportDescription(true)
          },
        },
      }),
      _c("BackToTopButton"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }