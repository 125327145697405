var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "text-h6 mb-4 grey--text" }, [_vm._v("Auflagen")]),
      _c(
        "v-container",
        { staticClass: "my-5" },
        [
          _vm.allCatalogs.length === 0
            ? _c(
                "div",
                { staticClass: "my-5 text-center" },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mb-4 grey--text", attrs: { size: "128" } },
                    [_vm._v("menu_book")]
                  ),
                  _c("p", { staticClass: "text-h4 my-5" }, [
                    _vm._v("Noch keine Produktionen."),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.allCatalogs, function (catalog, index) {
            return _c(
              "v-card",
              {
                key: catalog._id,
                staticClass: "px-3 py-5 mb-4",
                attrs: { flat: "", item: catalog, index: index },
              },
              [
                _c(
                  "v-layout",
                  { staticClass: "px-4", attrs: { row: "", wrap: "" } },
                  [
                    _c("v-flex", { attrs: { xs12: "", md4: "" } }, [
                      _c("div", { staticClass: "caption grey--text" }, [
                        _vm._v("Auflage"),
                      ]),
                      _c("p", { staticClass: "text-h5 mb-0" }, [
                        _vm._v(" " + _vm._s(catalog.name) + " "),
                      ]),
                      _c("p", { staticClass: "mb-0 text-caption" }, [
                        _vm._v(
                          "erstellt am " + _vm._s(_vm.toDate(catalog.createdAt))
                        ),
                      ]),
                    ]),
                    _c("v-spacer"),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm3: "" } },
                      [
                        _c(
                          "v-container",
                          {
                            staticClass: "ml-auto",
                            attrs: { "fill-height": "" },
                          },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "white--text mr-3",
                                                  attrs: {
                                                    small: "",
                                                    depressed: "",
                                                    fab: "",
                                                    dark: "",
                                                    color: "info",
                                                    router: "",
                                                    to:
                                                      "/projects/" +
                                                      _vm.$router.currentRoute
                                                        .params.projectID +
                                                      "/channels/" +
                                                      _vm.$router.currentRoute
                                                        .params.channelID +
                                                      "/print/catalogs/" +
                                                      catalog._id,
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v("read_more"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Einträge ansehen")])]
                            ),
                            _vm.$permGuard.chP("catalogs.c")
                              ? _c("DuplicateCatalog", {
                                  attrs: { catalog: catalog },
                                })
                              : _vm._e(),
                            _vm.$permGuard.chP("catalogs.d")
                              ? _c("DeleteConfirmation", {
                                  on: {
                                    yes: function ($event) {
                                      return _vm.removeCatalog(catalog._id)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c("CatalogCreate"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }