var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-2" },
    [
      _c(
        "v-row",
        { staticClass: "mx-0 my-2" },
        [
          _c("p", { staticClass: "ma-0" }, [
            _c("span", { staticClass: "mr-3 text-h6" }, [
              _vm._v(_vm._s(_vm.definition.label)),
            ]),
            _vm.$permGuard.hasPermission("super_admin")
              ? _c("span", { staticClass: "mr-3 orange--text text-body-2" }, [
                  _vm._v("<" + _vm._s(_vm.definition.name) + ">"),
                ])
              : _vm._e(),
          ]),
          (_vm.definition.validators || []).includes("required")
            ? _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "span",
                              _vm._g(
                                _vm._b(
                                  { staticClass: "mr-3", attrs: { text: "" } },
                                  "span",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v(" * ")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1521187535
                  ),
                },
                [_c("span", [_vm._v("Pflichtfeld")])]
              )
            : _vm._e(),
          _vm.isLocked
            ? _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "mr-3",
                                    attrs: { color: "warning" },
                                  },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v(" lock ")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1746716196
                  ),
                },
                [_c("span", [_vm._v("Datensatz ist gesperrt.")])]
              )
            : _vm._e(),
          _vm.definition.description
            ? _c(
                "v-dialog",
                {
                  attrs: { width: "500" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "mr-3",
                                    attrs: { text: "", icon: "", small: "" },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_c("v-icon", [_vm._v(" info ")])],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1671503173
                  ),
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "headline white--text secondary" },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-4", attrs: { dark: "" } },
                            [_vm._v(" info ")]
                          ),
                          _vm._v(" " + _vm._s(_vm.definition.label) + " "),
                        ],
                        1
                      ),
                      _c("v-card-text", [
                        _c("div", { staticClass: "pt-5" }, [
                          _c("div", [
                            _vm._v(_vm._s(_vm.definition.description)),
                          ]),
                        ]),
                      ]),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", text: "" },
                              on: {
                                click: function ($event) {
                                  _vm.dialog = false
                                },
                              },
                            },
                            [_vm._v(" OK ")]
                          ),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("v-spacer"),
          _vm.definition.linkName
            ? _c(
                "v-chip",
                {
                  staticClass: "ml-0 text-caption",
                  attrs: { small: "", rounded: "", depressed: "" },
                },
                [
                  _c("span", { staticClass: "mx-2" }, [
                    _vm._v(_vm._s(_vm.definition.linkName)),
                  ]),
                  _c("v-icon", { attrs: { size: "20", color: "grey" } }, [
                    _vm._v(" link "),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }