import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';

// import views
import Channels from '../views/Channels.vue';
import PlainRouterView from '../views/PlainRouterView';
import PlainRouterViewWithBack from '../views/PlainRouterViewWithBack';

import Login from '../views/Login';
import PageNotFound from '../views/PageNotFound';

import adminRoutes from './routes/admin';
import graphRoutes from './routes/graph';
import printRoutes from './routes/print';
import chatbotRoutes from './routes/chatbot_onlim';
import Dashboard from '../views/Admin/Dashboard';
import Users from '../views/Admin/users/Users';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/projects',
        name: 'Home',
        component: Channels,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/admin',
        name: 'Admin',
        meta: {
            requiresAuth: true,
        },
        component: PlainRouterView,
        children: adminRoutes,
    },
    {
        path: '/projects',
        name: 'Projects',
        component: PlainRouterViewWithBack,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: ':projectID',
                name: 'ProjectChannels',
                component: PlainRouterView,
                meta: {
                    requiresAuth: true,
                },
                children: [
                    {
                        // GRAPH
                        path: 'graph',
                        name: 'Graph',
                        component: PlainRouterView,
                        children: graphRoutes,
                    },
                    {
                        path: 'channels',
                        component: Channels,
                    },
                    {
                        path: 'channels/:channelID',
                        component: PlainRouterView,
                        children: [printRoutes, chatbotRoutes],
                    },
                ],
            },
        ],
    },
    {
        path: '*',
        name: 'PageNotFound',
        component: PageNotFound,
        meta: {
            requiresAuth: false,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

// router.beforeEach(Vue.prototype.$auth.authRedirectGuard());

let projectID = '-1';
let channelID = '-1';

// check on each route change if project/channel has changed, if true dispatch actions
async function checkProjectChannelChange(to, from) {
    // check if to-route targets project-channel
    if (!isNaN(to.params?.projectID) && !isNaN(to.params?.channelID)) {
        // check if project or channel is different
        if (
            to.params?.projectID !== projectID ||
            to.params?.channelID !== channelID
        ) {
            // set new project-channel
            projectID = to.params?.projectID;
            channelID = to.params?.channelID;

            // dispatch necessary actions
            // settings
            await store.dispatch('printsettings/fetchSettings', {
                projectID,
                channelID,
            });
            // todo entrytypes
            // todo templates
            // todo entries
        }
    }
}

router.beforeEach(async (to, from, next) => {
    console.log('route, projectID -- watch', router.currentRoute.params);

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        console.log(store.getters['auth/isLoggedIn']);
        if (!store.getters['auth/isLoggedIn']) {
            next({
                name: 'login',
                params: {
                    nextUrl: to.fullPath,
                },
            });
        }
    }
    await checkProjectChannelChange(to, from);
    next();
});

export default router;
