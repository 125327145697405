var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "entrytypeedit" },
    [
      _vm.loading
        ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
        : _vm._e(),
      _c("h1", [_vm._v(_vm._s(_vm.entrytype.name))]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { sm: "12", md: "6" } },
            [
              _c("v-text-field", {
                attrs: { label: "Name" },
                model: {
                  value: _vm.entrytype.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.entrytype, "name", $$v)
                  },
                  expression: "entrytype.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { sm: "12", md: "6" } },
            [
              _c("v-text-field", {
                attrs: { label: "we2p® Type" },
                model: {
                  value: _vm.entrytype.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.entrytype, "type", $$v)
                  },
                  expression: "entrytype.type",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { sm: "12", md: "6" } },
            [
              _c("v-select", {
                attrs: {
                  "menu-props": { offsetY: true },
                  items: _vm.getAllTemplates,
                  "item-value": "_id",
                  "item-text": _vm.templateName,
                  label: "Templates",
                  multiple: "",
                  chips: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function (ref) {
                      var item = ref.item
                      var index = ref.index
                      return [
                        _c("v-chip", [_vm._v(_vm._s(_vm.templateName(item)))]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.entrytype.templates,
                  callback: function ($$v) {
                    _vm.$set(_vm.entrytype, "templates", $$v)
                  },
                  expression: "entrytype.templates",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { sm: "12", md: "6" } },
            [
              _c("v-checkbox", {
                staticStyle: { "max-width": "200px" },
                attrs: { label: "IXEdit deaktivieren" },
                model: {
                  value: _vm.entrytype.disableIXEdit,
                  callback: function ($$v) {
                    _vm.$set(_vm.entrytype, "disableIXEdit", $$v)
                  },
                  expression: "entrytype.disableIXEdit",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { sm: "12" } }, [
            _c("h3", [_vm._v("Datenfelder")]),
          ]),
          !_vm.loading
            ? _c("PropertyList", {
                ref: "properties",
                attrs: { props: _vm.entrytype.properties },
                on: {
                  update: function ($event) {
                    _vm.savingRequired = true
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            fab: "",
            "x-large": "",
            fixed: "",
            color: _vm.saveColor,
            bottom: "",
            right: "",
            disabled: !_vm.savingRequired,
          },
          on: { click: _vm.save },
        },
        [
          !_vm.saving ? _c("v-icon", [_vm._v(" save ")]) : _vm._e(),
          _vm.saving
            ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }