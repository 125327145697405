var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "mr-3 mb-3 white--text",
                      attrs: {
                        rounded: "",
                        color: "primary",
                        disabled: _vm.isLocked,
                      },
                      on: { click: _vm.sendToEditor },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("edit")]),
                  _vm._v(" Editor öffnen "),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { elevation: "2" } },
            [
              _c("v-toolbar-title", [
                _c("img", {
                  staticClass: "mt-1",
                  attrs: { src: _vm.logoURL, height: "30" },
                }),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 font-weight-light",
                    staticStyle: { "vertical-align": "text-bottom" },
                  },
                  [_vm._v("Strecken-Editor")]
                ),
              ]),
              _c("v-spacer"),
              _c(
                "v-tooltip",
                {
                  attrs: { left: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "mr-1",
                                  attrs: { icon: "", text: "", color: "info" },
                                  on: { click: _vm.forceReload },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("refresh")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Editor neu laden")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c("DeleteConfirmation", {
                            attrs: {
                              msg: "Nicht gespeicherte Änderungen gehen eventuell verloren.",
                              title: "Wirklich beenden?",
                            },
                            on: {
                              yes: function ($event) {
                                _vm.dialog = false
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "mr-0",
                                                  attrs: {
                                                    icon: "",
                                                    text: "",
                                                    color: "error",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_c("v-icon", [_vm._v("close")])],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Editor beenden")])]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { height: "calc(100vh - 80px)" } },
            [
              _c("TrailEditor", {
                key: _vm.componentKey,
                attrs: { entry: _vm.entry },
                on: {
                  close: _vm.close,
                  save: function ($event) {
                    return _vm.$emit("save")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }