var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "text-h4 my-5 font-weight-bold grey--text" }, [
        _vm._v("Alle Datensätze"),
      ]),
      _c("v-spacer", { staticClass: "py-5" }),
      _c("EntityListFilter", {
        attrs: { loading: _vm.loading },
        model: {
          value: _vm.filter,
          callback: function ($$v) {
            _vm.filter = $$v
          },
          expression: "filter",
        },
      }),
      _c(
        "v-row",
        { staticClass: "mt-5" },
        [
          _c(
            "v-btn",
            {
              staticClass: "my-auto ml-5 mr-3",
              attrs: {
                color: "success",
                depressed: "",
                rounded: "",
                large: "",
              },
            },
            [
              _c("v-icon", { staticClass: "mr-2" }, [_vm._v("add_circle")]),
              _c("span", [_vm._v("Neu")]),
            ],
            1
          ),
          _c("v-spacer"),
          _c("EntityColumnConfig", {
            attrs: { properties: _vm.$store.getters["properties/getAll"] },
            model: {
              value: _vm.headers,
              callback: function ($$v) {
                _vm.headers = $$v
              },
              expression: "headers",
            },
          }),
          _c(
            "v-col",
            { staticStyle: { "max-width": "500px" }, attrs: { cols: "12" } },
            [
              _c(
                "v-sheet",
                { staticClass: "px-4 pt-1" },
                [
                  _c("v-text-field", {
                    attrs: {
                      "prepend-icon": "search",
                      loading: _vm.loading,
                      label: "Suchen",
                    },
                    model: {
                      value: _vm.filter.queryString,
                      callback: function ($$v) {
                        _vm.$set(_vm.filter, "queryString", $$v)
                      },
                      expression: "filter.queryString",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("EntityList", {
        attrs: { total: _vm.total, headers: _vm.headers, items: _vm.tableData },
        on: { options: _vm.updateOptions },
        model: {
          value: _vm.selectedEntities,
          callback: function ($$v) {
            _vm.selectedEntities = $$v
          },
          expression: "selectedEntities",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }