import { render, staticRenderFns } from "./PermissionTree.vue?vue&type=template&id=f9e069b8&"
import script from "./PermissionTree.vue?vue&type=script&lang=js&"
export * from "./PermissionTree.vue?vue&type=script&lang=js&"
import style0 from "./PermissionTree.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VSpacer,VTextField,VTreeview})


/* hot reload */
if (module.hot) {
  var api = require("/root/print-suite/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f9e069b8')) {
      api.createRecord('f9e069b8', component.options)
    } else {
      api.reload('f9e069b8', component.options)
    }
    module.hot.accept("./PermissionTree.vue?vue&type=template&id=f9e069b8&", function () {
      api.rerender('f9e069b8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/users/PermissionTree.vue"
export default component.exports