var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.getters["properties/getStatus"] !== "loading"
    ? _c(
        "div",
        [
          _c("LabelComponent", {
            attrs: { definition: _vm.definition, "is-locked": _vm.isLocked },
          }),
          _c(
            "v-sheet",
            {
              class:
                "grey " +
                (_vm.$vuetify.theme.isDark ? "darken-4" : "lighten-5") +
                " pa-4",
            },
            [
              _c(
                "v-row",
                { staticClass: "pa-3" },
                [
                  _c("v-spacer"),
                  _vm.$store.getters["user/getSettingsStatus"] === "success"
                    ? _c("ColumnConfig", {
                        attrs: {
                          properties: _vm.definition.properties,
                          "column-config": _vm.columnConfig,
                        },
                        on: { apply: _vm.generateHeader },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "transparent",
                attrs: {
                  "disable-sort": true,
                  "disable-pagination": true,
                  "item-key": "tableHelperIndex",
                  headers: _vm.headers,
                  items: _vm.tableData,
                  "footer-props": {
                    "items-per-page-options": [-1],
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.controls",
                      fn: function (props) {
                        return [
                          _c(
                            "v-dialog",
                            {
                              attrs: { scrollable: "", "max-width": "1600px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mr-3 my-2",
                                                attrs: {
                                                  fab: "",
                                                  depressed: "",
                                                  "x-small": "",
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_c("v-icon", [_vm._v("edit")])],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.name",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(item) +
                                            " " +
                                            _vm._s(_vm.translate(item)) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.dialog[props.item.tableHelperIndex],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dialog,
                                    props.item.tableHelperIndex,
                                    $$v
                                  )
                                },
                                expression:
                                  "dialog[props.item.tableHelperIndex]",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-title",
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.definition.label)),
                                      ]),
                                      _c("v-spacer"),
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-card-text",
                                    { staticStyle: { "max-height": "90vh" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-form",
                                                { ref: "FormValidation" },
                                                [
                                                  _c("EntryEditForm", {
                                                    ref: props.item
                                                      .tableHelperIndex,
                                                    attrs: {
                                                      language: _vm.language,
                                                      "hide-non-multilang": false,
                                                      columns: _vm.columns,
                                                      definition:
                                                        _vm.definition
                                                          .properties,
                                                      "synced-mode": false,
                                                      "is-locked": _vm.isLocked,
                                                    },
                                                    on: {
                                                      updateEntity: function (
                                                        $event
                                                      ) {
                                                        _vm.entity = $event
                                                      },
                                                      importItinerary:
                                                        function ($event) {
                                                          return _vm.requestImportItinerary()
                                                        },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.data[
                                                          _vm.data.findIndex(
                                                            function (row) {
                                                              return (
                                                                row.tableHelperIndex ===
                                                                props.item
                                                                  .tableHelperIndex
                                                              )
                                                            }
                                                          )
                                                        ],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.data,
                                                          _vm.data.findIndex(
                                                            function (row) {
                                                              return (
                                                                row.tableHelperIndex ===
                                                                props.item
                                                                  .tableHelperIndex
                                                              )
                                                            }
                                                          ),
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "data[data.findIndex(row => row.tableHelperIndex === props.item.tableHelperIndex)]",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "info", text: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.finishEditing(
                                                props.item.tableHelperIndex
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("OK")]
                                      ),
                                      _c("v-spacer"),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("DeleteConfirmation", {
                            on: {
                              yes: function ($event) {
                                return _vm.removeRow(
                                  props.item.tableHelperIndex
                                )
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mr-3 my-2",
                                              attrs: {
                                                fab: "",
                                                color: "error",
                                                depressed: "",
                                                "x-small": "",
                                                router: "",
                                                append: "",
                                                disabled: _vm.isLocked,
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("delete")])],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-3 my-2",
                              attrs: {
                                fab: "",
                                depressed: "",
                                "x-small": "",
                                router: "",
                                append: "",
                                disabled:
                                  !(
                                    _vm.data.findIndex(function (x) {
                                      return (
                                        x.tableHelperIndex ===
                                        props.item.tableHelperIndex
                                      )
                                    }) <
                                    _vm.data.length - 1
                                  ) || _vm.isLocked,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.moveRow(
                                    "down",
                                    props.item.tableHelperIndex
                                  )
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("keyboard_arrow_down")])],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-3 my-2",
                              attrs: {
                                fab: "",
                                depressed: "",
                                "x-small": "",
                                router: "",
                                append: "",
                                disabled:
                                  !(
                                    _vm.data.findIndex(function (x) {
                                      return (
                                        x.tableHelperIndex ===
                                        props.item.tableHelperIndex
                                      )
                                    }) !== 0
                                  ) || _vm.isLocked,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.moveRow(
                                    "up",
                                    props.item.tableHelperIndex
                                  )
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("keyboard_arrow_up")])],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2681511355
                ),
              }),
              _c(
                "v-row",
                { staticClass: "pb-3" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-3",
                      attrs: {
                        fab: "",
                        color: "success",
                        small: "",
                        depressed: "",
                        disabled: _vm.isLocked,
                      },
                      on: { click: _vm.addRow },
                    },
                    [_c("v-icon", [_vm._v("add")])],
                    1
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }