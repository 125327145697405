var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-2 mt-1" },
    [
      _c("span", { staticClass: "text-h6 mr-3" }, [
        _vm._v(_vm._s(_vm.cpDefinition.label)),
      ]),
      _c("span", { staticClass: "mr-3 caption blue--text" }, [
        _vm._v(_vm._s(_vm.cpDefinition.name)),
      ]),
      _vm.cpDefinition.description
        ? _c(
            "v-dialog",
            {
              attrs: { width: "500" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "mr-3",
                                attrs: { text: "", icon: "", small: "" },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_c("v-icon", [_vm._v(" info ")])],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1671503173
              ),
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline white--text secondary" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-4", attrs: { dark: "" } },
                        [_vm._v(" info ")]
                      ),
                      _vm._v(" " + _vm._s(_vm.cpDefinition.label) + " "),
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _c("div", { staticClass: "pt-5" }, [
                      _c("div", [_vm._v(_vm._s(_vm.cpDefinition.description))]),
                    ]),
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [_vm._v(" OK ")]
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }