var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticStyle: {
            "max-height": "80vh",
            position: "relative",
            "overflow-y": "scroll",
          },
          attrs: { elevation: "0" },
        },
        [
          _c("v-text-field", {
            staticClass: "ml-5",
            attrs: { "prepend-icon": "search" },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _c(
            "v-list",
            { attrs: { subheader: "" } },
            [
              _vm.contacts.length > 0
                ? _c(
                    "v-list-item-group",
                    [
                      _vm._l(_vm.contacts, function (contact, index) {
                        return [
                          _c(
                            "v-list-item",
                            {
                              key: contact._id,
                              attrs: {
                                router: "",
                                to: {
                                  name: "ContactEdit",
                                  params: {
                                    contactID: contact._id,
                                  },
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-avatar",
                                [_c("v-icon", [_vm._v(" account_circle ")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _vm.getDisplayName(contact)
                                    ? _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(_vm.getDisplayName(contact))
                                        ),
                                      ])
                                    : _c("v-list-item-title", [
                                        _c(
                                          "span",
                                          { staticClass: "blue--text" },
                                          [_vm._v("Neuer Kontakt")]
                                        ),
                                      ]),
                                  contact.organization &&
                                  _vm.getDisplayName(contact) !==
                                    contact.organization
                                    ? _c("v-list-item-subtitle", [
                                        _vm._v(
                                          " " +
                                            _vm._s(contact.organization) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          index < _vm.contacts.length - 1
                            ? _c("v-divider", {
                                key: index,
                                attrs: { inset: "" },
                              })
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  )
                : _c("div", [
                    _c("p", { staticClass: "mt-4 ml-7 grey--text" }, [
                      _vm._v("Keine Kontakte gefunden."),
                    ]),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }