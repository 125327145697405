var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1000" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [
                _vm._t(
                  "default",
                  function () {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-3",
                              attrs: {
                                fab: "",
                                color: "blue",
                                depressed: "",
                                "x-small": "",
                                router: "",
                                append: "",
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { color: "white" } }, [
                            _vm._v("remove_red_eye"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  { on: on, attrs: attrs }
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(" Print Vorschau "),
              ]),
              _vm.template && _vm.template.sideAware
                ? [
                    _c("v-spacer"),
                    _c(
                      "v-btn-toggle",
                      {
                        attrs: { borderless: "" },
                        on: {
                          change: function ($event) {
                            return _vm.loadPreview()
                          },
                        },
                        model: {
                          value: _vm.side,
                          callback: function ($$v) {
                            _vm.side = $$v
                          },
                          expression: "side",
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          { attrs: { value: "left", disabled: _vm.loading } },
                          [
                            _c("span", { staticClass: "hidden-sm-and-down" }, [
                              _vm._v("Links"),
                            ]),
                            _c(
                              "v-icon",
                              { staticClass: "mr-0", attrs: { right: "" } },
                              [_vm._v(" auto_stories ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          { attrs: { value: "right", disabled: _vm.loading } },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2 ml-0",
                                staticStyle: { transform: "scaleX(-1)" },
                                attrs: { right: "" },
                              },
                              [_vm._v(" auto_stories ")]
                            ),
                            _c("span", { staticClass: "hidden-sm-and-down" }, [
                              _vm._v("Rechts"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c("v-spacer"),
              _vm.previewLanguages.length > 1
                ? _vm._l(_vm.previewLanguages, function (lang, index) {
                    return _c(
                      "v-btn",
                      {
                        key: index,
                        staticClass: "mx-1",
                        style:
                          _vm.carouselPage >=
                            index *
                              (_vm.previewImages.length /
                                _vm.previewLanguages.length) &&
                          _vm.carouselPage <
                            index *
                              (_vm.previewImages.length /
                                _vm.previewLanguages.length) +
                              _vm.previewImages.length /
                                _vm.previewLanguages.length
                            ? ""
                            : "opacity: 0.3",
                        attrs: { fab: "", small: "", depressed: "" },
                        on: {
                          click: function ($event) {
                            _vm.carouselPage =
                              index *
                              (_vm.previewImages.length /
                                _vm.previewLanguages.length)
                          },
                        },
                      },
                      [
                        _c(
                          "v-avatar",
                          { attrs: { size: "32" } },
                          [
                            _c("v-img", {
                              attrs: {
                                src:
                                  "https://webimages.we2p.de/static/flags/svg/" +
                                  lang.slice(-2) +
                                  ".svg",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _c("v-spacer"),
              !_vm.loading &&
              !_vm.failed &&
              (_vm.previewInfo.links || {}).pdf &&
              !_vm.urls
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        color: "primary",
                        icon: "",
                        href: _vm.previewInfo.links.pdf,
                        target: "_blank",
                      },
                    },
                    [_c("v-icon", [_vm._v("picture_as_pdf")])],
                    1
                  )
                : _vm._e(),
              !_vm.urls
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { color: "blue", icon: "" },
                      on: { click: _vm.loadPreview },
                    },
                    [_c("v-icon", [_vm._v("sync")])],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "mr-0",
                  attrs: { color: "red", icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = !_vm.dialog
                    },
                  },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            2
          ),
          _c(
            "v-card",
            [
              !_vm.loading && !_vm.failed && _vm.previewImages.length === 1
                ? _c("img", {
                    attrs: {
                      width: "100%",
                      src: "" + _vm.previewImages,
                      alt: "CometPreview",
                    },
                  })
                : _vm._e(),
              !_vm.loading && !_vm.failed && _vm.previewImages.length > 1
                ? _c(
                    "v-carousel",
                    {
                      attrs: {
                        height: "700",
                        dark: "",
                        "show-arrows-on-hover": "",
                      },
                      model: {
                        value: _vm.carouselPage,
                        callback: function ($$v) {
                          _vm.carouselPage = $$v
                        },
                        expression: "carouselPage",
                      },
                    },
                    _vm._l(_vm.previewImages, function (previewLink, i) {
                      return _c("v-carousel-item", { key: i }, [
                        _c("img", {
                          staticStyle: { "object-fit": "contain" },
                          attrs: {
                            width: "100%",
                            height: "100%",
                            src: "" + previewLink,
                            alt: "CometPreview",
                          },
                        }),
                      ])
                    }),
                    1
                  )
                : _vm._e(),
              !_vm.loading && (_vm.failed || _vm.previewImages.length === 0)
                ? _c("v-container", { staticClass: "text-center py-5" }, [
                    _c("p", { staticClass: "text-h3" }, [
                      _vm._v("Fehlgeschlagen."),
                    ]),
                    _c("p", {}, [
                      _vm._v("Bitte kontaktieren Sie einen Administrator."),
                    ]),
                  ])
                : _vm._e(),
              _vm.loading
                ? _c(
                    "v-card",
                    { staticClass: "text-center py-5" },
                    [
                      _c("v-progress-circular", {
                        staticClass: "my-5",
                        attrs: {
                          size: 50,
                          width: 4,
                          color: "grey",
                          indeterminate: "",
                        },
                      }),
                      _vm.loadingOvertime
                        ? _c("p", { staticClass: "warning--text" }, [
                            _vm._v(
                              "Der Server ist gerade ausgelastet. Bitte haben Sie noch einen Augenblick Geduld."
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }