var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-col",
        { staticStyle: { "max-width": "1400px" }, attrs: { sm: "12" } },
        [
          _c(
            "draggable",
            _vm._b(
              {
                attrs: { group: "people", handle: ".handle" },
                on: {
                  start: function ($event) {
                    _vm.drag = true
                  },
                  end: function ($event) {
                    _vm.drag = false
                  },
                },
                model: {
                  value: _vm.properties,
                  callback: function ($$v) {
                    _vm.properties = $$v
                  },
                  expression: "properties",
                },
              },
              "draggable",
              _vm.dragOptions,
              false
            ),
            [
              _c(
                "transition-group",
                {
                  attrs: {
                    type: "transition",
                    name: !_vm.drag ? "flip-list" : null,
                  },
                },
                [
                  _vm._l(_vm.properties, function (property, index) {
                    return [
                      _c(
                        "v-list-item",
                        {
                          key: property.name,
                          staticClass: "mb-3 white listing-item",
                          attrs: { index: index },
                        },
                        [
                          [
                            _c(
                              "v-list-item-avatar",
                              {
                                staticClass: "handle",
                                staticStyle: { cursor: "grab" },
                              },
                              [_c("v-icon", [_vm._v(" drag_handle ")])],
                              1
                            ),
                            _vm.isDataComponent(property)
                              ? _c(
                                  "v-list-item-content",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      [
                                        _vm._v(
                                          " " + _vm._s(property.label) + " "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "orange--text text-caption",
                                          },
                                          [
                                            _vm._v(
                                              "<" + _vm._s(property.name) + ">"
                                            ),
                                          ]
                                        ),
                                        property.hidden
                                          ? _c(
                                              "v-icon",
                                              { staticClass: "ml-4" },
                                              [_vm._v("visibility_off")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    property.linkName
                                      ? _c(
                                          "v-list-item-subtitle",
                                          { staticClass: "grey--text" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  color: "success",
                                                  small: "",
                                                },
                                              },
                                              [_vm._v("link")]
                                            ),
                                            _c("span", [
                                              _vm._v(_vm._s(property.linkName)),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.isDataComponent(property)
                              ? _c(
                                  "v-list-item-content",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "text-h5" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            style:
                                              "color: " +
                                              ((property.config || {}).color ||
                                                "#2196f3") +
                                              ";",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(property.label) + " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "grey--text darken-4 text-caption",
                                              },
                                              [
                                                _vm._v(
                                                  "<!--" +
                                                    _vm._s(property.label) +
                                                    "-->"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-list-item-subtitle",
                                      { staticClass: "grey--text" },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(property.description)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("v-list-item-action", [
                              _c(
                                "div",
                                [
                                  property.type === "table"
                                    ? _c(
                                        "v-dialog",
                                        {
                                          attrs: {
                                            scrollable: "",
                                            "max-width": "1200px",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "ml-3",
                                                            attrs: {
                                                              fab: "",
                                                              depressed: "",
                                                              color: "blue",
                                                              small: "",
                                                            },
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v(" notes ")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.dialogs[property.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dialogs,
                                                property.name,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "dialogs[property.name]",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c("v-card-title", [
                                                _vm._v(_vm._s(property.label)),
                                              ]),
                                              _c("v-divider"),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c("PropertyList", {
                                                    ref: property.name,
                                                    refInFor: true,
                                                    attrs: {
                                                      props:
                                                        property.properties,
                                                    },
                                                    on: {
                                                      update: function (
                                                        $event
                                                      ) {
                                                        return _vm.$emit(
                                                          "update"
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-card-actions",
                                                [
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "success",
                                                        text: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.dialogs[
                                                            property.name
                                                          ] = false
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("OK")]
                                                  ),
                                                  _c("v-spacer"),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("PropertyEdit", {
                                    attrs: { property: property },
                                    on: { done: _vm.updateProperty },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "ml-3",
                                                      attrs: {
                                                        fab: "",
                                                        depressed: "",
                                                        color: "lightgrey",
                                                        small: "",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "dark" },
                                                    },
                                                    [_vm._v(" tune ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-3",
                                      attrs: {
                                        fab: "",
                                        depressed: "",
                                        color: "red",
                                        small: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteProperty(index)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [_vm._v(" delete ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                        ],
                        2
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-spacer"),
              _c("PropertyEdit", {
                attrs: { property: {}, create: true },
                on: { done: _vm.addProperty },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "text-left",
                                attrs: { fab: "", color: "success" },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_c("v-icon", [_vm._v("add")])],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("v-spacer"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }