var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "login-bg" }),
      _c("div", { staticClass: "login-bg-gradient" }),
      _c(
        "p",
        { staticClass: "login-copyright font-weight-bold white--text ma-0" },
        [_vm._v("© " + _vm._s(new Date().getFullYear()) + " VENUS GmbH")]
      ),
      _c(
        "v-card",
        {
          staticClass: "login-card pa-3",
          staticStyle: {
            "background-color": "rgba(255,255,255,0.8)",
            "backdrop-filter": "blur(10px)",
          },
          attrs: { elevation: "10", width: "380px" },
        },
        [
          _c("div", { staticClass: "pa-5" }, [
            _c("img", {
              staticClass: "my-2",
              attrs: { width: "100%", src: _vm.logoURL },
            }),
            _c(
              "form",
              {},
              [
                _c("v-text-field", {
                  attrs: {
                    id: "username",
                    type: "email",
                    label: "Benutzer",
                    placeholder: " ",
                  },
                  model: {
                    value: _vm.username,
                    callback: function ($$v) {
                      _vm.username = $$v
                    },
                    expression: "username",
                  },
                }),
                _c("v-text-field", {
                  attrs: {
                    id: "password",
                    type: "password",
                    label: "Passwort",
                    placeholder: " ",
                  },
                  model: {
                    value: _vm.password,
                    callback: function ($$v) {
                      _vm.password = $$v
                    },
                    expression: "password",
                  },
                }),
                _c(
                  "v-alert",
                  {
                    attrs: {
                      border: "left",
                      icon: "lock",
                      type: "error",
                      dismissible: "",
                    },
                    model: {
                      value: _vm.alert,
                      callback: function ($$v) {
                        _vm.alert = $$v
                      },
                      expression: "alert",
                    },
                  },
                  [_vm._v("Login fehlgeschlagen.")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-4",
                    attrs: {
                      type: "submit",
                      width: "100%",
                      color: "secondary",
                      "x-large": "",
                      depressed: "",
                      loading: _vm.loading,
                    },
                    on: { click: _vm.handleSubmit },
                  },
                  [_vm._v(" Login ")]
                ),
                _c("br"),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }