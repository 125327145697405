import axios from 'axios';
import router from '../../router';

let lastCatalog = '';

const store = {
    state: {
        status: '',
        loading: false,
        currentEntry: {},
        entries: [],
    },

    getters: {
        getStatus: (state) => state.status,
        allEntries: (state) => state.entries,
        isEntriesLoading: (state) => state.loading,
    },

    actions: {
        async fetchSingleEntry({ commit }, { id }) {
            commit('start_loading');
            await axios
                .get(`/api/entries/${id}`)
                .then((response) => {
                    commit('set_single_entry', { entry: response.data });
                })
                .catch((e) => {
                    console.error(e);
                })
                .finally(() => {
                    commit('end_loading');
                });
        },
        async fetchEntriesByCatalog({ commit }, catalogID) {
            console.log('fetched entries in entries.store.js');

            commit('start_loading');

            if (lastCatalog !== catalogID) {
                // flush
                commit('set_entries', []);
                lastCatalog = catalogID;
            }

            await axios
                .get(`/api/v2/entries/catalog/${catalogID}/`)
                .then((response) => {
                    commit('end_loading');
                    commit('set_entries', response.data);
                });
        },
        async addEntry({ commit, dispatch }, { entry, options }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/entries/`, {
                        entry,
                        options,
                    })
                    .then(async (res) => {
                        await dispatch(
                            'fetchEntriesByCatalog',
                            entry.catalogID
                        );
                        resolve({
                            entry: res.data.data?.entry,
                            entity: res.data.data?.entity,
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                        switch (err.message) {
                            case 'entity_create_forbidden':
                                reject('entity_create_forbidden');
                                break;
                            case 'entity_create_failed':
                                reject('entity_create_failed');
                                break;
                            case 'kg_no_connection':
                                reject('kg_no_connection');
                                break;
                            case 'entity_corrupt':
                                reject('entity_corrupt');
                                break;
                            default:
                                reject('unknown');
                        }
                    });
            });
        },
        async removeEntry({ commit, dispatch, rootState }, id) {
            const response = await axios.delete(`/api/entries/${id}/`);
            await dispatch(
                'fetchEntriesByCatalog',
                router.currentRoute.params.catalogID
            );
        },
    },

    mutations: {
        set_entries(state, entries) {
            state.entries = entries;
        },
        start_loading(state) {
            state.loading = true;
            state.status = 'loading';
        },
        end_loading(state) {
            state.loading = false;
            state.status = 'success';
        },
        set_single_entry(state, { entry }) {
            let index = state.entries.findIndex(
                (e) => e._id.toString() === entry._id.toString()
            );
            state.entries.splice(index, 1, entry);
        },
    },
};

export default {
    ...store,
    namespaced: true,
};
