var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            fixed: "",
            temporary: "",
            right: "",
            width: "600",
            color: "",
          },
          model: {
            value: _vm.opened,
            callback: function ($$v) {
              _vm.opened = $$v
            },
            expression: "opened",
          },
        },
        [
          _c(
            "v-toolbar",
            {
              attrs: {
                top: "",
                absolute: "",
                width: "100%",
                height: "auto",
                color: "white",
                dense: "",
              },
            },
            [
              _c(
                "v-list-item",
                { attrs: { color: "white" } },
                [
                  _c(
                    "v-list-item-avatar",
                    { attrs: { color: "grey" } },
                    [
                      _c("v-icon", { attrs: { color: "white" } }, [
                        _vm._v("chat"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Kommentare")])],
                    1
                  ),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", fab: "", small: "" },
                          on: { click: _vm.closeChat },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-container",
            { ref: "messageList", staticClass: "message-scroll-container" },
            _vm._l(_vm.messageList, function (msg, index) {
              return _c(
                "div",
                { key: index, attrs: { item: msg, index: index } },
                [
                  msg.author.id !== _vm.user.email
                    ? _c(
                        "p",
                        {
                          staticClass: "mx-3 mb-0 mt-3 text--grey text-caption",
                        },
                        [_vm._v(_vm._s(msg.author.name) + " schrieb:")]
                      )
                    : _vm._e(),
                  _c(
                    "v-card",
                    {
                      staticClass: "py-1 px-3 mb-3 message",
                      class:
                        msg.author.id === _vm.user.email ? "my-message" : "",
                      attrs: {
                        outlined: "",
                        "max-width": "80%",
                        width: "auto",
                        flat: "",
                      },
                    },
                    [
                      _c("small", {
                        staticClass: "ma-0",
                        domProps: {
                          innerHTML: _vm._s(
                            msg.data.text.replaceAll("\n", "<br>")
                          ),
                        },
                      }),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
          _c(
            "v-toolbar",
            {
              attrs: {
                bottom: "",
                absolute: "",
                width: "100%",
                height: "auto",
                color: "white",
              },
            },
            [
              _c("v-textarea", {
                staticClass: "my-2",
                attrs: {
                  label: "Nachricht eingeben",
                  "auto-grow": "",
                  outlined: "",
                  rows: _vm.rows,
                  "row-height": "15",
                  "hide-details": "",
                },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.sendMessage.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.message,
                  callback: function ($$v) {
                    _vm.message = $$v
                  },
                  expression: "message",
                },
              }),
              _c(
                "v-btn",
                {
                  staticClass: "my-2",
                  attrs: { fab: "", text: "", color: "white" },
                  on: { click: _vm.sendMessage },
                },
                [_c("v-icon", { attrs: { color: "grey" } }, [_vm._v("send")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            fab: "",
            fixed: "",
            right: "",
            bottom: "",
            color: "primary",
            dark: "",
          },
          on: { click: _vm.openChat },
        },
        [
          _c(
            "v-badge",
            {
              attrs: {
                content: _vm.newMessagesCount,
                value: _vm.newMessagesCount,
                color: "green",
              },
            },
            [_c("v-icon", [_vm._v("chat")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }