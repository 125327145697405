<template>
    
    <v-dialog max-width='1000' v-model='dialog' persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn fab depressed x-small v-bind="attrs" v-on="on" v-if='!create'>
                <v-icon>edit</v-icon>
            </v-btn>
            <v-btn fab depressed large fixed bottom right x-small color='success' v-bind="attrs" v-on="on" v-else>
                <v-icon>add</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <v-row class='py-5 px-3'>
                    <h3>Import {{ create ? "registrieren" : "anpassen" }}</h3>
                    <v-spacer></v-spacer>
                    <!--v-btn fab depressed small color='transparent'><v-icon color='error' @click='dialog = false'>close</v-icon></v-btn-->
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-autocomplete multiple
                                v-model='cronjob.projectIdentifiers'
                                chips
                                deletable-chips
                                :items='projects'
                                label='Projekte'
                ></v-autocomplete>
                <v-autocomplete v-model='cronjob.importSchema'
                                :items='schemas'
                                style='max-width: 300px'
                                label='Schema'
                ></v-autocomplete>
                <v-text-field multiple
                              v-model='cronjob.source'
                              style='max-width: 300px'
                              label='Quelle'
                ></v-text-field>
                <v-text-field multiple
                              class='mb-6'
                              v-model='cronjob.cron'
                              style='max-width: 300px'
                              label='Cronjob'
                              persistent-hint
                              :hint='cron'
                ></v-text-field>
                <v-text-field multiple
                              v-model='cronjob.feedUrl'
                              label='Feed URL'
                ></v-text-field>
                <v-checkbox v-model='cronjob.imageImport'
                            label='Bilder importieren?'
                            style='max-width: 220px'
                ></v-checkbox>
            </v-card-text>
            <v-card-actions class='py-4'>
                <v-spacer></v-spacer>
                <v-btn depressed large @click="dialog = false" color='error' class='mr-2'>Abbrechen</v-btn>
                <v-btn depressed large @click="save()" :loading='saving' color='success'>{{ create ? "Erstellen" : "OK" }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>

import cronstrue from 'cronstrue';

export default {
    name: 'ImportForm',
    props: [
        "value",
        "create"
    ],
    data() {
        return {
            dialog: false,
            saving: false,
            cronjob: {},
            schemas: [
                {
                    text: "TOMAS",
                    value: "tomas"
                },
                {
                    text: "ThüCAT",
                    value: "thuecat"
                },
                {
                    text: "GTFS",
                    value: "gtfs"
                },
                {
                    text: "Tourinfra",
                    value: "tourinfra"
                },
                {
                    text: "RMV",
                    value: "rmv"
                },
                {
                    text: "Bayreuth Events",
                    value: "bayreuth-events"
                },
                {
                    text: "EVIS Event Import",
                    value: "evis"
                }
            ]
        }
    },
    methods: {
        save() {
            this.saving = true;
            this.$store.dispatch('imports/' + (this.create ? "add" : "update"), {
                payload: {
                    ...this.cronjob,
                },
                id: this.cronjob._id?.toString()
            }).then(() => {
                this.dialog = false;
            }).finally(() => {
                this.saving = false;
            })
        }
    },
    computed: {
        cron: function() {
            try {
                return cronstrue.toString(this.cronjob.cron || "* * * * *",
                    { locale: 'de' }
                );
            } catch (e) {
               return "Wrong!";
            }
        },
        projects: function() {
            return this.$store.getters['projects/getAll'].map((p) => {
                return {
                    value: p.identifier,
                    text: p.name,
                }
            })
        }
    },
    watch: {
        dialog: function(val) {
            if (val) {
                this.cronjob = { ...this.value };
            }
        }
    },
};
</script>

<style scoped>

</style>
