var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("LabelComponent", {
        attrs: { definition: _vm.definition, "is-locked": _vm.isLocked },
      }),
      _c("v-autocomplete", {
        attrs: {
          readonly: _vm.isLocked,
          value: _vm.enumData,
          items: _vm.items,
          filled: "",
          chips: "",
          "item-text": "label." + _vm.$store.getters["general/language"],
          "item-value": "value",
          multiple: (_vm.definition.config || {}).multiSelect,
        },
        on: {
          input: function ($event) {
            return _vm.change($event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function (data) {
              return [
                _c(
                  "v-chip",
                  _vm._b(
                    {
                      attrs: {
                        "input-value": data.selected,
                        close: (_vm.definition.config || {}).multiSelect,
                      },
                      on: {
                        click: data.select,
                        "click:close": function ($event) {
                          return _vm.remove(data.item)
                        },
                      },
                    },
                    "v-chip",
                    data.attrs,
                    false
                  ),
                  [
                    data.item.unicode
                      ? _c("v-avatar", { attrs: { left: "" } }, [
                          _c("span", {
                            staticClass: "font--tinpi",
                            staticStyle: { "font-size": "17px" },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.getUnicodeEntity(data.item.unicode)
                              ),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(
                      " " +
                        _vm._s(
                          data.item.label[
                            _vm.$store.getters["general/language"]
                          ]
                        ) +
                        " "
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item",
            fn: function (data) {
              return [
                typeof data.item !== "object"
                  ? [
                      _c("v-list-item-content", {
                        domProps: { textContent: _vm._s(data.item) },
                      }),
                    ]
                  : [
                      data.item.unicode
                        ? _c(
                            "v-list-item-avatar",
                            [
                              _c("v-avatar", { attrs: { size: "40" } }, [
                                _c("span", {
                                  staticClass: "font--tinpi",
                                  staticStyle: { "font-size": "28px" },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.getUnicodeEntity(data.item.unicode)
                                    ),
                                  },
                                }),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", {
                            domProps: {
                              innerHTML: _vm._s(data.item.label["de-DE"]),
                            },
                          }),
                          _c("v-list-item-subtitle", {
                            domProps: { innerHTML: _vm._s(data.item.value) },
                          }),
                        ],
                        1
                      ),
                    ],
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }