var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", scrollable: "", "max-width": "800px" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [
                _vm._t(
                  "default",
                  function () {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-3",
                              attrs: {
                                fab: "",
                                color: "success",
                                depressed: "",
                                "x-small": "",
                                router: "",
                                append: "",
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { color: "white" } }, [
                            _vm._v("sync"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  { on: on, attrs: attrs }
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "pt-5" }, [
            _c(
              "span",
              { staticClass: "headline text-h4" },
              [
                _vm._v(" Import Assistent "),
                _c("v-icon", { staticClass: "ml-2", attrs: { size: "40" } }, [
                  _vm._v(" auto_fix_high "),
                ]),
              ],
              1
            ),
          ]),
          _vm.loadingProposal
            ? _c(
                "v-card-text",
                { staticClass: "mt-5" },
                [
                  _c(
                    "v-row",
                    { staticClass: "pt-5 mb-2" },
                    [
                      _c("v-spacer"),
                      _c("ProposalSpinner", {
                        attrs: {
                          "animation-duration": 1200,
                          color: "dodgerblue",
                          size: 50,
                        },
                      }),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "text-center mb-0 mt-4" }, [
                    _vm._v("SyncService evaluiert Daten und prüft Integrität."),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.loadingStrategy
            ? _c(
                "v-card-text",
                { staticClass: "mt-5" },
                [
                  _c(
                    "v-row",
                    { staticClass: "pt-5 mb-2" },
                    [
                      _c("v-spacer"),
                      _c("StrategySpinner", {
                        attrs: {
                          "animation-duration": 1200,
                          color: "red",
                          size: 50,
                        },
                      }),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "text-center mb-0 mt-4" }, [
                    _vm._v("Sync wird ausgeführt."),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.failedProposal && !_vm.loadingProposal
            ? _c("v-card-text", { staticClass: "mt-5" }, [
                _c("p", { staticClass: "text-center my-5 error--text" }, [
                  _vm._v("Fehlgeschlagen."),
                ]),
              ])
            : _vm._e(),
          !_vm.failedProposal && !_vm.loadingProposal && !_vm.loadingStrategy
            ? _c(
                "v-card-text",
                { staticClass: "pt-4" },
                [
                  _c("p", [
                    _vm._v(
                      "generiert am " +
                        _vm._s(
                          _vm.getDateTimeString(_vm.syncProposal.time || "1")
                        ) +
                        " Uhr"
                    ),
                  ]),
                  _c("p", { staticClass: "text-h6" }, [
                    _vm._v("Folgende Aktionen werden empfohlen:"),
                  ]),
                  _c(
                    "v-expansion-panels",
                    { staticClass: "mb-6", attrs: { multiple: "" } },
                    [
                      _vm.$permGuard.chP("actions.sync_catalog.c")
                        ? _c(
                            "v-expansion-panel",
                            {
                              attrs: {
                                disabled:
                                  (_vm.syncProposal.toCreate || []).length ===
                                  0,
                              },
                            },
                            [
                              _c("v-expansion-panel-header", [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-4",
                                        attrs: { color: "success" },
                                      },
                                      [_vm._v(" add ")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          (
                                            (_vm.syncStrategy.create || {})
                                              .ids || []
                                          ).length
                                        ) +
                                          "/" +
                                          _vm._s(
                                            (_vm.syncProposal.toCreate || [])
                                              .length
                                          ) +
                                          " neue Einträge erstellen"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                              _c("v-expansion-panel-content", [
                                !(_vm.syncStrategy.create || {}).ignore
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-2" },
                                      [
                                        _c("v-icon", { staticClass: "mr-2" }, [
                                          _vm._v(" info "),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            "Diese Einträge werden neu angelegt."
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mt-3",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectAll(
                                                      "create"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Alle auswählen")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list",
                                          { attrs: { "two-line": "" } },
                                          [
                                            _vm._l(
                                              _vm.syncProposal.toCreate,
                                              function (item, index) {
                                                return [
                                                  _c(
                                                    "v-list-item",
                                                    { key: index },
                                                    [
                                                      _c(
                                                        "v-list-item-action",
                                                        { staticClass: "mr-4" },
                                                        [
                                                          _c("v-checkbox", {
                                                            attrs: {
                                                              value:
                                                                item.entityId,
                                                              color: "success",
                                                            },
                                                            model: {
                                                              value: (
                                                                _vm.syncStrategy
                                                                  .create || {}
                                                              ).ids,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .syncStrategy
                                                                      .create ||
                                                                      {},
                                                                    "ids",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "(syncStrategy.create || {}).ids",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.translate(
                                                                    item.name
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-list-item-subtitle",
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  "CockpitID: " +
                                                                    item.entityId +
                                                                    " -> " +
                                                                    item.type
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$permGuard.chP("actions.sync_catalog.u")
                        ? _c(
                            "v-expansion-panel",
                            {
                              attrs: {
                                disabled:
                                  (_vm.syncProposal.toUpdate || []).length ===
                                  0,
                              },
                            },
                            [
                              _c("v-expansion-panel-header", [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-4",
                                        attrs: { color: "info" },
                                      },
                                      [_vm._v(" sync ")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          (
                                            (_vm.syncStrategy.update || {})
                                              .ids || []
                                          ).length
                                        ) +
                                          "/" +
                                          _vm._s(
                                            (_vm.syncProposal.toUpdate || [])
                                              .length
                                          ) +
                                          " bestehende Einträge aktualisieren"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                              _c("v-expansion-panel-content", [
                                !(_vm.syncStrategy.update || {}).ignore
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-2" },
                                      [
                                        _c("v-icon", { staticClass: "mr-2" }, [
                                          _vm._v(" info "),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            "Diese Einträge werden aktualisiert."
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mt-3",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectAll(
                                                      "update"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Alle auswählen")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list",
                                          { attrs: { "two-line": "" } },
                                          [
                                            _vm._l(
                                              _vm.syncProposal.toUpdate,
                                              function (item, index) {
                                                return [
                                                  _c(
                                                    "v-list-item",
                                                    { key: index },
                                                    [
                                                      _c(
                                                        "v-list-item-action",
                                                        { staticClass: "mr-4" },
                                                        [
                                                          _c("v-checkbox", {
                                                            attrs: {
                                                              value:
                                                                item.entryId,
                                                              color: "info",
                                                            },
                                                            model: {
                                                              value: (
                                                                _vm.syncStrategy
                                                                  .update || {}
                                                              ).ids,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .syncStrategy
                                                                      .update ||
                                                                      {},
                                                                    "ids",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "(syncStrategy.update || {}).ids",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.translate(
                                                                    item.name
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-list-item-subtitle",
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  "Eintrags-ID: " +
                                                                    item.entryId +
                                                                    " (" +
                                                                    item.type +
                                                                    ")"
                                                                ) + " - "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "info--text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    'Status: "' +
                                                                      _vm._s(
                                                                        item.status
                                                                      ) +
                                                                      '"'
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      (_vm.syncProposal.blockedUpdate || []).length > 0
                        ? _c(
                            "v-expansion-panel",
                            [
                              _c("v-expansion-panel-header", [
                                _c(
                                  "span",
                                  [
                                    _c("v-icon", { staticClass: "mr-4" }, [
                                      _vm._v(" lock "),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        "Status blockiert Update (" +
                                          _vm._s(
                                            (
                                              _vm.syncProposal.blockedUpdate ||
                                              []
                                            ).length
                                          ) +
                                          " Einträge)"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                              _c("v-expansion-panel-content", [
                                _c(
                                  "div",
                                  [
                                    _c("v-icon", { staticClass: "mr-2" }, [
                                      _vm._v(" info "),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        'Der Status des Eintrags blockiert das Update, z.B. "Freigegeben".'
                                      ),
                                    ]),
                                    _c(
                                      "v-list",
                                      { attrs: { "two-line": "" } },
                                      [
                                        _vm._l(
                                          _vm.syncProposal.blockedUpdate,
                                          function (item, index) {
                                            return [
                                              _c(
                                                "v-list-item",
                                                { key: index },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.translate(
                                                              item.name
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        [
                                                          _vm._v(
                                                            "Eintrags-ID: " +
                                                              _vm._s(
                                                                item.entryId +
                                                                  " (" +
                                                                  item.type
                                                              ) +
                                                              ") | "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "error--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                'Status: "' +
                                                                  _vm._s(
                                                                    item.status
                                                                  ) +
                                                                  '"'
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      (_vm.syncProposal.alreadyUpdate || []).length > 0
                        ? _c(
                            "v-expansion-panel",
                            [
                              _c("v-expansion-panel-header", [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-4",
                                        attrs: { color: "success" },
                                      },
                                      [_vm._v(" check ")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          (_vm.syncProposal.alreadyUpdate || [])
                                            .length
                                        ) + " sind bereits aktuell"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(" info "),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "Diese Einträge stimmen mit den Cockpit-Daten überein."
                                    ),
                                  ]),
                                  _c(
                                    "v-list",
                                    { attrs: { "two-line": "" } },
                                    [
                                      _vm._l(
                                        _vm.syncProposal.alreadyUpdate,
                                        function (item, index) {
                                          return [
                                            _c(
                                              "v-list-item",
                                              { key: index },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.translate(
                                                            item.name
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c("v-list-item-subtitle", [
                                                      _vm._v(
                                                        _vm._s(
                                                          "Eintrags-ID: " +
                                                            item.entryId +
                                                            " (" +
                                                            item.type +
                                                            ")"
                                                        ) + " - "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "info--text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            'Status: "' +
                                                              _vm._s(
                                                                item.status
                                                              ) +
                                                              '"'
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      (_vm.syncProposal.noMatch || []).length > 0
                        ? _c(
                            "v-expansion-panel",
                            [
                              _c("v-expansion-panel-header", [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-4",
                                        attrs: { color: "warning" },
                                      },
                                      [_vm._v(" warning ")]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (_vm.syncProposal.noMatch || [])
                                            .length
                                        ) +
                                        " ignorierte Datensätze "
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c("p", [
                                    _vm._v(
                                      "Diese Datensätze sind dem Channel zugewiesen, aber enthalten keinen we2pType, der sich auf einen Print-Type übertragen lässt."
                                    ),
                                  ]),
                                  _c(
                                    "v-list",
                                    { attrs: { "two-line": "" } },
                                    [
                                      _vm._l(
                                        _vm.syncProposal.noMatch,
                                        function (item, index) {
                                          return [
                                            _c(
                                              "v-list-item",
                                              { key: index },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.translate(
                                                            item.name
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c("v-list-item-subtitle", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.entityId +
                                                            " - " +
                                                            item.types
                                                        )
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-checkbox", {
                    attrs: {
                      label:
                        "Ich verstehe, welche Aktionen mit dem Auslösen der Synchronisation ausgeführt werden und bin mir deren Konsequenzen bewusst.",
                      color: "success",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.syncStrategy.agree,
                      callback: function ($$v) {
                        _vm.$set(_vm.syncStrategy, "agree", $$v)
                      },
                      expression: "syncStrategy.agree",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "error", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Abbrechen ")]
              ),
              _c("v-spacer"),
              _vm.syncStrategy.agree &&
              !_vm.loadingStrategy &&
              !_vm.loadingProposal
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "success" },
                      on: { click: _vm.executeStrategy },
                    },
                    [_vm._v(" Starten ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }