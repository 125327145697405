var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("LabelComponent", {
        attrs: { definition: _vm.definition, "is-locked": _vm.isLocked },
      }),
      _vm.isRTE
        ? _c(
            "div",
            { style: true ? "filter:invert(0.9)" : "" },
            [
              _c("ckeditor", {
                attrs: { editor: _vm.editor, config: _vm.editorConfig },
                on: {
                  input: function ($event) {
                    return _vm.change($event)
                  },
                },
                model: {
                  value: _vm.processedValue,
                  callback: function ($$v) {
                    _vm.processedValue = $$v
                  },
                  expression: "processedValue",
                },
              }),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c("v-spacer"),
                  _c("country-flag", {
                    staticStyle: {
                      "margin-top": "-6px",
                      filter: "invert(1.1)",
                    },
                    attrs: { country: _vm.flag, size: "normal" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm.isTextarea
        ? _c(
            "v-textarea",
            {
              attrs: {
                dense: "",
                filled: "",
                value: _vm.processedValue,
                "hide-details": "auto",
              },
              on: {
                input: function ($event) {
                  return _vm.change($event)
                },
              },
            },
            [
              _c(
                "template",
                { slot: "append-outer" },
                [
                  _c("country-flag", {
                    staticStyle: { "margin-top": "-6px" },
                    attrs: { country: _vm.flag, size: "normal" },
                  }),
                ],
                1
              ),
            ],
            2
          )
        : _c(
            "v-text-field",
            {
              attrs: {
                dense: "",
                filled: "",
                value: _vm.processedValue,
                "hide-details": "auto",
              },
              on: {
                input: function ($event) {
                  return _vm.change($event)
                },
              },
            },
            [
              _c(
                "template",
                { slot: "append" },
                [
                  _c("country-flag", {
                    staticStyle: { "margin-top": "-10px" },
                    attrs: { country: _vm.flag, size: "normal" },
                  }),
                ],
                1
              ),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }